import React, { useState, useEffect } from "react";
import { getSuggestions } from "./FetchApi"; // Adjust the import path as necessary
import "./ListSuggestions.css"; // For custom styles

const ListSuggestions = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const data = await getSuggestions();
        setSuggestions(data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch suggestions");
        setLoading(false);
      }
    };

    fetchSuggestions();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="suggestions-container">
      <h1>Suggestions List</h1>
      {suggestions.length === 0 ? (
        <div>No suggestions available</div>
      ) : (
        <div className="suggestions-list">
          {suggestions.map((suggestion) => (
            <div className="suggestion-card" key={suggestion._id}>
              <img
                src={suggestion.img}
                alt={suggestion.name}
                className="suggestion-image"
              />
              <div className="suggestion-details">
                <h2>{suggestion.name}</h2>
                <p>
                  <strong>Email:</strong> {suggestion.email}
                </p>
                <p>
                  <strong>Phone:</strong> {suggestion.phone}
                </p>
                <p>
                  <strong>Description:</strong> {suggestion.description}
                </p>
                <p>
                  <strong>Submitted At:</strong>{" "}
                  {new Date(suggestion.createdAt).toLocaleString()}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListSuggestions;
