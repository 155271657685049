import React, { Fragment } from "react";
import Layout from "../layout";
import Order from "./order";

const CheckoutPage = (props) => {
  return (
    <Fragment>
      <Layout children={<Order />} />
    </Fragment>
  );
};

export default CheckoutPage;
