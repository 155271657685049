import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import HelpIcon from "@mui/icons-material/Help";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { isMobile } from "react-device-detect";
import Search from "./search";

export default function BasicSpeedDial(props) {
  const shareData = {
    title: document.querySelector("title").innerText,
    text: document.querySelector("title").innerText,
    url: window.location.href,
  };
  const [copy, setCopy] = React.useState(false);

  const mobileShare = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      // resultPara.textContent = "Error: " + err;
      console.log(err);
    }
  };
  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopy(true);
      setTimeout(() => {
        setCopy(false);
      }, 3000);
    } catch (e) {
      console.log(e);
    }
  };
  const actions = [
    {
      icon: copy ? (
        <CheckCircleOutlineIcon />
      ) : (
        <FileCopyIcon onClick={copyToClipBoard} />
      ),
      name: copy ? "Copied to Clipboard!" : "Copy",
    },
    {
      icon: (
        <a
          className="text-gray-600 hover:text-gray-600"
          target="_blank"
          href={`https://wa.me/+919833287775?text=${encodeURIComponent(
            `Hi, I have a query about this product ${window.location.href}.`
          )}`}
        >
          <HelpIcon />
        </a>
      ),
      name: "Query",
    },
    {
      icon: (
        <SearchIcon
          className="searchButton"
          onClick={() => {
            props.setOpen(!props.open);
          }}
        />
      ),
      name: "Search",
    },
    { icon: <ShareIcon onClick={mobileShare} />, name: "Share" },
  ];

  return (
    <>
      <Search open={props.open} setOpen={props.setOpen}></Search>
      <Box
        style={{ zIndex: "3" }}
        className="fixed bottom-0 right-0 mb-16 mr-5 md:mb-10  md:mr-10"
        sx={{ height: 320, transform: "translateZ(0px)", flexGrow: 1 }}
      >
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          // sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon onClick={() => props.setOpen(false)} />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              // tooltipTitle={action.name}
              // tooltipOpen
            />
          ))}
        </SpeedDial>
      </Box>
    </>
  );
}
