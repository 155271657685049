import React, { useContext } from "react";
import { LayoutContext } from "../index";
import AuthComponent from "./AuthComponent";

const LoginSignup = () => {
  const { data, dispatch } = useContext(LayoutContext);

  const loginSignupModalToggle = () =>
    dispatch({ type: "loginSignupModalToggle", payload: !data.loginSignupModal });

  return (
    <AuthComponent 
      open={data.loginSignupModal} 
      onClose={loginSignupModalToggle}
    />
  );
};

export default LoginSignup;