import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};
export const uploadExcel = async (file, type) => {
  let formData = new FormData();
  console.log(file);
  formData.append("file", file);
  try {
    let result = await axios.post(
      `/api/centerStone/customExcelUpload?type=${type}`,
      formData,
      Headers()
    );
    console.log(result);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const getExcelData = async () => {
  try {
    let result = await axios.get(`/api/customJwe/getExcelData`,Headers());
    console.log(result)
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const getExcelAdminData = async (type) => {
  try {
    let result = await axios.get(`/api/centerStone/getAdminCenterStoneData?type=${type}`,Headers());
    console.log(result)
    return result.data;
  } catch (e) {
    console.log(e);
  }
};