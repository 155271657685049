import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Typography, Grid, Card, CardContent } from "@mui/material";
import { getAllCustomImages, getAllSec1Images } from "./fetchApi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const styles = `
  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  .product-image-container {
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .product-image-container img {
    transition: transform 0.3s ease-in-out;
  }
  .product-image-container:hover img {
    transform: scale(1.05);
  }
`;

const FadeInSection = ({ children }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = React.useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {children}
    </div>
  );
};

const Home = () => {
  const [images, setImages] = useState([]);
  const [sec1Images, setSec1Images] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllImages = async () => {
      try {
        const data = await getAllCustomImages();
        const sec1Img = await getAllSec1Images();
        if (data) {
          setImages(data.Images);
          sec1Img && setSec1Images(sec1Img.Images);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    getAllImages();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Home | Find Your Favorite Jewellery | H&M Designs | Fine Diamond
          Jewellery & Diamond Watches
        </title>
        <link rel="canonical" href={`/products`} />
        <style>{styles}</style>
      </Helmet>

      <section className="p-3 hidden md:block">
        {loading ? (
          <Skeleton height={400} />
        ) : (
          <Carousel
            className="w-full"
            autoPlay={true}
            animation="slide"
            indicators={false}
            navButtonsAlwaysVisible={true}
          >
            {images
              .filter((a) => a.slider === 1)
              .map((i) => (
                <Paper key={i._id}>
                  <a href={i.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={i.image}
                      alt="Banner"
                      className="h-96 sm:h-44 w-full cursor-pointer object-fill object-center"
                    />
                  </a>
                </Paper>
              ))}
          </Carousel>
        )}
      </section>

      <section className="p-3 md:hidden">
        {loading ? (
          <Skeleton height={400} />
        ) : (
          <Carousel
            className="w-full"
            autoPlay={true}
            animation="slide"
            indicators={false}
            navButtonsAlwaysVisible={true}
          >
            {images
              .filter((a) => a.slider === 1)
              .map((i) => (
                <Paper key={i._id}>
                  <a href={i.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={i.mob}
                      alt="Mobile Banner"
                      className="h-96 w-full cursor-pointer object-fill object-center"
                    />
                  </a>
                </Paper>
              ))}
          </Carousel>
        )}
      </section>

      <FadeInSection>
        <section className="p-3 text-left max-w-4xl mx-auto text-center">
          <Typography variant="h2" component="h1" gutterBottom>
            Welcome to H&M Designs
          </Typography>
          <Typography variant="body1">
            Discover the elegance of diamond-studded fine jewellery at H&M Designs. 
            We offer a stunning range of exquisite pieces that blend timeless beauty 
            with modern sophistication.
          </Typography>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section className="p-3">
          <Typography variant="h3" gutterBottom>
            Our Products
          </Typography>
          <hr />
          <Typography variant="h4" gutterBottom>
            Fine Jewellery & Accessories:
          </Typography>
          <Typography variant="body1">
            Explore our collection of diamond-studded fine jewellery, as below.
            Enhance your style with our Solid Stainless Steel Diamond Studded
            Chronograph Watches, combining luxury and functionality in one
            exquisite timepiece.
          </Typography>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section>
          <Grid container spacing={4} className="p-3 mt-3">
            {loading
              ? Array.from({ length: 4 }).map((_, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Skeleton height={300} />
                  </Grid>
                ))
              : sec1Images
                  .filter((a) => a.section === 1)
                  .map((s) => (
                    <Grid item xs={12} md={6} key={s._id}>
                      <div className="product-image-container">
                        <a href={s.link} target="_blank" rel="noopener noreferrer">
                          <img
                            src={s.image}
                            alt="Product"
                            className="w-full cursor-pointer object-fill object-center rounded-2"
                          />
                        </a>
                      </div>
                    </Grid>
                  ))}
          </Grid>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section className="p-3 text-center text-white bg-gray-800">
          <hr />
          <Typography variant="body1">
            Natural diamonds have a unique appeal due to their rarity and natural
            formation over millions of years. Therefore it holds intrinsic value
            and rarity, making them a timeless investment. Wearing something that
            has been formed naturally over time, is seen as a symbol of enduring
            love or commitment. People still perceive natural diamonds as having
            greater value, both monetarily and sentimentally, as they hold
            significance, especially in cultural or familial contexts.
          </Typography>
          <hr />
        </section>
      </FadeInSection>

      <section className="p-3 hidden md:block">
        {loading ? (
          <Skeleton height={400} />
        ) : (
          <Carousel
            className="w-full"
            autoPlay={true}
            animation="slide"
            indicators={false}
            navButtonsAlwaysVisible={true}
          >
            {images
              .filter((a) => a.slider === 2)
              .map((i) => (
                <Paper key={i._id}>
                  <a href={i.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={i.image}
                      alt="Banner"
                      className="h-96 sm:h-44 w-full cursor-pointer object-fill object-center"
                    />
                  </a>
                </Paper>
              ))}
          </Carousel>
        )}
      </section>

      <section className="p-3 md:hidden">
        {loading ? (
          <Skeleton height={400} />
        ) : (
          <Carousel
            className="w-full"
            autoPlay={true}
            animation="slide"
            indicators={false}
            navButtonsAlwaysVisible={true}
          >
            {images
              .filter((a) => a.slider === 2)
              .map((i) => (
                <Paper key={i._id}>
                  <a href={i.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={i.mob}
                      alt="Mobile Banner"
                      className="h-96 w-full cursor-pointer object-fill object-center"
                    />
                  </a>
                </Paper>
              ))}
          </Carousel>
        )}
      </section>

      <FadeInSection>
        <section className="p-3">
          <Typography variant="h3" gutterBottom>
            Why Choose Us?
          </Typography>
          <hr />
          <Grid container spacing={4}>
            {[
              { title: "Quality Craftsmanship", content: "Each piece is crafted with meticulous attention to detail, ensuring exceptional quality, with each one hand sketched before CAD designing." },
              { title: "Variety", content: "From classic designs to contemporary styles, we offer something for every taste, with both online products and offline inventory to choose from." },
              { title: "Customization Options", content: "Tailor your jewellery to your preferences with options for different gold karats, diamond qualities and select your own centre solitaire diamond, creating a piece that perfectly reflects your style and budget." },
              { title: "Certification", content: "Certified by a reputable gemological laboratory, all our diamond jewellery is hallmarked for purity by an official assay office and ethically sourced through conflict-free means and fair labour practices." }
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card className="h-full">
                  <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="body2">
                      {item.content}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section
          style={{
            backgroundImage:
              'url("https://hnm-designs-image.s3.ap-south-1.amazonaws.com/1645380586141_pexels-the-glorious-studio-9953655.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
          }}
        >
          <Grid container spacing={4} className="p-3 mt-3">
            {sec1Images
              .filter((a) => a.section === 2)
              .map((s) => (
                <Grid item xs={12} md={6} key={s._id}>
                  <div className="product-image-container">
                    <a href={s.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={s.image}
                        alt="Product"
                        className="w-full cursor-pointer object-fill object-center rounded-2"
                      />
                    </a>
                  </div>
                </Grid>
              ))}
          </Grid>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section>
          <Grid container spacing={4} className="p-3 mt-3">
            {sec1Images
              .filter((a) => a.section === 3)
              .map((s) => (
                <Grid item xs={12} md={6} key={s._id}>
                  <div className="product-image-container">
                    <a href={s.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={s.image}
                        alt="Product"
                        className="w-full cursor-pointer object-fill object-center rounded-2"
                      />
                    </a>
                  </div>
                </Grid>
              ))}
          </Grid>
        </section>
      </FadeInSection>

      <FadeInSection>
        <section>
          <Grid container spacing={4} className="p-3 mt-3">
            {sec1Images
              .filter((a) => a.section === 4)
              .map((s) => (
                <Grid item xs={12} md={6} key={s._id}>
                  <div className="product-image-container">
                    <a href={s.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={s.image}
                        alt="Product"
                        className="w-full cursor-pointer object-fill object-center rounded-2"
                      />
                    </a>
                  </div>
                </Grid>
              ))}
          </Grid>
        </section>
      </FadeInSection>
    </>
  );
};

export default Home;