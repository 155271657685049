import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
// const apiURL = process.env.REACT_APP_API+'/';
const EditorPage = (props)=>{  
return(  
    <>
    <CKEditor
     editor={ Editor }
     config={{
        ckfinder: {
        uploadUrl: '/api/content/upload-image'
      },
      
    }}
     data={props.data.content}
     onReady={ editor => {
         // You can store the "editor" and use when it is needed.
         console.log( 'Editor is ready to use!', editor );
     } }
     onChange={ ( event, editor ) => {
         const data = editor.getData();
         props.setData({...props.data,content:data,error: 0},console.log(data));
     } }
     onBlur={ ( event, editor ) => {
         console.log( 'Blur.', editor );
     } }
     onFocus={ ( event, editor ) => {
         console.log( 'Focus.', editor );
     } }
    ></CKEditor>

    {/* <CKEditor
     editor={ ClassicEditor }
     config={{
        ckfinder: {
        uploadUrl: 'process.env.REACT_APP_API/content/upload-image'
      },
      
    }}
     data={props.data.content}
     onReady={ editor => {
         // You can store the "editor" and use when it is needed.
         console.log( 'Editor is ready to use!', editor );
     } }
     onChange={ ( event, editor ) => {
         const data = editor.getData();
         props.setData({...props.data,content:data,error: 0},console.log(data));
     } }
     onBlur={ ( event, editor ) => {
         console.log( 'Blur.', editor );
     } }
     onFocus={ ( event, editor ) => {
         console.log( 'Focus.', editor );
     } }
    ></CKEditor> */}
    </>
)
}
export default EditorPage;