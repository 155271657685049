import React, { Fragment } from "react";
import Layout from "../shop/layout";
import ContentShow from "./ContentShow";

const ContentPage = () => {
  return (
    <Fragment>
      <Layout children={<ContentShow />} />
    </Fragment>
  );
};

export default ContentPage;