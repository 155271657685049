import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};
export const getAllProduct = async (skip, cat, subCat, sortby, filter) => {
  console.log("getting", skip, cat, subCat, sortby, filter);
  try {
    let res = await axios.get(
      `/api/product/all-product?skip=${skip}&cat=${cat}&subCat=${subCat}&sortby=${sortby}&filter=${filter}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getAllProductAdmin = async (skip, search) => {
  try {
    let res = await axios.get(
      `/api/product/all-product-admin?skip=${skip}&search=${search}`,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
window.scroll({ top: 0, left: 0, behavior: "smooth" });
export const createProduct = async (formData
//   {
//   pName,
//   modelNumber,
//   pDescription,
//   pStatus,
//   pCategory,
//   WeightAndSize,
//   goldWeight,
//   extraStoneAdded,
//   sex,
//   sName,
//   sPrice,
//   DCarrett,
//   DOffer,
//   LPrice,
//   LOffer,
//   pDiamension,
//   custumizable,
//   pSubCategory,
//   pPrice,
// }

) => {
  /* Most important part for uploading multiple image  */
  // let formData = {
  //   pName,
  //   modelNumber,
  //   pDescription,
  //   pStatus,
  //   pCategory,
  //   WeightAndSize,
  //   goldWeight,
  //   extraStoneAdded,
  //   sex,
  //   pPrice,
  //   sName,
  //   sPrice,
  //   DCarrett,
  //   DOffer,
  //   LPrice,
  //   LOffer,
  //   pDiamension,
  //   custumizable,
  //   pSubCategory,
  // };
  /* Most important part for uploading multiple image  */
  // formData.append("pName", pName);
  // formData.append("modelNumber", modelNumber);
  // formData.append("pDescription", pDescription);
  // formData.append("pStatus", pStatus);
  // formData.append("pCategory", pCategory);
  // formData.append("extraStoneAdded", extraStoneAdded);
  // formData.append("sName", sName);
  // formData.append("sPrice", sPrice);
  // formData.append("DCarrett", DCarrett);
  // formData.append("DOffer", DOffer);
  // formData.append("LPrice", LPrice);
  // formData.append("LOffer", LOffer);
  // formData.append("goldWeight", goldWeight);
  // formData.append("sex", sex);
  // formData.append("pDiamension", pDiamension);
  // formData.append("custumizable", custumizable);
  // formData.append("pSubCategory", pSubCategory);
  // formData.append("WeightAndSize", JSON.stringify(WeightAndSize));

  try {
    let res = await axios.post(`/api/product/add-product`, formData, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editProduct = async (formData) => {
  try {
    let res = await axios.post(
      `/api/product/edit-product`,
      formData,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = async (pId) => {
  try {
    let res = await axios.post(
      `/api/product/delete-product`,
      { pId },
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByCategory = async (catId) => {
  try {
    let res = await axios.post(`/api/product/product-by-category`, {
      catId,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByPrice = async (price) => {
  try {
    let res = await axios.post(`/api/product/product-by-price`, {
      price,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const productImageUpload = async ({ pid, image, color }) => {
  let formdata = new FormData();
  formdata.append("pId", pid);
  formdata.append("image", image);
  formdata.append("color", color);
  try {
    let res = await axios.post(
      `/api/product/add-product-image`,
      formdata,
      Headers()
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const fetchImageUpload = async (pid) => {
  try {
    let res = await axios.get(`/api/product/fetch-images?id=${pid}`, Headers());
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const deleteImageUpload = async (pid) => {
  try {
    let res = await axios.get(`/api/product/delete-image?id=${pid}`, Headers());
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const addColor = async (color) => {
  try {
    let res = await axios.post(
      `/api/product/add-color`,
      { name: color },
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchColor = async (pid) => {
  try {
    let res = await axios.get(`/api/product/fetch-color`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const deleteColor = async (id) => {
  try {
    let res = await axios.get(`/api/product/delete-color?id=${id}`, Headers());
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const photoPandentImages = async (id) => {
  try {
    let res = await axios.get(
      `/api/product/get-photo-pendant-images?id=${id}`,
      Headers()
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};
export const photoPandentImageUpload = async (data) => {
  let formdata = new FormData();
  formdata.append("image", data.image);
  formdata.append("id", data.pid);
  formdata.append("type", data.type);
  try {
    let res = await axios.post(
      `/api/product/admin-add-photo-pendant`,
      formdata,
      Headers()
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};
export const savePhotoPandentCordinates = async (data) => {
  try {
    let res = await axios.post(
      `/api/product/admin-save-coordinates-photo-pendant`,
      data,
      Headers()
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};
