import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {createAddresses,fetchAddress} from "./FetchApi"
import { Radio,FormControlLabel,RadioGroup,FormLabel,FormControl } from '@mui/material';
const Payment = (props)=>{  
return(<>
<FormControl component="fieldset">
  <FormLabel component="legend">Payment Options</FormLabel>
  <RadioGroup
    aria-label="payment"
    defaultValue="1"
    name="radio-buttons-group"
  >
    <FormControlLabel value="1" control={<Radio onChange={e=>props.setpaymentMode(e.target.value)} />} label="Online Payment" />
    <FormControlLabel value="0" control={<Radio onChange={e=>props.setpaymentMode(e.target.value)}/>} label="Cash On Delivery" />
  </RadioGroup>
</FormControl>
</>)
}
export default Payment;