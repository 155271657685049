import React, { useContext, useEffect, useState } from 'react';
import { ProductDetailsContext } from './ProductDetailsContext';
import { isAuthenticate } from '../auth/fetchApi';
import { Star, ThumbsUp, ThumbsDown } from 'lucide-react';
import { LayoutContext } from "../layout";
const ProductDetailsSectionTwo = ({ fetchData }) => {
  const { data: layoutData } = useContext(LayoutContext);
  const [product, setProduct] = useState({});

  useEffect(() => {
    setProduct(layoutData.singleProductDetail || {});
  }, [layoutData]);

  return (
    <div className="px-4 py-8 md:max-8">
      <div className="bg-white rounded-lg overflow-hidden">
          <Description description={product.pDescription} />
          <Reviews reviews={product.pRatingsReviews} fetchData={fetchData} />
      </div>
    </div>
  );
};

const Description = ({ description }) => (
  <section className="mb-8">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Product Description</h2>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </section>
);

const Specifications = ({ specifications }) => (
  <section className="mb-8">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Specifications</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {specifications && specifications.map((spec, index) => (
        <div key={index} className="flex justify-between border-b border-gray-200 py-2">
          <span className="font-medium text-gray-600">{spec.name}</span>
          <span className="text-gray-800">{spec.value}</span>
        </div>
      ))}
    </div>
  </section>
);

const Reviews = ({ reviews, fetchData }) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <section className="mb-8">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Customer Reviews</h2>
      {isAuthenticate() ? (
        <button
          onClick={() => setShowForm(!showForm)}
          className="mb-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
        >
          {showForm ? 'Cancel Review' : 'Write a Review'}
        </button>
      ) : (
        <p className="mb-4 text-gray-600">Please log in to leave a review.</p>
      )}
      {showForm && <ReviewForm fetchData={fetchData} onCancel={() => setShowForm(false)} />}
      <ReviewList reviews={reviews} />
    </section>
  );
};

const ReviewForm = ({ fetchData, onCancel }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your review submission logic here
    console.log('Submitting review:', { rating, review });
    fetchData();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8 bg-gray-100 p-4 rounded-lg">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rating">
          Rating
        </label>
        <div className="flex">
          {[1, 2, 3, 4, 5].map((star) => (
            <Star
              key={star}
              size={24}
              onClick={() => setRating(star)}
              className={`cursor-pointer ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
            />
          ))}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="review">
          Your Review
        </label>
        <textarea
          id="review"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows="4"
          required
        ></textarea>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={onCancel}
          className="mr-2 bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition duration-300"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
        >
          Submit Review
        </button>
      </div>
    </form>
  );
};

const ReviewList = ({ reviews }) => (
  <div>
    {reviews && reviews.map((review, index) => (
      <ReviewItem key={index} review={review} />
    ))}
  </div>
);

const ReviewItem = ({ review }) => (
  <div className="mb-4 border-b border-gray-200 pb-4">
    <div className="flex items-center mb-2">
      <div className="flex mr-2">
        {[1, 2, 3, 4, 5].map((star) => (
          <Star
            key={star}
            size={16}
            className={star <= review.rating ? 'text-yellow-400' : 'text-gray-300'}
          />
        ))}
      </div>
      <span className="text-sm text-gray-600">{review.date}</span>
    </div>
    <p className="text-gray-800 mb-2">{review.text}</p>
    <div className="flex items-center text-sm text-gray-500">
      <button className="flex items-center mr-4">
        <ThumbsUp size={16} className="mr-1" /> {review.helpfulCount}
      </button>
      <button className="flex items-center">
        <ThumbsDown size={16} className="mr-1" /> {review.notHelpfulCount}
      </button>
    </div>
  </div>
);

export default ProductDetailsSectionTwo;