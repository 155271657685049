import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from './Action';
import { DashboardUserContext } from './Layout';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Avatar, Typography, Divider, IconButton } from '@mui/material';
import { Home, ShoppingCart, Person, Favorite, Settings, ExitToApp, Menu } from '@mui/icons-material';

const Sidebar = () => {
  const { data } = useContext(DashboardUserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'My Orders', icon: <ShoppingCart />, path: '/user/orders' },
    { text: 'My Account', icon: <Person />, path: '/user/profile' },
    { text: 'My Wishlist', icon: <Favorite />, path: '/wish-list' },
    { text: 'Settings', icon: <Settings />, path: '/user/setting' },
  ];

  const drawer = (
    <div className="h-full bg-gray-100">
      <div className="p-4 bg-blue-600 text-white">
        <div className="flex items-center space-x-4">
          <Avatar className="w-16 h-16">
            {data.userDetails?.name.charAt(0).toUpperCase()}
          </Avatar>
          <div>
            <Typography variant="subtitle1">Hello,</Typography>
            <Typography variant="h6">{data.userDetails?.name}</Typography>
          </div>
        </div>
      </div>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => navigate(item.path)}
            className={`${
              location.pathname === item.path
                ? 'bg-blue-100 border-r-4 border-blue-600'
                : ''
            }`}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <ListItem button onClick={logout}>
          <ListItemIcon><ExitToApp /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className="md:hidden fixed top-4 left-4 z-50"
      >
  
      </IconButton>
      <div className="hidden md:block w-64">
        {drawer}
      </div>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        className="block md:hidden"
        classes={{
          paper: 'w-64',
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Sidebar;