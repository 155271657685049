import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { photoPandentImages, photoPandentImageUpload,savePhotoPandentCordinates } from "./FetchApi";
import Draggable from 'react-draggable'; 
import Slider from '@mui/material/Slider';
const PhotoPendantModal = (props) => {
  useEffect(() => {
    console.log(props)
    if(props.show && props.pid){  
      photos()
    }
  },[props.show])
  const [images, setImages] = useState({});
  const photos = async ()=>{  
    try{  
      let res = await photoPandentImages(props.pid);
      if(res){
        setImages(res)
        selectImage("")
      }
    }
    catch(e){  

    }
  }
  const [photoEdit, setPhotoEdit] = useState(false);
  const [image, setImage] = useState({});
  const [imageSide, setImageSide] = useState("")
  const [showEdit, setShowEdit] = useState(false)
  const pImageRef = React.useRef(null)
  const selectImage =(value) =>{  
    setImage(images[value] || {})
    setImageSide(value)
  }
  const uploadImage = async (e)=>{
    if(!imageSide){
      alert("Please select image side")
    }
    try{
        let res = await photoPandentImageUpload({image:e.target.files[0],pid:props.pid,type:imageSide});
        if(res.success){
          photos()
          
        }
    }
    catch(e){
      console.log(e)
    }
  }
  const savePhotoPandent = async ()=>{
  //  let scaleX =  pImageRef.naturalWidth / pImageRef.current.width
  //  let scaleY =  pImageRef.naturalHeight / pImageRef.current.height
  //  image.translateX = image.translateX * scaleX
  //  image.translateY = image.translateY * scaleY
  //  image.scaleX = image.scaleX * scaleX
  //  image.scaleY = image.scaleX * scaleY
    try{
          let res = await savePhotoPandentCordinates({...image,id:props.pid,type:imageSide})
          alert('Image Added Successfully')
          setPhotoEdit(false)
          photos()
    }catch(e){
      console.log(e)
    }
  }
  return (<>
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Photo Pandent Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      {photoEdit?<><Button onClick={()=>{setPhotoEdit(false)}}>
        Back to Photos
        </Button><PhotoEditor imgData={image} setImage={setImage} pImageRef={pImageRef}></PhotoEditor></>:
       <div>
         <select value={imageSide} onChange={(e)=>{selectImage(e.target.value)}}>Select Side
         <option value="">Select Side</option>
           <option value="imageF">Front</option>
           <option  value="imageL">Left</option>
           <option value="imageR">Right</option>
         </select>
         {imageSide && <><label style={{marginLeft: "10px"}} htmlFor="imageID"><i className="fa fa-camera"></i></label><input id="imageID" style={{display: 'none'}} onChange={uploadImage} type="file"></input></>}
         <div style={{margin:"auto",maxWidth:"max-content"}} onMouseEnter={()=>setShowEdit(true)} onMouseLeave={()=>setShowEdit(false)} className="flex">
         <img src={image.url || ""} style={{ height:"100px"}}></img>
         {showEdit && imageSide && <i onClick={()=>{setPhotoEdit(true)}} style={{margin:"auto",fontSize:20,color:"black"}} className="fa fa-edit"></i>}
         </div>
       </div>
}
    
      </Modal.Body>
      <Modal.Footer>
      <Button onClick={savePhotoPandent}>Save</Button>
      <Button varient="secondary">Cancel</Button>
      </Modal.Footer>
    </Modal>
  </>)
};

const PhotoEditor = ({imgData,setImage,pImageRef})=>{  
  const [positions, setPositions] = useState({
    translateX:imgData.translateX || 0,
    translateY:imgData.translateY || 0,
    skewX:imgData.skewX || 0,
    skewY:imgData.skewY || 0,
    scaleX:imgData.scaleX || 1,
    scaleY:imgData.scaleY || 1,
  })
  const [preview, setPreview] = useState(false)
  let [ activeDrags, setActiveDrags ] = useState(0);
  let imageRef = useRef(null)
  const onStart = () => {
    setActiveDrags(++activeDrags);
    imageRef.current.style.transform =  `scale(${positions.scaleX},${positions.scaleY}) translate(${positions.translateX}px, ${positions.translateY}px) skew(${positions.skewX}deg, ${positions.skewY}deg)`
  };

 const  onStop = () => {
  setActiveDrags(--activeDrags);
  imageRef.current.style.transform =  `scale(${positions.scaleX},${positions.scaleY}) translate(${positions.translateX}px, ${positions.translateY}px) skew(${positions.skewX}deg, ${positions.skewY}deg)`
  };

const handleDrag = (e, ui) => {
  setPositions({...positions,translateX:ui.x, translateY:ui.y});
  imageRef.current.style.transform =  `scale(${positions.scaleX},${positions.scaleY}) translate(${ui.x}px, ${ui.y}px) skew(${positions.skewX}deg, ${positions.skewY}deg)`
}
useEffect(()=>{
    setImage({...imgData,translateX:positions.translateX, translateY:positions.translateY,skewX:positions.skewX,skewY:positions.skewY,scaleX:positions.scaleX,scaleY:positions.scaleY})
    imageRef.current.style.transform =  `scale(${positions.scaleX},${positions.scaleY}) translate(${positions.translateX}px, ${positions.translateY}px) skew(${positions.skewX}deg, ${positions.skewY}deg)`
},[positions])
  const dragHandlers = {onStart: onStart, onStop: onStop, onDrag: handleDrag};
  return (  
    <>
    <div className="grid grid-cols-2">
      <div >
        <div style={{width:"max-content", height:"max-content"}} className="relative">
        <img ref={pImageRef} style={{maxHeight:"383px",zIndex:2,position:"relative"}} src={imgData.url}></img>
        <Draggable  bounds="parent" {...dragHandlers} >
          <img  ref={imageRef} style={{position:"absolute",margin:"0",top:"0",left:"0",zIndex:preview?1:3,height:"100px",width:"100px"}} src="https://hnm-designs-image.s3.ap-south-1.amazonaws.com/test/1652044374767user.jpg"></img>
        </Draggable>
        
        </div>
      </div>
      <div>
        <div>
          Skew X-axis
        <Slider
  aria-label="Small steps"
  defaultValue={positions.skewX}
  onChange={(e,i)=>{setPositions({...positions,skewX:i})}}
  // getAriaValueText={valuetext}
  // marks
  step={1}
  min={-180}
  max={180}
  valueLabelDisplay="auto"
/>
Skew Y-axis
<Slider
  aria-label="Small steps"
  defaultValue={positions.skewY}
  // getAriaValueText={valuetext}
  step={1}
  onChange={(e,i)=>{setPositions({...positions,skewY:i})}}
  // marks
  min={-180}
  max={180}
  valueLabelDisplay="auto"
/>
Scale
<Slider
  aria-label="Small steps"
  defaultValue={positions.skewX}
  // getAriaValueText={valuetext}
  step={0.01}
  onChange={(e,i)=>{setPositions({...positions,scaleX:i,scaleY:i})}}
  // marks
  min={0}
  max={2}
  valueLabelDisplay="auto"
/>
Preview <input type="checkbox" onChange={(e) =>{setPreview(!preview)}}></input>
        </div>
      </div>

    </div>
    </>
  )
}

export default PhotoPendantModal
