import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-crop/dist/ReactCrop.css';
import { ToastContainer, toast } from 'react-toastify';
// console.log = function () {};
// console.warn = function () {};
// console.error = function () {};
ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer></ToastContainer>
  </React.StrictMode>,
  document.getElementById("root")
);
// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(<App />, rootElement);
// } else {
//   ReactDOM.render(<App />, rootElement);
// }
// serviceWorker.unregister();
