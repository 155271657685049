import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  getAllCategoryWithSubCategory,
  // fetchSubCatagories,
} from "../../admin/categories/FetchApi";
const MobileCatagoryPage = () => {
  useEffect(() => {
    fetchCategoryData();
  }, []);
  const [allCat, setAllCat] = useState({});
  const fetchCategoryData = async () => {
    let responseData = await getAllCategoryWithSubCategory();
    if (responseData.Categories) {
      setAllCat(responseData.Categories);
    }
  };

  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Catagories || H&M Designs | Fine Diamond Jewellery & Diamond Watches</title>
        <link rel="canonical" href="https:/hnmdesigns.com" />
      </Helmet>

      <div className="md:hidden flex flex-col">

        {allCat.length > 0 ? (
          <Accordion>

            {allCat.map((cat, index) => (
              cat.cName === "Rings" ||
                cat.cName === "Earrings" ||
                cat.cName === "Pendant" ? (<Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    {cat.cName} 
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-none">
                      {cat.subCats.length > 0
                        ? cat.subCats.map((scat) => (
                          <li
                            className="bordor-b-2 border-solid bordor-gray-600 p-1"
                            key={scat._id}
                          >
                            <Link
                              className="text-gray-600 no-underline hover:underline hover:font-bold"
                              to={`/shop/${cat.cName && cat.cName.toLowerCase().split(' ').join('-').split('/').join('or')}/${scat.name.toLowerCase().split(' ').join('-').split('/').join('or')}`}
                            >
                              {scat.name} 
                            </Link>
                          </li>
                        ))
                        : "No Sub Catagories Found"}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>) : ""

            ))}
            {allCat.map((cat, index) => (
              cat.cName === "Rings" ||
                cat.cName === "Earrings" ||
                cat.cName === "Pendant" ? "" : (<Accordion.Item eventKey={index}>
                  {cat.cName == "Diamond Watches" ?
                    <Accordion.Header>
                      <Link to="/shop/diamond-watches" className="no-underline">{cat.cName}  </Link>

                    </Accordion.Header> :
                    <>
                      <Accordion.Header>
                        {cat.cName} 

                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="list-none">
                          {cat.subCats.length > 0
                            ? cat.subCats.map((scat) => (
                              <li
                                className="bordor-b-2 border-solid bordor-gray-600 p-1"
                                key={scat._id}
                              >
                                <Link
                                  className="text-gray-600 no-underline hover:underline hover:font-bold"
                                  to={`/shop/${cat.cName && cat.cName.toLowerCase().split(' ').join('-').split('/').join('or')}/${scat.name.toLowerCase().split(' ').join('-').split('/').join('or')}`}
                                >
                                  {scat.name} 
                                </Link>
                              </li>
                            ))
                            : "No Sub Catagories Found"}
                        </ul>
                      </Accordion.Body>
                    </>
                  }

                </Accordion.Item>)

            ))}
          </Accordion>
        ) : (
          <div class="flex h-96">
            <div class="m-auto">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          </div>
        )}
      </div>
      <div className="md:flex md:h-96 hidden md:block md:animate-pulse text-red-800">
        <div class="md:m-auto">
          <h1>Please Open Through Mobile To Visit This Page</h1>
        </div>
      </div>
    </>
  );
};
export default MobileCatagoryPage;
