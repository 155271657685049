import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { searchProduct } from "./FetchApi";
import { 
  Dialog, 
  DialogContent, 
  TextField, 
  InputAdornment, 
  IconButton, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Avatar, 
  Typography 
} from "@mui/material";
import { Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";

export default function Search(props) {
  const [data, setData] = useState({
    categories: [],
    subCategories: [],
    products: [],
  });
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (props.open) {
      setSearch("");
      setData({ categories: [], subCategories: [], products: [] });
      setTimeout(() => searchInputRef.current?.focus(), 100);
    }
  }, [props]);

  useEffect(() => {
    if (search === "") {
      setData({ categories: [], subCategories: [], products: [] });
    } else {
      fetchSearchData();
    }
  }, [search]);

  const fetchSearchData = async () => {
    try {
      let response = await searchProduct(search);
      setData(response);
    } catch (err) {
      console.error("Error fetching search data:", err);
    }
  };

  const handleItemClick = (path) => {
    props.setOpen(false)
    navigate(path);
  };

  const renderSearchResults = (title, items, pathGenerator) => (
    items?.length > 0 && (
      <div className="mb-4">
        <Typography variant="h6" className="mb-2">{title}</Typography>
        <List>
          {items.map((item, index) => (
            <ListItem
              key={index}
              button
              onClick={() => handleItemClick(pathGenerator(item))}
              className="hover:bg-gray-100 transition-colors duration-200 rounded-lg mb-2"
            >
              <ListItemAvatar>
                <Avatar 
                  src={item.pImages?.[0]?.url || item.cImage} 
                  alt={item.pName || item.name || item.cName}
                >
                  {(item.pName || item.name || item.cName || "").charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={item.pName || item.name || item.cName}
                secondary={item.pSubCategory?.name || item.category?.cName}
              />
            </ListItem>
          ))}
        </List>
      </div>
    )
  );

  return (
    <Dialog
      open={props.open}
      onClose={()=>props.setOpen(false)}
      fullWidth
      maxWidth="md"
      classes={{ paper: 'rounded-lg' }}
    >
      <DialogContent className="p-0">
        <div className="flex flex-col h-full">
          <div className="p-4 border-b sticky top-0 bg-white z-1">
            <TextField
              inputRef={searchInputRef}
              fullWidth
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={()=>props.setOpen(false)}
                      aria-label="close search"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex-grow overflow-y-auto p-4">
            {renderSearchResults("Products", data.products, (p) => 
              `/shop/${p.pCategory.cName.toLowerCase().replace(/\s+/g, "-").replace(/\//g, "or")}/${p.pSubCategory.name.toLowerCase().replace(/\s+/g, "-").replace(/\//g, "or")}/${p.pName.toLowerCase().replace(/\s+/g, "-").replace(/\//g, "or")}-${p.modelNumber}`
            )}
            {renderSearchResults("Sub Categories", data.subCatagories, (sc) => 
              `/shop/${sc.category.cName.toLowerCase().replace(/\s+/g, "-").replace(/\//g, "or")}/${sc.name.toLowerCase().replace(/\s+/g, "-").replace(/\//g, "or")}`
            )}
            {renderSearchResults("Categories", data.catagories, (c) => 
              `/shop/${c.cName.toLowerCase().replace(/\s+/g, "-").replace(/\//g, "or")}`
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}