import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import {
  productImageUpload,
  fetchImageUpload,
  deleteImageUpload,
  addColor,
  fetchColor,
  deleteColor,
} from "./FetchApi";
import { green } from "@mui/material/colors";
import { CircularProgress, Box, Fab } from "@mui/material";
import { Save as SaveIcon, Check as CheckIcon,HighlightOff } from "@mui/icons-material";
function UploadProductImage(props) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [addColorOption, setColorOption] = useState(false);
  const [data, setData] = useState({
    pid: "",
    image: "",
    color: "",
  });
  const [newColor, setNewColor] = useState("");
  const [colors, setColors] = useState([]);
  useEffect(() => {
    if (props.pid) {
    fetchProductImages();
    fetchColors();
    }
  }, [props.show]);
  const fetchProductImages = async () => {
    let response = await fetchImageUpload(props.pid);
    if (response.success) {
      setImages(response.data);

      setTimeout(()=>{
        setSuccess(false);
        setLoading(false);
      },2000)
      
    }
  };
  const hiddenFileInput = useRef(null);
  const uploadImage = async (sdata) => {
    console.log(sdata);
    setData(sdata);
    if (!loading && sdata.image) {
      setSuccess(false);
      setLoading(true);
      let response = await productImageUpload(sdata);
      if (response.success) {
        fetchProductImages();
        setSuccess(true);
        setLoading(false);
      }
    }
  };
  const deleteImage = async (id) => {
    let response = await deleteImageUpload(id);
    if (response.success) {
      fetchProductImages();
    }
  };

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };
  const handleButtonClick = () => {
    if(!success && !loading && data.color){  
      hiddenFileInput.current.click();
    }
    
  };

  const addNewColor = async () => {
    if (newColor != "") {
      let response = await addColor(newColor);
      if (response.success) {
        setColorOption(false)
        setData({...data,color:""})
        fetchColors();
        setNewColor("");
      }
    }
  };
  const fetchColors = async () => {
    let response = await fetchColor();
    if (response.success) {
      setColors(response.data);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload product image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="grid grid-cols-10">
          {images &&
            images.map((i) => (
              <div className="relative">
                <HighlightOff onClick={(e) => deleteImage(i._id)} className="absolute center"></HighlightOff>
                <img
                  
                  style={{ height: "60px" }}
                  src={i.url}
                ></img>
                <div className="text-xs absolute bottom-0">{i.color.name}</div>
              </div>
            ))}
        </div>
        <div>
          <input
            type="file"
            ref={hiddenFileInput}
            className="hidden"
            onChange={(e) => {
              uploadImage({
                ...data,
                image: e.target.files[0],
                pid: props.pid,
              });
            }}
          ></input>
          <Box sx={{ m: 1, position: "relative" }}>
            <Fab
              aria-label="save"
              color="primary"
              sx={buttonSx}
              onClick={handleButtonClick}
            >
              {success ? <CheckIcon /> : <SaveIcon />}
            </Fab>
            {loading && (
              <CircularProgress
                size={68}
                sx={{
                  color: "green[500]",
                  position: "absolute",
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
          <div className="flex">
          {addColorOption && <div className="border border-indigo-600 m-2">
              <input
                className="outline-none w-20 px-2 focus:outline-none"
                value={newColor}
                onChange={(e) => setNewColor(e.target.value)}
                type="text"
              ></input>
              <Button
                onClick={(e) => {
                  addNewColor();
                }}
                variant="success"
                size="sm"
              >
                Add
              </Button>
            </div>}
            <select
            value={data.color}
              onChange={(e) => {e.target.value == "add"?setColorOption(true):setData({ ...data, color: e.target.value })}}
            >
              <option value="">--Select Color--</option>
              {colors &&
                colors.map((c, i) => (
                  <option value={c._id} key={i}>
                    {c.name}
                  </option>
                ))}
                <option value="add">Add Color</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={!loading && props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default UploadProductImage;
