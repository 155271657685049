import React from 'react'
import { 
  Card, 
  CardContent, 
  Typography, 
  Chip, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PaymentIcon from '@mui/icons-material/Payment'
import LocationOnIcon from '@mui/icons-material/LocationOn'

export default function Component({ orders = [] }) {
  return (
    <div className="container mx-auto md:px-4 py-8 bg-gray-50 min-h-screen">
      <div className="space-y-6">
        {orders.map((order) => (
          <Card key={order.order_id} className="shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardContent>
              <div className="flex flex-wrap justify-between items-center mb-4">
                <Typography variant="h6" component="h2" className="text-gray-700">
                  Order #{order.order_id}
                </Typography>
                <Chip 
                  label={order.status} 
                  color={order.status === 'Ordered' ? 'primary' : 'default'}
                  className="mt-2 sm:mt-0"
                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                <div className="flex items-center">
                  <LocalShippingIcon className="mr-2 text-gray-600" />
                  <Typography variant="body2" color="text.secondary">
                    Date: {new Date(order.createdAt).toLocaleDateString()}
                  </Typography>
                </div>
                <div className="flex items-center">
                  <PaymentIcon className="mr-2 text-gray-600" />
                  <Typography variant="body2" color="text.secondary">
                    Payment: {order.payment}
                  </Typography>
                </div>
                <div className="flex items-center">
                  <Typography variant="body2" color="text.secondary" className="font-semibold">
                    Total Amount: ₹{order.total_amount.toLocaleString()}
                  </Typography>
                </div>
              </div>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="font-medium">Products</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper} className="bg-white">
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Size</TableCell>
                          <TableCell align="right">Color</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.products.map((product, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row" className="flex items-center">
                              <Avatar src={product.image.url} alt={product.product.pName} className="mr-2" />
                              <div>
                                <Typography variant="body2">{product.product.pName}</Typography>
                                <Typography variant="caption" color="text.secondary">
                                  Model: {product.product.modelNumber}
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell align="right">{product.quantity}</TableCell>
                            <TableCell align="right">{product.size.size}</TableCell>
                            <TableCell align="right">{product.color.name}</TableCell>
                            <TableCell align="right">₹{product.amount.toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="font-medium">Shipping Address</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex items-start">
                    <LocationOnIcon className="mr-2 text-gray-600 mt-1" />
                    <Typography variant="body2">
                      {order.address.name}<br />
                      {order.address.address1}<br />
                      {order.address.address2}<br />
                      {order.address.city}, {order.address.state} {order.address.pin}<br />
                      Phone: {order.address.phone}
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}