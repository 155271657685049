import React, { Fragment, useContext, useEffect } from "react";
import { getAllMaterial, deleteMaterial } from "./FetchApi";
import {Spinner} from "react-bootstrap"
import { MaterialContext } from "./index";
import moment from "moment";

{
  /*const apiURL = process.env.REACT_APP_API+'/';*/
}

const AllMaterial = (props) => {
  const { data, dispatch } = useContext(MaterialContext);
  const { materials, loading } = data;

  useEffect(() => {
    if(!data.editMaterialModal.modal)
    fetchData();
  }, [data.editMaterialModal.modal]);

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    let responseData = await getAllMaterial();
    setTimeout(() => {
      if (responseData && responseData.Materials) {
        dispatch({
          type: "fetchMaterialAndChangeState",
          payload: responseData.Materials,
        });
        dispatch({ type: "loading", payload: false });
      }
    }, 1000);
  };

  const deleteMaterialReq = async (mId) => {
    let deleteC = await deleteMaterial(mId);
    if (deleteC.error) {
      console.log(deleteC.error);
    } else if (deleteC.success) {
      console.log(deleteC.success);
      fetchData();
    }
  };

  /* This method call the editmodal & dispatch material context */
  const editMaterial = (mId, mPrice,is_deleted, type) => {
    console.log(mPrice);
    if (type) {
      dispatch({
        type: "editMaterialModalOpen",
        mId: mId,
        mPrice: mPrice,
        is_deleted
      });
    }
  };

  if (loading) {
    return (
      <div class="flex h-96">
            <div class="m-auto">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          </div>
    );
  }

  return (
    <Fragment>
      <div className="col-span-1 overflow-auto bg-white shadow-lg p-4 h-screen overflow-auto">
        <table className="table-auto border w-full my-2">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Material Name</th>
              <th className="px-4 py-2 border">Price</th>
              <th className="px-4 py-2 border">Status</th>
              <th className="px-4 py-2 border">Created at</th>
              <th className="px-4 py-2 border">Updated at</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {materials && materials.length > 0 ? (
              materials.map((item, key) => {
                return (
                  <MaterialTable
                    material={item}
                    editMat={(mId, mPrice,is_deleted, type) =>
                      editMaterial(mId, mPrice,is_deleted, type)
                    }
                    deleteMat={(mId) => deleteMaterialReq(mId)}
                    key={key}
                  />
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="text-xl text-center font-semibold py-8"
                >
                  No material found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="text-sm text-gray-600 mt-2">
          Total {materials && materials.length} material found
        </div>
      </div>
    </Fragment>
  );
};

/* Single material Component */
const MaterialTable = ({ material, deleteMat, editMat }) => {
  return (
    <Fragment>
      <tr>
        <td className="p-2 text-left">
          {material.mName.length > 20
            ? material.mName.slice(0, 20) + "..."
            : material.mName}
        </td>
        <td className="p-2 text-left">{material.mPrice}</td>
        <td className="p-2 text-center">
          <div className={`rounded-full border border-2 text-white text-base font-semibold ${!material.is_deleted?"bg-green-600":"bg-red-600"}`}>{!material.is_deleted?"Active":"Inactive"}</div>
          </td>
        <td className="p-2 text-center">
          {moment(material.createdAt).format("lll")}
        </td>
        <td className="p-2 text-center">
          {moment(material.updatedAt).format("lll")}
        </td>
        <td className="p-2 flex items-center justify-center">
          <span
            onClick={(e) =>
              editMat(
                material._id,
                material.mPrice,
                material.is_deleted,
                true
              )
            }
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-green-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          {/* <span
            onClick={(e) => deleteMat(material._id)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </span> */}
        </td>
      </tr>
    </Fragment>
  );
};

export default AllMaterial;
