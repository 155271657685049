import React, {
    Fragment,
    useContext,
    useEffect,
    useState,
    useRef,
  } from "react";
import Slider from "react-slick";
  import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
  } from 'react-image-crop'
  import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
  import 'react-image-crop/dist/ReactCrop.css'
  import { Modal, Row, Col } from "react-bootstrap";
  import Button from "@mui/material/Button";
  import { generatePhotoPandent, getPhotoPandentImages} from "./FetchApi";
  import ProgressBar from 'react-bootstrap/ProgressBar'
  const PhotoPendantModal = (props) => {
    const [cropImageModal,setCropImageModal] = useState(false)
    const imageRef = useRef(null)
    const [imageSrc, setImgSrc] = useState("")
    const [crop, setCrop] = useState(null)
    const [imageData, setImageData] = useState(null)
  const [croppedImage, setCroppedImage] = useState("")
  const [images, setImages] = useState([])
  const [now1, setNow1] = useState(0)
    const cropImage = (e)=>{
        if (e.target.files && e.target.files.length > 0) {
        setCropImageModal(true)
        setCrop({  
            aspect:1,
            height:100,
            width:100,
            unit:'px'
        })
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
        }
    }
    function onImageLoaded(e) {
        imageRef.current = e
      }
   const [now, setNow] = useState(0)

   useEffect(() => {
       console.log(crop)
   },[crop])

  const getImage = async ()=>{
       let res = await getCroppedImg(imageRef.current, crop, 'newFile.jpg')
       console.log(crop)
       const blobUrl = URL.createObjectURL(res);
       setImageData(res)
       setCroppedImage(blobUrl)
       setCropImageModal(false)
   }
   const generatePhoto = async () => {
    setNow(0)
    setNow(10)
    let res = await generatePhotoPandent({image:imageData,id:props.pid},setNow)
    setNow(100)
    getImages()
    setCroppedImage("")
   }
   useEffect(() => {
     if(props.pid){
      getImages()
     }
   },[])
   const getImages = async () => {
    setNow(0)
    setNow1(10)
    let res = await getPhotoPandentImages(props.pid,setNow1)
    if(res._id){  
      setImages([res.imageF,res.imageL,res.imageR])
      setTimeout(() => {
        setNow1(0)
      },2000)
      
    }
   }
   const settings = {
    customPaging: function(i) {
      return (
        <a>
          <img src={images[i]} />
        </a>
      );
    },
    fade: true,
    dots: true,
    dotsClass: "slick-dots slick-thumb relative",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
        return (  
            <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Customize Photo Pandent
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <Slider {...settings}>
          {images.map((image,index)=>(  
            <div>
            <img style={{margin:"auto"}} src={image} />
          </div>
            ))}
          
        </Slider>
            <div>
                <div>
                    <label htmlFor="fileUpload" className="py-4 text-center text-gray-400 border rounded  border-gary-600 bg-gray-100 flex">
                        <div style={{margin:"auto"}}>
                        {croppedImage?<img style={{height:"100px"}} src={croppedImage}></img>:
                      <>  <i className="fa fa-plus text-lg"></i>
                        <p>Upload Image</p>
                        </>}
                        </div>
                    </label>
                    <input id="fileUpload" onChange={cropImage} style={{display:"none"}} type="file"></input>

                    {now!=0 && <ProgressBar className="mt-4" now={now} label={`${now}%`} />}
                    {cropImageModal && <><ReactCrop
                     ruleOfThirds
                     src={imageSrc}
                     crop={crop}
                     onImageLoaded={onImageLoaded}
                     onChange={setCrop}
                     onComplete={setCrop}
                     style={{ maxHeight: "300px" }}
        />
        <br></br>
        <Button variant="contained" size="small" onClick={getImage}>Save</Button></>}
        
        {now1!=0 && (<div className="text-center"><ProgressBar animated now={now1} />
        <br></br>
        <span className="text-sm text-gray-400">Please wait ...</span>
        </div>)}
                    
                </div>
            </div>

        </div>
  
      </Modal.Body>
      {croppedImage && <Modal.Footer>
        <Button variant="contained" startIcon={<AutoFixHighIcon></AutoFixHighIcon>} onClick={generatePhoto} color="warning" size="large">Customize Now</Button>
      </Modal.Footer>}
      </Modal>
        )
  }
  var getCroppedImg = (image, pixelCrop, fileName) => {

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = pixelCrop.width * scaleX;
    canvas.height = pixelCrop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY
    );
console.log(image.naturalHeight,image.height)
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  }
  export default PhotoPendantModal;