import React from "react";
import { Route, Navigate } from "react-router-dom";
import { isAuthenticate, isAdmin } from "./fetchApi";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  // <Route
  //   {...rest}
  //   render={(props) =>
  //     isAuthenticate() && !isAdmin() ? (
  //       <Component {...props} />
  //     ) : (
  //       <Navigate
  //         to={{
  //           pathname: "/",
  //           state: { from: props.location },
  //         }}
  //       />
  //     )
  //   }
  // />
  if (isAuthenticate() && !isAdmin()) {
    return <Component {...rest} />
  }
  return <Navigate
    to={{
      pathname: "/user/profile"
    }}
  />
};

export default ProtectedRoute;
