import React, { Fragment, useContext, useEffect, useState } from "react";
import { uploadExcel, getExcelAdminData } from "./fetchApi";
import { Spinner } from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { Table, Container, Row, Col, Pagination } from "react-bootstrap";
import { Button } from "@mui/material";

const CustomizeUpload = () => {
  const [file, setFile] = useState("");
  const [success, setSuccess] = useState(false);
  const [initial, setInitial] = useState(true);
  const [tData, setTData] = useState([]);
  const [tKeys, setTKeys] = useState([])
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [type, setType] = useState("NATURAL")

  // useEffect(() => {
  //   if (!initial) {
  //     // uploadExcelData();
  //   } else {
  //     setInitial(false);
  //   }
  // }, [file]);
  useEffect(() => {
    setTableData(type);
  }, [success]);
  const uploadExcelData = async () => {
    setLoading(true);
    setTData([]);
    let response = await uploadExcel(file, type);
    if (response.error) {
    } else if (response.success) {
      setSuccess(true);
    }
  };
  const setTableData = async (type) => {
    setType(type)
    let excel = await getExcelAdminData(type);
    if(excel.data.length){
      setTKeys(Object.keys(excel.data[0]))
    }
    setTData(excel.data);
    // if (excel.data.length > 0) {
      setLoading(false);
    // }
  };
  const tDataPerPage = 20;
  const pagesVisited = pageNumber * tDataPerPage;
  const pageCount = Math.ceil(tData.length / tDataPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const chnageType = (value) => {
    setLoading(true);
    setTableData(value)
    setPageNumber(0)
  }

  return (
    <>
      <div className="flex mt-5 items-center">
        <label className="ml-10 mr-10" for="excelFile">
          Upload Excel File
        </label>

        <input
          id="excelFile"
          onChange={(e) => setFile(e.target.files[0])}
          type="file"
          className=""
        ></input>
        {file?<Button onClick={()=>uploadExcelData()}>Upload</Button>:null}
        <div className="mr-8">
          <select
            value={type}
            onChange={(e) => chnageType(e.target.value)}
          >
            <option value="NATURAL">NATURAL</option>
            <option value="LAB">LAB GROWN</option>
          </select>
        </div>
        <a className="text-base bg-blue-400 text-white rounded no-underline p-1" href={`/api/uploads/customExcel/excel_${type}.xlsx`} download>Download Excel File</a>
      </div>
      {!loading && tData.length ? (
        <div className="overflow-x-auto">
          <Table className="text-xs" striped bordered hover size="sm">
            <thead>
              <tr>
                {tKeys.filter((s)=>s!=='_id').map((a,i)=><th key={i}>{a}</th>)}
              </tr>
            </thead>
            <tbody>
              {tData
                .slice(pagesVisited, pagesVisited + tDataPerPage)
                .map((a) => <tr>
                  {Object.entries(a).map(([y,x],i)=>y=='_id'?null:<td>{typeof x === 'object'?x.text:x}</td>)}
                </tr>)}
            </tbody>
          </Table>

          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      ) :loading? (
        <div class="flex h-96">
          <div class="m-auto">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        </div>
      ):<div className="text-center mt-8">
      No Data Found, Please upload center stone excel sheet
    </div>}
    </>
  );
};
export default CustomizeUpload;
