import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckoutComponent } from "./CheckoutProducts";
import OrderStatus from "./orderStatus";
import AddressPage from "./Address";
import { LayoutContext } from "..";
const Order = () => {
  const [checkoutPage, setCheckoutPage] = useState(0);
  const { data, dispatch } = useContext(LayoutContext);

  const handleCheckOut = () => {
    setCheckoutPage(0);
  };
  const history = useNavigate();
  const [orderId, setOrderId] = useState(null);
  if (!localStorage.getItem("cart")) {
    history("/shop");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [checkoutPage]);
  return (
    <>
      {checkoutPage === 0 && (
        <AddressPage setCheckoutPage={setCheckoutPage}></AddressPage>
      )}

      {checkoutPage === 1 && (
        <CheckoutComponent
          setOrderId={setOrderId}
          setCheckoutPage={setCheckoutPage}
        ></CheckoutComponent>
      )}
      {checkoutPage === 2 && (
        <OrderStatus orderId={orderId} product={data.cartProduct}></OrderStatus>
      )}
    </>
  );
};
export default Order;
