import React, { Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { getOrderImage } from "./FetchApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Set width to 90% to make it responsive
  height: "90%", // Set width to 90% to make it responsive
  maxWidth: 800, // Max width to limit modal width on larger screens
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Enable vertical scrolling
};

const VOP = (props) => {
  console.log("VOprops", props);
  const [images, setImages] = useState([]);

  const getOrderImages = async (pId, color, index) => {
    let imageUrl = await getOrderImage(pId, color);
    console.log("imageUrl>>>>", imageUrl.image);
    // Update the images array for the specific product index
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = imageUrl.image;
      return updatedImages;
    });
  };

  useEffect(() => {
    // Fetch images for each product when component mounts
    props.products.forEach((product, index) => {
      getOrderImages(product.product._id, product.color._id, index);
    });
  }, [props.products]);

  return (
    <>
      <Modal
        open={props.open}
        onClose={(e) => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            onClick={() => props.setOpen(false)}
            style={{ position: "absolute", top: 5, right: 5 }}
          >
            Close
          </Button>
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
            View Ordered Products
          </Typography>
          <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
            Number of Ordered Products: {props.products.length}
          </Typography>
          {props.products.map((p, i) => (
            <Fragment key={i}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", marginTop: 2 }}
              >
                Product {i + 1} : {p.product.pName}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", marginTop: 2 }}
              >
                Product {i + 1} Amount : {p.amount}
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                Color: {p.color.name}
              </Typography>
              <Typography variant="body1">
                Diamond: {p.diamond.mName}
              </Typography>
              <Typography variant="body1">Carat: {p.carrett} gm</Typography>
              {p.weight && (
                <Typography variant="body1">Weight: {p.weight} gm</Typography>
              )}
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {images[i] &&
                  images[i].map((image, j) => (
                    <img
                      key={j}
                      src={image.url}
                      alt={`Product ${i + 1}`}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        width: "200px",
                        height: "auto",
                      }}
                    />
                  ))}
              </Box>
            </Fragment>
          ))}
        </Box>
      </Modal>
    </>
  );
};
export default VOP;
