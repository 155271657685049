import React, { Fragment, useContext, useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import useScrollListener from "../../../hooks/useScrollListener";
const MobileViewNav = () => {
  const [navClassList, setNavClassList] = useState("");
  const scroll = useScrollListener();
  let location = useLocation();
  // update classList of nav on scroll
  useEffect(() => {
    if (scroll.y > 150 && scroll.y - scroll.lastY > 0) {
      setNavClassList("translate-y-full");
    } else {
      setNavClassList("");
    }
  }, [scroll.y, scroll.lastY]);
  // useEffect(() => {
  //   if (location.pathname == "checkout") {
  //     return null;
  //   }
  // });
if(location.pathname == "/checkout"){  
  return null
}
  return (
    <>
      <div
        className={`mobileviewnav fixed bottom-0 md:hidden w-full h-16 bg-gray-200 grid grid-cols-5 text-xs text-gray-600  text-center transform transition duration-150 ease-in-out ${navClassList}`}
      >
        <NavLink
          onClick={(e) => navigator.vibrate(30)}
          exact
          to="/"
          className="flex flex-col text-gray-600 no-underline hover:no-underline p-2"
          activeClassName="active"
        >
          <i class="fas fa-home-alt text-lg"></i>
          Home
        </NavLink>
        <NavLink
          onClick={(e) => navigator.vibrate(30)}
          exact
          to="/catagories"
          className="flex flex-col text-gray-600 no-underline hover:no-underline p-2"
          activeClassName="active"
        >
          <i class="fad fa-th-large text-lg"></i>
          Catagories
        </NavLink>
        <NavLink
          onClick={(e) => navigator.vibrate(30)}
          exact
          to="/customize-ring"
          className="flex flex-col text-gray-600 no-underline hover:no-underline p-2"
          activeClassName="active"
        >
          <i class="fas fa-edit text-lg"></i>
          Customize
        </NavLink>
        <NavLink
          onClick={(e) => navigator.vibrate(30)}
          exact
          to="/user/profile"
          className="flex flex-col text-gray-600 no-underline hover:no-underlinLink p-2"
          activeClassName="active"
        >
          <i class="fas fa-user-alt text-lg"></i>
          Profile
        </NavLink>
        <NavLink
          onClick={(e) => navigator.vibrate(30)}
          exact
          to="/user/orders"
          className="flex flex-col text-gray-600 no-underline hover:no-underlinLink p-2"
          activeNavLinklassName="active"
        >
          <i class="fad fa-box-open text-lg"></i>
          Orders
        </NavLink>
      </div>
    </>
  );
};

export default MobileViewNav;
