import React, { Fragment } from "react";
import styles from "./coupon.module.css";
import { FormLabel, Button } from "@mui/material";

function Coupon({ couponData, setCouponData }) {
  return (
    <div>
      <div className={styles.couponCodeDiv}>
        <input placeholder="Coupon code"></input>
        <Button variant="contained" color="info" size="medium">
          Apply
        </Button>
      </div>
    </div>
  );
}
export default Coupon;
