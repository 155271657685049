import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAddresses, fetchAddress, setDefaultAddress } from "./FetchApi";
import { Autocomplete, Button, TextField } from "@mui/material";
import R from "countrycitystatejson";
import { toast } from "react-toastify";
import { ArrowLeft, Edit, Check } from "lucide-react";

const AddressPage = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [address, setAddress] = useState({
    id: null,
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    pin: "",
    phone: "",
    landmark: "",
    error: false,
    success: false,
  });

  const every = R.getCountryByShort("IN");
  let states = every.states;
  const stateNames = Object.keys(states);
  let cities = R.getCities("IN", state);

  useEffect(() => {
    getAddress();
  }, [addNewAddress]);

  const getAddress = async () => {
    let response = await fetchAddress();
    if (response) {
      setAddressList(response);
    }
  };

  const saveAddress = async () => {
    if (address.phone.length !== 10) {
      toast.error("Enter Valid Phone Number.");
      return;
    }
    let response = await createAddresses(address);
    if (response && response.success) {
      setAddNewAddress(false);
      toast.success("Address saved successfully!");
    } else if (response && response.error) {
      setAddress({ ...address, error: response.error, success: false });
    }
  };

  const editAddress = (address) => {
    setAddNewAddress(true);
    setAddress({
      id: address._id,
      name: address.name,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state: address.state,
      pin: address.pin,
      phone: address.phone,
      landmark: address.landmark,
      error: false,
      success: false,
    });
  };

  const makeDefaultAddress = async (id) => {
    let response = await setDefaultAddress(id);
    if (response.success) {
      getAddress();
      toast.success("Default address updated!");
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Button
        onClick={() => navigate(-1)}
        startIcon={<ArrowLeft />}
        className="mb-6 text-blue-600 hover:text-blue-800"
      >
        Back
      </Button>

      <h1 className="text-3xl font-bold mb-6">Manage Addresses</h1>

      {!addNewAddress && (
        <div className="mb-6">
          <Button
            onClick={() => setAddNewAddress(true)}
            variant="contained"
            className="bg-blue-600 hover:bg-blue-700 text-white"
          >
            Add new address
          </Button>
        </div>
      )}

      {!addNewAddress && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {addressList.map((a, i) => (
            <div
              key={i}
              className={`p-4 rounded-lg shadow-md transition-all duration-300 ${
                a.is_default ? "border-2 border-blue-600" : "border border-gray-200"
              }`}
            >
              <div className="flex justify-between items-start mb-2">
                <h3 className="font-semibold text-lg">{a.name}</h3>
                <div className="flex space-x-2">
                  <button
                    onClick={() => makeDefaultAddress(a._id)}
                    className={`p-1 rounded-full ${
                      a.is_default ? "bg-blue-600 text-white" : "bg-gray-200 hover:bg-blue-600 hover:text-white"
                    }`}
                  >
                    <Check size={16} />
                  </button>
                  <button
                    onClick={() => editAddress(a)}
                    className="p-1 rounded-full bg-gray-200 hover:bg-gray-300"
                  >
                    <Edit size={16} />
                  </button>
                </div>
              </div>
              <p className="text-sm text-gray-600">
                {a.landmark}, {a.city}, {a.state} - {a.pin}
              </p>
            </div>
          ))}
        </div>
      )}

      {addNewAddress && (
        <form onSubmit={(e) => e.preventDefault()} className="space-y-4 max-w-lg">
          <TextField
            fullWidth
            label="Name"
            value={address.name}
            onChange={(e) => setAddress({ ...address, name: e.target.value, error: false })}
          />
          <TextField
            fullWidth
            label="Address Line 1"
            multiline
            rows={2}
            value={address.address1}
            onChange={(e) => setAddress({ ...address, address1: e.target.value, error: false })}
          />
          <TextField
            fullWidth
            label="Address Line 2"
            multiline
            rows={2}
            value={address.address2}
            onChange={(e) => setAddress({ ...address, address2: e.target.value, error: false })}
          />
          <Autocomplete
            options={stateNames}
            value={address.state}
            onChange={(_, newValue) => {
              setState(newValue);
              setAddress({ ...address, state: newValue, error: false });
            }}
            renderInput={(params) => <TextField {...params} label="Select State" />}
          />
          <Autocomplete
            options={cities}
            value={address.city}
            onChange={(_, newValue) => {
              setCity(newValue);
              setAddress({ ...address, city: newValue, error: false });
            }}
            renderInput={(params) => <TextField {...params} label="Select City" />}
          />
          <TextField
            fullWidth
            label="PIN"
            value={address.pin}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 6 && /^\d*$/.test(value)) {
                setAddress({ ...address, pin: value, error: false });
              }
            }}
            inputProps={{ maxLength: 6 }}
          />
          <TextField
            fullWidth
            label="Landmark"
            value={address.landmark}
            onChange={(e) => setAddress({ ...address, landmark: e.target.value, error: false })}
          />
          <TextField
            fullWidth
            label="Phone"
            value={address.phone}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 10 && /^\d*$/.test(value)) {
                setAddress({ ...address, phone: value, error: false });
              }
            }}
            inputProps={{ maxLength: 10 }}
          />
          <div className="flex space-x-4">
            <Button
              onClick={saveAddress}
              variant="contained"
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              Save Address
            </Button>
            <Button
              onClick={() => {
                setAddNewAddress(false);
                setAddress({
                  id: null,
                  name: "",
                  address1: "",
                  address2: "",
                  city: "",
                  state: "",
                  pin: "",
                  phone: "",
                  landmark: "",
                  error: false,
                  success: false,
                });
              }}
              variant="outlined"
              className="border-red-600 text-red-600 hover:bg-red-50"
            >
              Cancel
            </Button>
          </div>
        </form>
      )}

      {addressList.length > 0 && !addNewAddress && (
        <div className="mt-6">
          <Button
            onClick={() => props.setCheckoutPage(1)}
            variant="contained"
            className="bg-orange-500 hover:bg-orange-600 text-white"
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddressPage;