import React, { Fragment, useContext, useState, useEffect } from "react";
import { ProductContext } from "./index";
import { editProduct, getAllProduct } from "./FetchApi";
import { getAllCategory, fetchSubCatagories } from "../categories/FetchApi";
import AddSideWeight from "./AddSideWeight";
import ExtraStone from "./ExtraStone";
import { 
  TextField, Select, MenuItem, FormControlLabel, Radio, RadioGroup, 
  Button, Typography, Checkbox, FormControl, InputLabel
} from '@material-ui/core';

const EditProductModal = (props) => {
  const { data, dispatch } = useContext(ProductContext);
  const [priceConstant, setPriceConstant] = useState(false);
  const [categories, setCategories] = useState([]);

  const alert = (msg, type) => (
    <div className={`bg-${type}-100 border border-${type}-400 text-${type}-700 px-4 py-3 rounded relative mb-4`} role="alert">
      <span className="block sm:inline">{msg}</span>
    </div>
  );

  const [subCatagories, setSubCatagories] = useState([]);
  const [sizeRequired, setSizeRequired] = useState(true);
  const [fData, setFdata] = useState({
    pId: "",
    pName: "",
    modelNumber: "",
    pDescription: "",
    pStatus: true,
    pCategory: "",
    WeightAndSize: [],
    extraStones: [],
    extraStoneAdded: false,
    pSubCategory: "",
    custumizable: false,
    sex: "unisex",
    goldWeight: 0,
    pPrice: 0,
    sName: "",
    sPrice: 0,
    DCarrett: 0,
    DOffer: 0,
    LPrice: 0,
    LOffer: 0,
    pDiamension: "",
    success: false,
    error: false,
  });

  useEffect(() => {
    fetchCategoryData();
  }, []);

  const fetchCategoryData = async () => {
    let responseData = await getAllCategory();
    if (responseData.Categories) {
      setCategories(responseData.Categories);
    }
  };

  useEffect(() => {
    if (data.editProductModal.pCategory) {
      setFdata({
        ...fData,
        pId: data.editProductModal.pId,
        pName: data.editProductModal.pName,
        modelNumber: data.editProductModal.modelNumber,
        pDescription: data.editProductModal.pDescription,
        pStatus: data.editProductModal.pStatus,
        pCategory: data.editProductModal.pCategory._id,
        WeightAndSize: data.editProductModal.WeightAndSize
          ? data.editProductModal.WeightAndSize.map(({ _id, ...rest }) => ({
              ...rest,
            }))
          : [],
        extraStoneAdded: data.editProductModal.extraStoneAdded,
        extraStones: data.editProductModal.extraStones,
        pSubCategory: data.editProductModal.pSubCatagory._id,
        custumizable: data.editProductModal.custumizable,
        sex: data.editProductModal.sex,
        goldWeight: data.editProductModal.goldWeight,
        sName: data.editProductModal.sName,
        sPrice: data.editProductModal.sPrice,
        DCarrett: data.editProductModal.DCarrett,
        DOffer: data.editProductModal.DOffer,
        LPrice: data.editProductModal.LPrice,
        LOffer: data.editProductModal.LOffer,
        pDiamension: data.editProductModal.pDiamension,
        pPrice: data.editProductModal.pPrice,
      });

      sizereq(data.editProductModal.pCategory._id);
      getSubCatagories(data.editProductModal.pCategory._id);
    }
  }, [data.editProductModal]);

  const getSubCatagories = async (cat) => {
    let subCat = await fetchSubCatagories(cat);
    if (subCat) {
      setSubCatagories(subCat);
    }
  };

  const sizereq = (value) => {
    categories.filter((cat) => {
      if (cat._id === value) {
        setSizeRequired(cat.sizeRequired);
        setPriceConstant(cat.priceConstant);
      }
    });
  };

  const fetchData = async () => {
    let responseData = await getAllProduct();
    if (responseData && responseData.Products) {
      dispatch({
        type: "fetchProductsAndChangeState",
        payload: responseData.Products,
      });
    }
  };

  const WeightAndSize = (sizeArr) => {
    setFdata({
      ...fData,
      error: false,
      success: false,
      WeightAndSize: sizeArr,
    });
  };

  const extraStones = (stoneArr) => {
    setFdata({
      ...fData,
      error: false,
      success: false,
      extraStones: stoneArr,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      let responseData = await editProduct(fData);
      if (responseData.success) {
        fetchData();
        setFdata({ ...fData, success: responseData.success });
        dispatch({ type: "editProductModalClose", payload: false });
      } else if (responseData.error) {
        setFdata({ ...fData, error: responseData.error });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div
        onClick={(e) => dispatch({ type: "editProductModalClose", payload: false })}
        className={`${
          data.editProductModal.modal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />

      <div
        className={`${
          data.editProductModal.modal ? "" : "hidden"
        } fixed inset-0 flex items-center justify-center z-40 overflow-auto`}
      >
        <div className="bg-white w-11/12 md:w-3/5 lg:w-1/2 rounded-lg shadow-xl p-6 max-h-screen overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <Typography variant="h5" className="font-semibold">Edit Product</Typography>
            <Button
              onClick={(e) => dispatch({ type: "editProductModalClose", payload: false })}
              className="text-gray-600 hover:text-gray-800"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Button>
          </div>

          {fData.error && alert(fData.error, "red")}
          {fData.success && alert(fData.success, "green")}

          <form className="space-y-4" onSubmit={(e) => submitForm(e)}>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Product Name"
                variant="outlined"
                fullWidth
                value={fData.pName}
                onChange={(e) => setFdata({ ...fData, pName: e.target.value })}
              />
              <TextField
                label="Model Number"
                variant="outlined"
                fullWidth
                value={fData.modelNumber}
                onChange={(e) => setFdata({ ...fData, modelNumber: e.target.value })}
              />
            </div>

            <TextField
              label="Product Description"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={fData.pDescription}
              onChange={(e) => setFdata({ ...fData, pDescription: e.target.value })}
            />

            <div className="flex items-center space-x-4">
              <Typography variant="body1">Sex:</Typography>
              <RadioGroup
                row
                value={fData.sex}
                onChange={(e) => setFdata({ ...fData, sex: e.target.value })}
              >
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="unisex" control={<Radio />} label="Unisex" />
              </RadioGroup>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Product Status</InputLabel>
                <Select
                  value={fData.pStatus}
                  onChange={(e) => setFdata({ ...fData, pStatus: e.target.value })}
                  label="Product Status"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Disabled</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel>Product Category</InputLabel>
                <Select
                  value={fData.pCategory}
                  onChange={(e) => {
                    setFdata({ ...fData, pCategory: e.target.value });
                    sizereq(e.target.value);
                    getSubCatagories(e.target.value);
                  }}
                  label="Product Category"
                >
                  {categories.map((elem) => (
                    <MenuItem value={elem._id} key={elem._id}>{elem.cName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <FormControl variant="outlined" fullWidth>
              <InputLabel>Product Sub Category</InputLabel>
              <Select
                value={fData.pSubCategory}
                onChange={(e) => setFdata({ ...fData, pSubCategory: e.target.value })}
                label="Product Sub Category"
              >
                {subCatagories.map((elem) => (
                  <MenuItem value={elem._id} key={elem._id}>{elem.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="grid grid-cols-2 gap-4">
              {sizeRequired ? (
                <AddSideWeight
                  WeightAndSize={WeightAndSize}
                  Size={fData.WeightAndSize}
                />
              ) : (
                <>
                  <TextField
                    label="Gold Weight"
                    variant="outlined"
                    type="number"
                    fullWidth
                    value={fData.goldWeight}
                    onChange={(e) => setFdata({ ...fData, goldWeight: e.target.value })}
                  />
                  {priceConstant && (
                    <TextField
                      label="Price"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={fData.pPrice}
                      onChange={(e) => setFdata({ ...fData, pPrice: e.target.value })}
                    />
                  )}
                </>
              )}
              <TextField
                label="Dimension"
                variant="outlined"
                fullWidth
                value={fData.pDiamension}
                onChange={(e) => setFdata({ ...fData, pDiamension: e.target.value })}
              />
            </div>

            {fData.pCategory === "6150c537ea2f237c3e0fa244" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fData.custumizable}
                    onChange={(e) => setFdata({ ...fData, custumizable: e.target.checked })}
                    color="primary"
                  />
                }
                label="Customizable"
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={fData.extraStoneAdded}
                  onChange={(e) => setFdata({ ...fData, extraStoneAdded: e.target.checked })}
                  color="primary"
                />
              }
              label="Extra Stone Added"
            />

            {fData.extraStoneAdded && (
              <ExtraStone
                extraStones={extraStones}
                Stones={fData.extraStones}
                custumizable={fData.custumizable}
              />
            )}

            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Diamond Carat"
                variant="outlined"
                type="number"
                fullWidth
                value={fData.DCarrett}
                onChange={(e) => setFdata({ ...fData, DCarrett: e.target.value })}
              />
              <TextField
                label="Offer on Diamond (%)"
                variant="outlined"
                type="number"
                fullWidth
                value={fData.DOffer}
                onChange={(e) => setFdata({ ...fData, DOffer: e.target.value })}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Labour Price"
                variant="outlined"
                type="number"
                fullWidth
                value={fData.LPrice}
                onChange={(e) => setFdata({ ...fData, LPrice: e.target.value })}
              />
              <TextField
                label="Offer on Labour Price (%)"
                variant="outlined"
                type="number"
                fullWidth
                value={fData.LOffer}
                onChange={(e) => setFdata({ ...fData, LOffer: e.target.value })}
              />
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              className="py-3 bg-blue-600 hover:bg-blue-700"
            >
              Update Product
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EditProductModal;