import React, { Fragment } from "react";
import Layout from "../layout";
import SingleWishProduct from "./SingleWishProduct";
import {Helmet} from "react-helmet";
const WishList = () => {
  return (
    <Fragment>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Wish Products | H&M Designs | Fine Diamond Jewellery & Diamond Watches</title>
            </Helmet>
      <Layout children={<SingleWishProduct />} />
    </Fragment>
  );
};

export default WishList;
