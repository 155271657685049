import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};
export const DashboardData = async () => {
  try {
    let res = await axios.get(`/api/customize/dashboard-data`,Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSliderImages = async () => {
  try {
    let res = await axios.get(`/api/customize/get-slide-image`,Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postUploadImage = async (formData) => {
  console.log(formData.get("image"));
  try {
    let res = await axios.post(
      `/api/customize/upload-slide-image`,
      formData,Headers()
    );
    console.log(res)
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postDeleteImage = async (id) => {
  try {
    let res = await axios.post(`/api/customize/delete-slide-image`, {
      id,
    },Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
