import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { shipOrder, changeOrderStatus } from "./FetchApi";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StatusChange = (props) => {
  const [status, setStatus] = useState({
    status: "",
    msg: "",
  });
  const [trackingId, setTrackingId] = useState("");
  const [orderStatus, setOrderStatus] = useState("Cast Tree");

  useEffect(() => {
    setStatus({
      status: "",
      msg: "",
    });
    setTrackingId("");
    setOrderStatus("Cast Tree");
  }, [props.orderID]);

  async function handleStatusChange() {
    let res;
    if (orderStatus === "SH") {
      res = await shipOrder(props.orderID, trackingId);
    } else {
      res = await changeOrderStatus(props.orderID, orderStatus);
    }

    if (res.success) {
      setStatus({
        status: "success",
        msg: `Order ${orderStatus === "SH" ? "Shipped" : "Status Changed"}`,
      });
      props.fetchOrders(props.page * 10 - 10, props.search);
      setTimeout(() => {
        props.setOpen(false);
      }, 2000);
    } else {
      setStatus({
        status: "error",
        msg: "Failed to update order",
      });
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Change Order Status
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Select
            value={orderStatus}
            onChange={(e) => setOrderStatus(e.target.value)}
            displayEmpty
          >
            {/* New Stages */}
            <MenuItem value="Cast Tree">Cast Tree</MenuItem>
            <MenuItem value="Mould">Mould</MenuItem>
            <MenuItem value="Stone Setting">Stone Setting</MenuItem>
            <MenuItem value="Polishing">Polishing</MenuItem>
            <MenuItem value="Inspection">Inspection</MenuItem>
            <MenuItem value="Final Product">Final Product</MenuItem>

            {/* Existing Statuses */}
            <MenuItem value="OR">Ordered</MenuItem>
            <MenuItem value="SH">Shipped</MenuItem>
            <MenuItem value="DL">Delivered</MenuItem>
            <MenuItem value="CN">Cancelled</MenuItem>
            <MenuItem value="RN">Returned</MenuItem>
          </Select>
        </FormControl>
        {orderStatus === "SH" && (
          <TextField
            id="standard-basic"
            label="Blue Dart AWB No."
            variant="standard"
            sx={{ mt: 2 }}
            value={trackingId}
            onFocus={() =>
              setStatus({
                status: "",
                msg: "",
              })
            }
            onChange={(e) => setTrackingId(e.target.value)}
          />
        )}
        {status.msg && <Alert severity={status.status}>{status.msg}</Alert>}
        <Button onClick={handleStatusChange} variant="contained" sx={{ mt: 2 }}>
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default StatusChange;
