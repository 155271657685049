import React from "react";
import Layout from "../layout";
import IndexWatches from '../home/indexWatches'
function DiamondWatches() {
    return (<Layout><div className="container flex flex-column gap-4">
        <h1 className="text-4xl">Diamond Watches</h1>

        <p>Introducing our Diamond Watch – a masterpiece of sophistication and
            functionality designed for both men and women. Crafted with a solid stainless steel body, this timepiece exudes durability and style, perfect for any occasion.</p>
        <p>Featuring a chronograph function, our Diamond Watch not only keeps precise time but also adds a touch of elegance to your daily activities. From timing workouts to tracking meetings, this watch is your ultimate companion.</p>
        <p>With a water resistance of up to 50 meters, you can confidently wear your Diamond Watch wherever life takes you – from the gym to the boardroom and beyond. And with scratch-resistant sapphire glass, your watch will maintain its pristine appearance for years to come.</p>
        <p>Our Diamond Watch is available in unisex models, ensuring that everyone can enjoy the timeless luxury and functionality it offers. Plus, each watch comes with a lifetime warranty, guaranteeing quality and peace of mind for a lifetime of wear.
        </p>
        <p>As a special bonus, select models of our Diamond Watch come with free leather bands, allowing you to personalize your style and effortlessly transition from day to night with ease.</p>

        <p>Experience the perfect combination of style, durability, and versatility with our Diamond Watch – the ultimate accessory for the modern individual.</p>
        <IndexWatches></IndexWatches>
    </div>
  
    
    </Layout>);
}

export default DiamondWatches;