import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getContents =async () => {
    try{  
        let result = await axios.get(`/api/content/get-contents`);
        return result.data;
    }
    catch(e){
        console.log(e)
    }
}


export const addContent = async (data)=>{  
    try{  
        let result = await axios.post(`/api/content/add-content`,data,Headers())
        return result.data
    }
    catch(e){  
        console.log(e)
    }
}

export const deleteContents =async (id) => {
    try{  
        let result = await axios.get(`/api/content/delete-content?id=${id}`,Headers());
        return result.data;
    }
    catch(e){
        console.log(e)
    }
}