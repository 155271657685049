import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import LandingPageEdit from "./LandingPageEdit";
import {Tabs,Tab}from "react-bootstrap";
import Section1Images from "./Section1Images"
const LandingPage = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4 m-4">
    <Tabs defaultActiveKey="Slider" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="Slider" title="Main Slider">
    <LandingPageEdit />
  </Tab>
  <Tab eventKey="Section-1" title="Section Images">
  <Section1Images/>
  </Tab>
</Tabs>
      
    </div>
  );
};

const Customize = (props) => {
  return (
    <Fragment>
        <AdminLayout children={<LandingPage />} />
    </Fragment>
  );
};

export default Customize;
