import React, { useState, useEffect } from 'react';
import {
  Slider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { 
  Square, 
  Heart, 
  Circle, 
  Octagon,
  Pentagon,
  Diamond,
  Triangle,
} from 'lucide-react';

const FilterOptions = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    priceRange: [1000, 100000000],
    caratRange: [0.3, 9.9],
    color: [],
    clarity: [],
    cut: '',
    shape: [],
  });

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  useEffect(() => {
    onFilterChange(filters)
  }, [filters, onFilterChange])

  const colors = ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
  const clarities = ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2'];
  const cuts = ['3EX', '3EX / 3VG', 'EX', 'VG'];
  const shapes = [
    { name: 'Asscher', icon: <Square size={16} /> },
    { name: 'Cushion', icon: <Square size={16} /> },
    { name: 'Emerald', icon: <Square size={16} /> },
    { name: 'Heart', icon: <Heart size={16} /> },
    { name: 'Marquise', icon: <Pentagon size={16} /> },
    { name: 'Oval', icon: <Circle size={16} /> },
    { name: 'Pear', icon: <Triangle size={16} /> },
    { name: 'Princess', icon: <Square size={16} /> },
    { name: 'Radiant', icon: <Diamond size={16} /> },
    { name: 'Round', icon: <Circle size={16} /> }
  ];

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-lg shadow">
      <div className="flex flex-col gap-2">
        <label className="block mb-2 text-sm font-medium text-gray-700">Shape</label>
        <div className="flex flex-wrap gap-2">
          {shapes.map(shape => (
            <button
              key={shape.name}
              onClick={() => {
                const newShapes = filters.shape.includes(shape.name)
                  ? filters.shape.filter(s => s !== shape.name)
                  : [...filters.shape, shape.name];
                handleFilterChange('shape', newShapes);
              }}
              className={`px-3 py-1 text-sm font-semibold rounded-full flex items-center gap-1
                ${filters.shape.includes(shape.name) 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-200 text-gray-700'}`}
            >
              {shape.icon}
              <span>{shape.name}</span>
            </button>
          ))}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex-1 min-w-[200px]">
          <label className="block mb-2 text-sm font-medium text-gray-700">Price Range</label>
          <Slider
            value={filters.priceRange}
            onChange={(_, newValue) => handleFilterChange('priceRange', newValue)}
            valueLabelDisplay="auto"
            min={10000}
            max={100000000}
            className="mb-2"
          />
          <div className="flex justify-between gap-2">
            <TextField
              value={filters.priceRange[0]}
              onChange={(e) => handleFilterChange('priceRange', [Number(e.target.value), filters.priceRange[1]])}
              type="number"
              size="small"
              InputProps={{ inputProps: { min: 10000, max: 100000000 } }}
              className="w-1/4"
            />
            <TextField
              value={filters.priceRange[1]}
              onChange={(e) => handleFilterChange('priceRange', [filters.priceRange[0], Number(e.target.value)])}
              type="number"
              size="small"
              InputProps={{ inputProps: { min: 10000, max: 100000000 } }}
              className="w-1/8"
            />
          </div>
        </div>
        <div className="flex-1 min-w-[200px]">
          <label className="block mb-2 text-sm font-medium text-gray-700">Carat Range</label>
          <Slider
            value={filters.caratRange}
            onChange={(_, newValue) => handleFilterChange('caratRange', newValue)}
            valueLabelDisplay="auto"
            min={0.3}
            max={9.9}
            step={0.1}
            className="mb-2"
          />
          <div className="flex justify-between gap-2">
            <TextField
              value={filters.caratRange[0]}
              onChange={(e) => handleFilterChange('caratRange', [Number(e.target.value), filters.caratRange[1]])}
              type="number"
              size="small"
              InputProps={{ inputProps: { min: 0.3, max: 9.9, step: 0.1 } }}
              className="w-1/2"
            />
            <TextField
              value={filters.caratRange[1]}
              onChange={(e) => handleFilterChange('caratRange', [filters.caratRange[0], Number(e.target.value)])}
              type="number"
              size="small"
              InputProps={{ inputProps: { min: 0.3, max: 9.9, step: 0.1 } }}
              className="w-1/2"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex-1 min-w-[200px]">
          <label className="block mb-2 text-sm font-medium text-gray-700">Color</label>
          <div className="flex flex-wrap gap-2">
            {colors.map(color => (
              <button
                key={color}
                onClick={() => {
                  const newColors = filters.color.includes(color)
                    ? filters.color.filter(c => c !== color)
                    : [...filters.color, color];
                  handleFilterChange('color', newColors);
                }}
                className={`px-3 py-1 text-sm font-semibold rounded-full 
                  ${filters.color.includes(color) 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-200 text-gray-700'}`}
              >
                {color}
              </button>
            ))}
          </div>
        </div>
        <div className="flex-1 min-w-[200px]">
          <label className="block mb-2 text-sm font-medium text-gray-700">Clarity</label>
          <div className="flex flex-wrap gap-2">
            {clarities.map(clarity => (
              <button
                key={clarity}
                onClick={() => {
                  const newClarities = filters.clarity.includes(clarity)
                    ? filters.clarity.filter(c => c !== clarity)
                    : [...filters.clarity, clarity];
                  handleFilterChange('clarity', newClarities);
                }}
                className={`px-3 py-1 text-sm font-semibold rounded-full 
                  ${filters.clarity.includes(clarity) 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-200 text-gray-700'}`}
              >
                {clarity}
              </button>
            ))}
          </div>
        </div>
        <div className="flex-1 min-w-[200px]">
          <FormControl fullWidth size="small">
            <InputLabel>Cut</InputLabel>
            <Select
              value={filters.cut}
              onChange={(e) => handleFilterChange('cut', e.target.value)}
              label="Cut"
            >
              <MenuItem value="">All Cuts</MenuItem>
              {cuts.map(cut => (
                <MenuItem key={cut} value={cut}>{cut}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {/* <div className="flex justify-end">
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => onFilterChange(filters)}
          className="bg-blue-500 hover:bg-blue-600"
        >
          Apply Filters
        </Button>
      </div> */}
    </div>
  );
};

export default FilterOptions;