import React, { useState, useEffect } from "react";
import EditorPage from "../partials/Editor";
import { Table, Button } from "react-bootstrap";
import { getContents, addContent, deleteContents } from "./FetchApi";
import { Spinner } from "react-bootstrap";
const UpdateContent = () => {
  const [editor, setEditor] = useState(false);
  const [contents, setContents] = useState([]);
  const [editid,setEditId] = useState("");
  useEffect(() => {
    fetchContents();
  }, [editor]);

  const [data, setData] = useState({
    content_type: "",
    name: "",
    content: "",
    error: 0,
  });

  const fetchContents = async () => {
    let response = await getContents();
    console.log(response);
    setContents(response);
  };
  const cancelContent = () => {
    setData({
      content_type: "",
      name: "",
      content: "",
      error: 0,
    });
  };
  const saveContent = async () => {
    setData({
      ...data,
      error: 3,
    });
    let response = {}

    if(editid != ""){  
        response = await addContent({...data,id:editid});
    }
    else{  
        response = await addContent(data);
    }

    if (response.error) {
      setData({
        content_type: "",
        name: "",
        content: "",
        error: 1,
      });
    } else if (response.success) {
      setEditId("");
      setData({
        content_type: "",
        name: "",
        content: "",
        error: 2,
      });
      setTimeout(() => {
        setEditor(!editor);
      }, 2000);
    }
  };
  const deleteContent = async (id) => {
    let result = await deleteContents(id);
    if (result.success) {
      fetchContents();
      
    }
  };
  const editContent = async (id) => {
    let editData = contents.find((c) => {
     return c._id == id;
    });
    setData({
      content_type: editData.content_type,
      name: editData.name,
      content: editData.content,
      error: 0,
    });
    setEditor(true);
    setEditId(id)
  };
  return (
    <>
      <div className={`flex justify-${!editor ? "end" : "start"} mb-3`}>
        <Button
          onClick={(e) => {
            setEditor(!editor);
            setEditId("");
            setData({
              content_type: "",
              name: "",
              content: "",
              error: 0,
            });
          }}
        >
          {!editor ? "Add Content" : "Back to Contents"}
        </Button>
      </div>
      {!editor ? (
        <>
          {contents.length > 0 ? (
            <Table striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Content Type</th>
                  <th>Content Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {contents.map((c, index) => (
                  <tr className="capitalize">
                    <td>{index}</td>
                    <td>{c.content_type}</td>
                    <td><a href={'/contents/'+c.url} target="_blank">{c.name}</a></td>
                    <td className="p-2 flex items-center justify-center">
                      <span className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
                        <svg
                        onClick={(e) => editContent(c._id)}
                          className="w-6 h-6 fill-current text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      <span className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
                        <svg
                          onClick={(e) => deleteContent(c._id)}
                          className="w-6 h-6 fill-current text-red-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div class="flex h-96">
            <div class="m-auto">
            <div class="dot-flashing"></div>
            </div>
          </div>
          )}
        </>
      ) : (
        <>
          <div className="w-1/2 grid grid-cols-2 gap-2">
            <div className="flex flex-col space-y-2 ">
              <label>Select Content Type</label>
              <select
                value={data.content_type}
                onChange={(e) =>
                  setData({ ...data, content_type: e.target.value, error: 0 })
                }
                className="p-2 focus:outline-none capitalize rounded"
              >
                <option value="">--Select--</option>
                <option value="help">Help</option>
                <option value="about">about</option>
                <option value="terms">terms</option>
                <option value="query">query</option>
                <option value="services">services</option>
                <option value="consumer-confidence">Consumer Confidence</option>
                <option value="guide">guide</option>
                <option value="privacy">privacy</option>
                <option value="other">other</option>
              </select>
            </div>
            <div className="flex flex-col space-y-2">
              <label>Add Content Name</label>
              <input
                value={data.name}
                onChange={(e) =>
                  setData({ ...data, name: e.target.value, error: 0 })
                }
                className="p-2 focus:outline-none rounded"
                type="text"
                placeholder="Enter Content Name"
                disabled={!data.content_type.length > 0}
              ></input>
            </div>
          </div>
          <div className="mt-3">
            {data.name.length > 0 && (
              <EditorPage data={data} setData={setData}></EditorPage>
            )}
          </div>
          <div className="flex flex-row space-x-2 justify-end">
            <Button
              onClick={(e) => saveContent()}
              variant={
                data.error === 0
                  ? "primary"
                  : data.error === 1
                  ? "danger"
                  : data.error === 2
                  ? "success"
                  : data.error === 3 && "secondary"
              }
            >
              {data.error === 0
                ? "Save"
                : data.error === 1
                ? "Failed to Save"
                : data.error === 2
                ? "Saved"
                : data.error === 3 && (
                    <>
                      Saving...
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  )}
            </Button>
            <Button onClick={(e) => cancelContent()} variant="danger">
              Cancel
            </Button>
          </div>
        </>
      )}
    </>
  );
};
export default UpdateContent;
