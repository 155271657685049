import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getAllCategoryWithSubCategory,
  fetchSubCatagories,
} from "../../admin/categories/FetchApi";
import styles from './style.module.css';
const CatagoryList = (props) => {
  useEffect(() => {
    fetchCategoryData();
  }, []);
  const [allCat, setAllCat] = useState([]);
  // const [subCat, setSubCat] = useState([]);
  const [image, setImage] = useState("");
  const [catName, setCatName] = useState("");
  const fetchCategoryData = async () => {
    let responseData = await getAllCategoryWithSubCategory();
    if (responseData && responseData.Categories.length > 0) {
      setAllCat(responseData.Categories);
      setImage(responseData.Categories[0].cImage);
      setCatName(responseData.Categories[0].cName)
    }
  };
  // const fetchSubCatagory = async (cata) => {
  //   let responseData = await fetchSubCatagories(cata);
  //   if (responseData) {
  //     setSubCat(responseData);
  //   }
  // };
  return (
    <>
      <div className={styles.categoryPage + " catListModal"}>
        <div className="absolute w-full top-full inset-x-0 bg-blue-600">
          <div className="cn-2">
            <div className="flex">
              <div className="w-4/5 pt-2 pb-3 md:px-7">
                <div className="flex flex-wrap py-3 -mt-5">
                  {allCat.map((a, i) =>
                    <div onMouseEnter={() => {
                      setImage(a.cImage)
                      setCatName(a.cName)
                    }} className="w-1/5 pt-5">
                      <p className="font-semibold text-white md:mb-1 md:text-lg">
                        <a href={`/shop/${a.cName
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                          .split("/")
                          .join("or")
                          }
                        `}>
                          {a.cName} 
                        </a>
                      </p>
                      {a.cName == "Diamond Watches"?null :a.subCats.map((b, i) =>
                        <a href={`/shop/${a.cName
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                          .split("/")
                          .join("or")
                          }/${b.name
                            .toLowerCase()
                            .split(" ")
                            .join("-")
                            .split("/")
                            .join("or")
                          }
                        `} className="py-1 block text-14 md:text-sm text-white relative" pagetype="page">
                          {b.name}  <span className={styles.rightIcon}><i class="fal fa-arrow-right"></i></span>
                        </a>
                      )}

                    </div>
                  )}


                </div>
              </div>
              <div className="w-1/5 bg-white/80">
                <a href={`/shop/${catName
                  .toLowerCase()
                  .split(" ")
                  .join("-")
                  .split("/")
                  .join("or")
                  }`}>
                  <img style={{ height: 212 }} className={styles.catImage + " w-full object-cover"} src={image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "https://hnmdesigns-images.s3.ap-south-1.amazonaws.com/1663500020014_Category%20Rings.jpg";
                    }}
                  />
                  <div className="p-3 block bg-blue-400 text-14 text-white font-semibold">SHOP NOW</div>
                </a>
                <div className="py-4 md:px-2 ">
                  <a href="#" target="_self" className="block" pagetype="default">
                    <div className="px-3 py-1 flex items-center justify-between">
                      <p className="text-14 md:text-[14px]">Jewellery Certification</p>
                      {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45NzEiIGhlaWdodD0iMTAuOTI1IiB2aWV3Qm94PSIwIDAgMTMuOTcxIDEwLjkyNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQyNDQ1MztzdHJva2U6IzQyNDQ1Mzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMzLjUyOSAtMTUuNTQ1KSByb3RhdGUoMTgwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ3IC0yNS45NjkpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLjc0Nyw0LjczNkguNzYzTDUuMTIxLjM3OUEuMjI1LjIyNSwwLDEsMCw0LjguMDYyTC4wNjUsNC44YS4yMzEuMjMxLDAsMCwwLDAsLjMxOEw0LjgsOS44NTVhLjIyNC4yMjQsMCwwLDAsLjMxOC0uMzE1TC43NjMsNS4xODNIMTIuNzQ3YS4yMjQuMjI0LDAsMCwwLDAtLjQ0N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDQpIi8+PC9nPjwvZz48L3N2Zz4=" alt="Arrow Icon" className="w-3 h-3"> */}
                    </div>
                  </a>
                  <a href="#" target="_self" className="block" pagetype="default_shop">
                    <div className="px-3 py-1 flex items-center justify-between">
                      <p className="text-14 md:text-[14px]">Made In India</p>
                      {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45NzEiIGhlaWdodD0iMTAuOTI1IiB2aWV3Qm94PSIwIDAgMTMuOTcxIDEwLjkyNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQyNDQ1MztzdHJva2U6IzQyNDQ1Mzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMzLjUyOSAtMTUuNTQ1KSByb3RhdGUoMTgwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ3IC0yNS45NjkpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLjc0Nyw0LjczNkguNzYzTDUuMTIxLjM3OUEuMjI1LjIyNSwwLDEsMCw0LjguMDYyTC4wNjUsNC44YS4yMzEuMjMxLDAsMCwwLDAsLjMxOEw0LjgsOS44NTVhLjIyNC4yMjQsMCwwLDAsLjMxOC0uMzE1TC43NjMsNS4xODNIMTIuNzQ3YS4yMjQuMjI0LDAsMCwwLDAtLjQ0N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDQpIi8+PC9nPjwvZz48L3N2Zz4=" alt="Arrow Icon" className="w-3 h-3"> */}
                    </div>
                  </a>

                  <a href="#" target="_self" className="block" pagetype="default">
                    <div className="px-3 py-1 flex items-center justify-between">
                      <p className="text-14 md:text-[14px]">Diamond Education</p>
                      {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45NzEiIGhlaWdodD0iMTAuOTI1IiB2aWV3Qm94PSIwIDAgMTMuOTcxIDEwLjkyNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQyNDQ1MztzdHJva2U6IzQyNDQ1Mzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMzLjUyOSAtMTUuNTQ1KSByb3RhdGUoMTgwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ3IC0yNS45NjkpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLjc0Nyw0LjczNkguNzYzTDUuMTIxLjM3OUEuMjI1LjIyNSwwLDEsMCw0LjguMDYyTC4wNjUsNC44YS4yMzEuMjMxLDAsMCwwLDAsLjMxOEw0LjgsOS44NTVhLjIyNC4yMjQsMCwwLDAsLjMxOC0uMzE1TC43NjMsNS4xODNIMTIuNzQ3YS4yMjQuMjI0LDAsMCwwLDAtLjQ0N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDQpIi8+PC9nPjwvZz48L3N2Zz4=" alt="Arrow Icon" className="w-3 h-3"> */}
                    </div>
                  </a>
                  <a href="#" target="_self" className="block" pagetype="default">
                    <div className="px-3 py-1 flex items-center justify-between">
                      <p className="text-14 md:text-[14px]">Metal & Texture Types</p>
                      {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45NzEiIGhlaWdodD0iMTAuOTI1IiB2aWV3Qm94PSIwIDAgMTMuOTcxIDEwLjkyNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQyNDQ1MztzdHJva2U6IzQyNDQ1Mzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMzLjUyOSAtMTUuNTQ1KSByb3RhdGUoMTgwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ3IC0yNS45NjkpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLjc0Nyw0LjczNkguNzYzTDUuMTIxLjM3OUEuMjI1LjIyNSwwLDEsMCw0LjguMDYyTC4wNjUsNC44YS4yMzEuMjMxLDAsMCwwLDAsLjMxOEw0LjgsOS44NTVhLjIyNC4yMjQsMCwwLDAsLjMxOC0uMzE1TC43NjMsNS4xODNIMTIuNzQ3YS4yMjQuMjI0LDAsMCwwLDAtLjQ0N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDQpIi8+PC9nPjwvZz48L3N2Zz4=" alt="Arrow Icon" className="w-3 h-3"> */}
                    </div>
                  </a>
                  <a href="#" target="_self" className="block" pagetype="default">
                    <div className="px-3 py-1 flex items-center justify-between">
                      <p className="text-14 md:text-[14px]">Free Insured Shipping</p>
                      {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45NzEiIGhlaWdodD0iMTAuOTI1IiB2aWV3Qm94PSIwIDAgMTMuOTcxIDEwLjkyNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQyNDQ1MztzdHJva2U6IzQyNDQ1Mzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMzLjUyOSAtMTUuNTQ1KSByb3RhdGUoMTgwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ3IC0yNS45NjkpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLjc0Nyw0LjczNkguNzYzTDUuMTIxLjM3OUEuMjI1LjIyNSwwLDEsMCw0LjguMDYyTC4wNjUsNC44YS4yMzEuMjMxLDAsMCwwLDAsLjMxOEw0LjgsOS44NTVhLjIyNC4yMjQsMCwwLDAsLjMxOC0uMzE1TC43NjMsNS4xODNIMTIuNzQ3YS4yMjQuMjI0LDAsMCwwLDAtLjQ0N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDQpIi8+PC9nPjwvZz48L3N2Zz4=" alt="Arrow Icon" className="w-3 h-3"> */}
                    </div>
                  </a>
                  <a href="#" target="_self" className="block" pagetype="default">
                    <div className="px-3 py-1 flex items-center justify-between">
                      <p className="text-14 md:text-[14px]">Free Consultation</p>
                      {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45NzEiIGhlaWdodD0iMTAuOTI1IiB2aWV3Qm94PSIwIDAgMTMuOTcxIDEwLjkyNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQyNDQ1MztzdHJva2U6IzQyNDQ1Mzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMzLjUyOSAtMTUuNTQ1KSByb3RhdGUoMTgwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ3IC0yNS45NjkpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLjc0Nyw0LjczNkguNzYzTDUuMTIxLjM3OUEuMjI1LjIyNSwwLDEsMCw0LjguMDYyTC4wNjUsNC44YS4yMzEuMjMxLDAsMCwwLDAsLjMxOEw0LjgsOS44NTVhLjIyNC4yMjQsMCwwLDAsLjMxOC0uMzE1TC43NjMsNS4xODNIMTIuNzQ3YS4yMjQuMjI0LDAsMCwwLDAtLjQ0N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDQpIi8+PC9nPjwvZz48L3N2Zz4=" alt="Arrow Icon" className="w-3 h-3"> */}
                    </div>
                  </a>
                  <a href="#" target="_self" className="block" pagetype="default">
                    <div className="px-3 py-1 flex items-center justify-between">
                      <p className="text-14 md:text-[14px]">Support</p>
                      {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45NzEiIGhlaWdodD0iMTAuOTI1IiB2aWV3Qm94PSIwIDAgMTMuOTcxIDEwLjkyNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQyNDQ1MztzdHJva2U6IzQyNDQ1Mzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMzLjUyOSAtMTUuNTQ1KSByb3RhdGUoMTgwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ3IC0yNS45NjkpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLjc0Nyw0LjczNkguNzYzTDUuMTIxLjM3OUEuMjI1LjIyNSwwLDEsMCw0LjguMDYyTC4wNjUsNC44YS4yMzEuMjMxLDAsMCwwLDAsLjMxOEw0LjgsOS44NTVhLjIyNC4yMjQsMCwwLDAsLjMxOC0uMzE1TC43NjMsNS4xODNIMTIuNzQ3YS4yMjQuMjI0LDAsMCwwLDAtLjQ0N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDQpIi8+PC9nPjwvZz48L3N2Zz4=" alt="Arrow Icon" className="w-3 h-3"> */}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CatagoryList;
