import React, { Fragment,useState } from "react";
import {Helmet} from "react-helmet";
import AdminNavber from "../partials/AdminNavber";
import AdminSidebar from "../partials/AdminSidebar";
import AdminFooter from "../partials/AdminFooter";

const AdminLayout = ({ children }) => {  
const [sidebar,setSidebar] = useState(true)
  const handleSidebar = ()=>{  
    setSidebar(!sidebar)
  }
  return (
    <Fragment>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Admin Panel | H&M Designs | Fine Diamond Jewellery & Diamond Watches</title>
            </Helmet> 
      <AdminNavber handleSidebar={handleSidebar} sidebar={sidebar} />
      <section className="flex bg-gray-100">
        <AdminSidebar sidebar={sidebar} />
        <div className="w-full ">
          {/* All Children pass from here */}
          {children}
        </div>
      </section>
      <AdminFooter />
    </Fragment>
  );
};

export default AdminLayout;
