import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { getRelatedProducts } from "./FetchApi";
import { isWishReq, unWishReq, isWish } from "../home/Mixins";

const RelatedProducts = ({ pPrice, pSubCatagory, custumizable }) => {
  const [products, setProducts] = useState([]);
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList")) || []
  );

  useEffect(() => {
    fetchRelatedProducts();
  }, []);

  const fetchRelatedProducts = async () => {
    let result = await getRelatedProducts({
      pPrice,
      subCatagory: pSubCatagory,
      custumizable
    });
    if (result.Products) {
      setProducts(result.Products);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="my-8 md:mx-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        You may also like
      </h2>
      <Slider className="overflow-hidden px-4" {...settings}>
        {products.map((item) => (
          <ProductCard key={item._id} item={item} wList={wList} setWlist={setWlist} />
        ))}
      </Slider>
    </div>
  );
};

const ProductCard = ({ item, wList, setWlist }) => {
  const productUrl = `/shop/${item.category_url}/${item.sub_category_url}/${item.pName
    .toLowerCase()
    .split(" ")
    .join("-")
    .split("/")
    .join("or")}-${item.modelNumber}`;

  return (
    <Link
      to={productUrl}
      className="block mx-2 no-underline"
    >
      <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:scale-105">
        <div className="relative">
          <img
            className="h-48 w-full object-cover object-center"
            src={item.pImages.length > 0 && item.pImages[0].url}
            alt={item.pName}
          />
          <WishlistButton item={item} wList={wList} setWlist={setWlist} />
        </div>
        <div className="p-4">
          <h3 className="text-sm font-semibold text-gray-800 mb-1 truncate">
            {item.pName}
          </h3>
          <div className="flex items-center justify-between mb-2">
            <span className="text-lg font-bold text-gray-900">₹{Math.ceil(item.pPrice)}</span>
            <div className="flex items-center">
              <StarIcon />
              <span className="text-sm text-gray-600 ml-1">{item.rating}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const WishlistButton = ({ item, wList, setWlist }) => {
  const handleWishlistToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isWish(item._id, wList)) {
      unWishReq(e, item._id, setWlist);
    } else {
      isWishReq(e, item._id, setWlist);
    }
  };

  return (
    <button
      onClick={handleWishlistToggle}
      className="absolute top-2 right-2 p-2 rounded-full bg-white bg-opacity-75 transition-colors duration-200 hover:bg-opacity-100"
    >
      <HeartIcon filled={isWish(item._id, wList)} />
    </button>
  );
};

const StarIcon = () => (
  <svg
    className="w-4 h-4 fill-current text-yellow-500"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
  </svg>
);

const HeartIcon = ({ filled }) => (
  <svg
    className={`w-5 h-5 ${
      filled ? "text-red-500 fill-current" : "text-gray-600"
    }`}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
    />
  </svg>
);

export default RelatedProducts;