import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

const SuggestYourOwn = () => {
  const history = useNavigate();
  const [data, setData] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
    description: "",
    image: "",
    error: "",
    success: "",
    loading: false,
  });

  useEffect(() => {
    setData({ ...data, error: "" });
  }, [
    data.fName,
    data.lName,
    data.email,
    data.phone,
    data.description,
    data.image,
  ]);

  const submitForm = async () => {
    if (
      data.fName === "" ||
      data.lName === "" ||
      data.email === "" ||
      data.phone === "" ||
      data.description === "" ||
      data.image === ""
    ) {
      setData({ ...data, error: "Please fill all the fields" });
    } else {
      setData({ ...data, loading: true });
      let formData = new FormData();
      formData.append("name", data.fName + " " + data.lName);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("description", data.description);
      formData.append("image", data.image);

      let response = await axios.post("/api/product/save-suggestion", data);
      if (response.data.success) {
        setData({
          ...data,
          loading: false,
          success: "Suggestion sent successfully, We will get back to you soon",
        });
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Suggest Your Jewellery || H&M Designs | Fine Diamond Jewellery &
          Diamond Watches
        </title>
        <link rel="canonical" href="https:/hnmdesigns.com" />
      </Helmet>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={data.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container>
        <div className="mt-10 mb-10 sm:mt-0">
          {data.success ? (
            <div className="relative top-20 mt-10  mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3 text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Successful!
                </h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500">{data.success}</p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    id="ok-btn"
                    onClick={() => {
                      history("/shop");
                    }}
                    className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="md:grid md:grid-cols-1 md:gap-6">
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h1 className="text-lg font-medium leading-6 text-gray-900">
                      Suggest your jewellery
                    </h1>
                    <p className="mt-1 text-sm text-gray-600">
                      Jewellery (Jewelry) design is the art of creating ornamental pieces, dating back over 7,000 years to ancient civilizations like those in the Indus Valley, Mesopotamia, and Egypt. The process involves initial conceptual sketches followed by detailed technical drawings by trained designers. While traditional hand-drawing methods are still used, 3D CAD software is increasingly popular. Historically, jewellery was commissioned by nobility and the church, often featuring techniques like enamelling and repoussé to signify status. Since the 1900s, jewellery has evolved with changing styles and social trends, influenced by globalization and materialism. Today, style boundaries are less distinct, reflecting a blend of historical and contemporary trends.
                    </p>
                    <p className="mt-4 text-sm font-semibold text-gray-600">
                      Share your custom design idea, and we'll bring it to life for you.
                    </p>
                  </div>
                </div>
                <form>
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      {data.error ? (
                        <span className="mt-1 text-sm text-red-600">
                          {data.error}
                        </span>
                      ) : null}
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name
                          </label>
                          <input
                            onChange={(e) => {
                              setData({ ...data, fName: e.target.value });
                            }}
                            value={data.fName}
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name
                          </label>
                          <input
                            onChange={(e) => {
                              setData({ ...data, lName: e.target.value });
                            }}
                            value={data.lName}
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <input
                            onChange={(e) => {
                              setData({ ...data, email: e.target.value });
                            }}
                            value={data.email}
                            type="text"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone
                          </label>
                          <input
                            onChange={(e) => {
                              if (
                                !isNaN(parseInt(e.target.value)) &&
                                e.target.value.length <= 10
                              )
                                setData({ ...data, phone: e.target.value });
                            }}
                            value={data.phone}
                            type="text"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-6">
                          <div style={{ margin: "auto" }}>
                            <label className="block text-sm font-medium text-gray-700">
                              Design Image
                            </label>
                            <label
                              htmlFor="file-upload"
                              className="cursor-pointer mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                            >
                              <div className="space-y-1 text-center">
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <div className="flex text-sm text-gray-600">
                                  <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>Upload your design</span>
                                    <input
                                      id="file-upload"
                                      onChange={(e) => {
                                        setData({
                                          ...data,
                                          image: e.target.files[0],
                                        });
                                      }}
                                      name="file-upload"
                                      type="file"
                                      className="sr-only"
                                    />
                                  </label>
                                </div>
                                <p className="text-xs text-gray-500">
                                  PNG, JPG, GIF up to 10MB
                                </p>
                                <p className="text-base font-bold text-gray-500">
                                  {data.image?.name}
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-span-6">
                          <label
                            htmlFor="street-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Please Describe Your Jewellery
                          </label>
                          <textarea
                            onChange={(e) => {
                              setData({ ...data, description: e.target.value });
                            }}
                            value={data.description}
                            name="street-address"
                            id="street-address"
                            autoComplete="street-address"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        onClick={submitForm}
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                <div className="mt-4 text-sm text-gray-500">
                  Note: You are responsible for any copyright issues with the images or design concepts you provide. We are not liable for copyright claims and do not use content from search engines. We use your shared images – concepts solely as inspiration for creating original designs and do not replicate branded products.
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default SuggestYourOwn;