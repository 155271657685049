import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ViewOrder } from "./FetchApi";
import { LayoutContext } from "../index";
function OrderStatus(props) {
  const { data, dispatch } = useContext(LayoutContext);
  const [orderData, setOrderData] = useState({
    orderId: "",
    transactionId: "",
    paymentStatus: "",
  });
  const [countdown, setCountdown] = useState(10); // Initial countdown value
  const history = useNavigate();

  const fetchOrder = async () => {
    try {
      setTimeout(()=>{
        localStorage.removeItem("cart");
        
      },6000)
      let order = await ViewOrder({ orderId: props.orderId });
      setOrderData({
        orderId: order.order_id,
        transactionId: order.tranaction,
        paymentStatus: order.payment,
      });
      startCountdown();
    } catch (e) {
      console.log(e);
    }
  };

  const startCountdown = () => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    // Stop the timer and navigate to /shop after 10 seconds
    setTimeout(() => {
      clearInterval(timer);
      dispatch({ type: "cartProduct", payload: [] });
    }, 10000);
  };

  useEffect(() => {
    if (props.orderId) {
      fetchOrder();
    }
  }, []);

  const copyOrderDataToClipboard = () => {
    const clipboardData = `ORDER ID: ${orderData.orderId}\nTRANSACTION ID: ${orderData.transactionId}\nPAYMENT STATUS: ${orderData.paymentStatus}`;
    navigator.clipboard.writeText(clipboardData).then(() => {
      alert("Order data copied to clipboard!");
    });
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-90 z-50 overflow-hidden">
        <div className="bg-white p-8 rounded-md shadow-lg max-h-full overflow-y-auto w-full md:w-2/3 lg:w-1/2">
          <div className="text-center text-4xl text-green-600 mb-4">
            <i className="fas fa-check-circle"></i>
          </div>
          <h1 className="text-center text-3xl font-semibold text-green-600 mb-4">
            Order Successful
          </h1>
          <div className="pb-2">
            <div>
              <strong>ORDER ID:</strong> {orderData.orderId}
            </div>
            <div>
              <strong>TRANSACTION ID:</strong> {orderData.transactionId}
            </div>
            <div>
              <strong>PAYMENT STATUS:</strong> {orderData.paymentStatus}
            </div>
            {/* Display the product information */}
            <div className="col-span-1 md:col-span-2">
              {props.product.map((item, index) => (
                <div key={index} className="flex items-center space-x-4 pb-2">
                  <img
                    src={item.pImages[0].url}
                    alt={item.pName}
                    className="h-16 w-16 object-cover rounded-md"
                  />
                  <div>
                    <div className="font-semibold">{item.pName}</div>
                    <div className="text-gray-600">
                      {item.pPrice.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <p className="text-center text-gray-600 mt-4">
            We will contact you shortly.
          </p>
          <div className="mt-4 text-center text-gray-600">
            Redirecting to <span className="font-semibold">Shop</span> in{" "}
            <span className="font-semibold">{countdown}</span> seconds...
          </div>
          <div className="mt-4 flex justify-center">
            <button
              onClick={copyOrderDataToClipboard}
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md focus:outline-none"
            >
              Copy Order Data
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderStatus;
