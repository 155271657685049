import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllMaterial = async () => {
  try {
    let res = await axios.get(`/api/material/all-material`, Headers());
    return res.data;
    
  } catch (error) {
    console.log(error);
  }
};

export const createMaterial = async (data) => {

  try {
    let res = await axios.post(
      `/api/material/add-material`,
      data,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editMaterial = async (mId, mPrice, is_deleted) => {
  let data = { mId: mId, mPrice: mPrice, is_deleted };
  try {
    let res = await axios.post(
      `/api/material/edit-material`,
      data,
      Headers()
    );
    console.log(res.data)
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMaterial = async (mId) => {
  try {
    let res = await axios.post(
      `/api/material/delete-material`,
      { mId },
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
