import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};
export const getAllOrder = async (skip, search, status) => {
  try {
    let res = await axios.get(
      `/api/order/get-all-orders?skip=${skip}&search=${search}&status=${status}`,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderImage = async (pId, color) => {
  console.log("trynagetorderimage", pId, color);
  try {
    let res = await axios.get(
      `/api/order/get-order-image?pId=${pId}&color=${color}`,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editCategory = async (oId, status) => {
  let data = { oId: oId, status: status };
  console.log(data);
  try {
    let res = await axios.post(`/api/order/update-order`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteOrder = async (oId) => {
  let data = { oId: oId };
  try {
    let res = await axios.post(`/api/order/delete-order`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const shipOrder = async (oId, trckingID) => {
  let data = { id: oId, tracking_id: trckingID };
  try {
    let res = await axios.post(`/api/order/ship-order`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const changeOrderStatus = async (oId, orderStatus) => {
  let data = { id: oId, orderStatus: orderStatus };
  try {
    let res = await axios.post(`/api/order/changeOrderStatus`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
