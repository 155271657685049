import React, { useState, useEffect } from "react";
import { Table, Button } from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ReactPaginate from "react-paginate";
import { getCustomData } from "./FetchApi";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterOptions from "./FilterOptions";
import { convertToINR } from "../../utils/index";
import {useParams} from "react-router-dom";
const CustomData = (props) => {
  const { customStone, setCustomStone, setNewOne, newOne } = props;
  const [fetchData, setFetchData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [customData, setCustomData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [type, setType] = useState('NATURAL');
  const [tKeys, setTKeys] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('Net Amt(Rs)');
  const [order, setOrder] = useState('asc');
  const params = useParams()

  useEffect(()=>{
  if(Object.keys(newOne)?.length){
    setNewOne({})
  }
  },[params])

  const StoneTypeButton = ({ label, color, isActive, onClick }) => (
    <Button
      variant={isActive ? "contained" : "outlined"}
      color={color}
      onClick={onClick}
      sx={{ mr: 1, mb: 1 }}
    >
      {label}
    </Button>
  );

  const fetchCustomData = async (type, pageNumber, searchTerm, filters, sortColumn, sortOrder) => {
    // setType(type);
    // setPageNumber(pageNumber);
    let resp = await getCustomData(type, (pageNumber * 20), searchTerm, filters, sortColumn, sortOrder);
    if (resp.data.length) {
      setTKeys(Object.keys(resp.data[0]));
    }
    setFetchData(resp.data);
    setTotal(resp.count);
    setCustomData(resp.data);
  };

  useEffect(() => {
    fetchCustomData(type, pageNumber, searchTerm, filters, orderBy, order);
  }, [type, pageNumber, searchTerm, filters, orderBy, order]);

  useEffect(() => {
    setCheckedState(customData.map((a) => ({
      ["Stock Id"]: a['Stock Id'],
      checked: a["Stock Id"] === newOne["Stock Id"]
    })));
  }, [customData, newOne]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleCheckbox = (s) => {
    const updatedCheckedState = checkedState.map((item) =>
      item["Stock Id"] === s
        ? { ...item, checked: !item.checked }
        : item
    );
    setCheckedState(updatedCheckedState);

    const selectedStone = customData.find(stone => stone["Stock Id"] === s);
    if (selectedStone) {
      setCustomStone({
        price: Math.ceil(selectedStone["Net Amt(Rs)"]),
        ["Stock Id"]: selectedStone["Stock Id"],
        data: selectedStone
      });
      setNewOne(selectedStone);
    } else {
      setNewOne({});
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPageNumber(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const columns = [
    { id: 'compare', label: 'Select', sortable: false },
    { id: 'Net Amt(Rs)', label: 'Price', sortable: true, field: 'Net Amt(Rs)' },
    { id: 'Shape', label: 'Shape', sortable: true, field: 'Shape' },
    { id: 'Cts', label: 'Carat', sortable: true, field: 'Cts' },
    { id: 'Cut', label: 'Cut', sortable: true, field: 'Cut' },
    { id: 'Color', label: 'Color', sortable: true, field: 'Color' },
    { id: 'Clarity', label: 'Clarity', sortable: true, field: 'Clarity' },
    { id: 'Lab', label: 'Certificate Lab', sortable: true, field: 'Lab' },
    // { id: 'details', label: 'Details', sortable: false },
  ];

  const renderCellContent = (stone, column) => {
    if (column.id === 'compare') {
      return (
        <input
          type="checkbox"
          checked={newOne["Stock Id"] === stone["Stock Id"]}
          onChange={() => handleCheckbox(stone["Stock Id"])}
        />
      );
    }

    if (column.id === 'Net Amt(Rs)') {
      return convertToINR(parseFloat(stone[column.field]));
    }
    const value = stone[column.field];
    if (typeof value === 'object' && value !== null) {
      return value.text || JSON.stringify(value);
    }
    return value;
  };

  return (
    <div id="centerStoneTable">
      <h4 className="font-bold text-lg mb-4">Choose Your Center Stone</h4>
      {newOne && Object.keys(newOne).length > 0 && (
        <TableContainer component={Paper} sx={{ mb: 4, backgroundColor: '#e0f2f1' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Carat</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Clarity</TableCell>
                <TableCell>Stone ID</TableCell>
                <TableCell>Net Rate</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{newOne.Cts}</TableCell>
                <TableCell>{newOne.Color}</TableCell>
                <TableCell>{newOne.Clarity}</TableCell>
                <TableCell>{newOne["Stock Id"]}</TableCell>
                <TableCell>{parseFloat(newOne["Net Amt(Rs)"]).toFixed(2)}</TableCell>
                <TableCell>{newOne["type"] === "LAB"?"LAB":"NATURAL"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className="flex flex-wrap items-center justify-between mb-4">
        <input
          className="p-2 border rounded"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Stone"
        />
        <div>
          <StoneTypeButton
            label="Lab Grown Diamonds"
            color="success"
            isActive={type === 'LAB'}
            onClick={() => setType('LAB')}
          />
          <StoneTypeButton
            label="Natural Diamonds"
            color="primary"
            isActive={type === 'NATURAL'}
            onClick={() => setType('NATURAL')}
          />
        </div>
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => {
            setShowFilter(!showFilter);
            showFilter && handleFilterChange({});
          }}
        >
          Filter
        </Button>
      </div>
      {showFilter && (
        <FilterOptions onFilterChange={handleFilterChange} />
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customData.map((stone) => (
              <TableRow
                key={stone._id}
                onClick={() => handleCheckbox(stone["Stock Id"])}
                selected={newOne["Stock Id"] === stone["Stock Id"]}
                hover
              >
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {renderCellContent(stone, column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center mt-4 max-screen overflow-x-auto">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(total / 20)}
          forcePage={pageNumber}
          onPageChange={changePage}
          containerClassName={"pagination flex"}
          pageClassName={"mx-1"}
          pageLinkClassName={"px-3 py-2 border rounded hover:bg-blue-600 no-underline"}
          activeClassName={"bg-blue-600 text-gray-200"}
          activeLinkClassName={"bg-blue-600 text-gray-200"}
          previousClassName={"mx-1"}
          nextClassName={"mx-1"}
          previousLinkClassName={"px-3 py-2 border rounded hover:bg-blue-600 no-underline"}
          nextLinkClassName={"px-3 py-2 border rounded hover:bg-blue-600 no-underline"}
          disabledClassName={"opacity-50 cursor-not-allowed"}
        />
      </div>
    </div>
  );
};

export default CustomData;