import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { newPassword } from './fetchApi';
import { toast } from 'react-toastify';
import { 
  Button, TextField, Typography, Container, Paper, InputAdornment, IconButton
} from '@mui/material';
import { Visibility, VisibilityOff, Lock } from '@mui/icons-material';

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: '',
    cPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.password) newErrors.password = 'Password is required';
    if (!formData.cPassword) newErrors.cPassword = 'Confirm password is required';
    if (formData.password !== formData.cPassword) {
      newErrors.password = 'Passwords do not match';
      newErrors.cPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const responseData = await newPassword({ 
        password: formData.password, 
        cPassword: formData.cPassword, 
        token 
      });
      if (responseData.message) {
        toast.success(responseData.message);
        setTimeout(() => navigate('/'), 3000);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Container component="main" maxWidth="xs" className="mt-8">
      <Paper elevation={3} className="p-8 rounded-lg">
        <Typography component="h1" variant="h5" className="text-center mb-4">
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit} className="space-y-4">
          <TextField
            fullWidth
            label="New Password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className="transition-all duration-300 ease-in-out transform hover:scale-105"
          />
          <TextField
            fullWidth
            label="Confirm New Password"
            name="cPassword"
            type={showPassword ? 'text' : 'password'}
            value={formData.cPassword}
            onChange={handleChange}
            error={!!errors.cPassword}
            helperText={errors.cPassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
            }}
            className="transition-all duration-300 ease-in-out transform hover:scale-105"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="mt-3 transition-all duration-300 ease-in-out transform hover:scale-105"
          >
            Reset Password
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ResetPassword;