import React, { Fragment, useContext, useState, useEffect } from "react";

function ExtraStone({ extraStones, Stones,custumizable }) {
    const [error, setError] = useState("");
    const [stoneName, setStoneName] = useState("");
    const [stonePrice, setStonePrice] = useState(0);
    const addMore = () => {
        if (stoneName && stonePrice) {
            const UpdatedStones = [...Stones, { stoneName: stoneName, stonePrice: stonePrice, replacable: false }]
            extraStones(UpdatedStones);
            updateAccordingToCustomizable(UpdatedStones)
            setStoneName("");
            setStonePrice(0);
        } else {
            setError("Please Enter Valid Input");
            setStoneName("");
            setStonePrice(0);
        }
    };
    const remove = (index) => {
        const newStones = [...Stones];
        newStones.splice(index, 1);
        extraStones(newStones);
        updateAccordingToCustomizable(newStones)
    };

    const updateAccordingToCustomizable = (UpdatedStones)=>{
        if(UpdatedStones[0] && custumizable){
            if(!Stones.some((a)=>a.replacable)){
                const updatedStones = UpdatedStones.map((stone, j) => ({
                    ...stone,
                    replacable: j === 0 ? true : false,
                }));
                extraStones(updatedStones);
            }
            
        }else{
            const updatedStones = UpdatedStones.map((stone, j) => ({
                ...stone,
                replacable:  false,
            }));
            extraStones(updatedStones);
        }
    }
    useEffect(()=>{
        updateAccordingToCustomizable(Stones)
    },[custumizable])
    return (
        <>
            <table className="text-center" border="5" cellspacing="5">
                {error ? <span className="text-red-400">{error}</span> : ""}
                <thead>
                    <tr>
                        {custumizable?<th>Replacable</th>:null}
                        <th>Stone Name</th>
                        <th>Stone Price</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Stones && Stones.map((s, i) => (
                        <tr key={i}>
                            {custumizable?<td><input checked={s.replacable} onClick={() => {
                                const updatedStones = Stones.map((stone, j) => ({
                                    ...stone,
                                    replacable: j === i ? true : false,
                                }));
                                extraStones(updatedStones);
                            }} type="radio" name="extraStone"></input></td>:null}
                            <td>{s.stoneName}</td>
                            <td>{s.stonePrice}</td>
                            <td>
                                <i onClick={() => remove(i)} className="fa fa-trash"></i>
                            </td>
                        </tr>
                    ))}
                    <tr className="">
                    {custumizable?<td></td>:null}
                        <td>
                            <input
                                type="text"
                                value={stoneName}
                                onChange={(e) => setStoneName(e.target.value)}
                                onFocus={(e) => setError("")}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            ></input>
                        </td>
                        <td>
                            <input
                                type="number"
                                value={stonePrice}
                                onFocus={(e) => setError("")}
                                onChange={(e) => setStonePrice(e.target.value)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            ></input>
                        </td>
                        <td>
                            <i
                                class="fas fa-plus ml-3"
                                aria-hidden="true"
                                onClick={addMore}
                            ></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
export default ExtraStone;
