import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  CircularProgress, 
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { getOrderByUser } from './FetchApi';
import OrderList from './OrderHistory';
import Layout from "./Layout";
const OrderHistoryPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await getOrderByUser();
        setOrders(response.orders);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch orders:', err);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const filteredOrders = orders.filter(order => 
    order.order_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.products.some(product => 
      product.product.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <Container fluid className="py-8">
      
      <Box className="mb-6">
        <TextField
          fullWidth
          placeholder="Search orders by ID or product"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {loading ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <OrderList orders={filteredOrders} />
      )}
    </Container>
  );
};

const UserOrders = (props) => {
  return (
    <>
      <Layout children={<OrderHistoryPage />} />
    </>
  );
};

export default UserOrders;