import React, { Fragment, useContext, useEffect, useState } from "react";
import { getAllProductAdmin, deleteProduct } from "./FetchApi";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { ProductContext } from "./index";
import UploadProductImage from "./uploadImage";
import PhotoPandent from "./photoPendantModal";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
// const apiURL = process.env.REACT_APP_API + "/";

const AllProduct = (props) => {
  const { data, dispatch } = useContext(ProductContext);
  const { products } = data;
  console.log("prods>", products);

  let [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  let [count, setCount] = useState(0);
  let [skip, setSkip] = useState(0);
  const [search, setSearch] = useState("");
  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    setLoading(true);
    let responseData = await getAllProductAdmin(page * 8 - 8, search);
    console.log("stroke", responseData);
    setTimeout(() => {
      if (responseData && responseData.Products) {
        setCount(Math.ceil(responseData.count / 8));
        dispatch({
          type: "fetchProductsAndChangeState",
          payload: responseData.Products,
        });
        setLoading(false);
      }
    }, 1000);
  };

  const deleteProductReq = async (pId) => {
    let deleteC = await deleteProduct(pId);
    if (deleteC.error) {
      console.log(deleteC.error);
    } else if (deleteC.success) {
      console.log(deleteC.success);
      fetchData();
    }
  };

  /* This method call the editmodal & dispatch product context */
  const editProduct = (pId, product, type) => {
    if (type) {
      dispatch({
        type: "editProductModalOpen",
        product: { ...product, pId: pId },
      });
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const [photoPandentModalShow, setphotoPandentModalShow] = useState(false);
  const [pId, setPId] = useState("");
  const imageModal = (pId) => {
    setPId(pId);
    setModalShow(true);
  };
  const photoPandentModal = (pId) => {
    setPId(pId);
    setphotoPandentModalShow(true);
  };
  const searchTable = () => {
    console.log("searchin");
    fetchData();
  };
  // if (loading) {
  //   return (
  //     <div class="flex h-96">
  //       <div class="m-auto">
  //         <Spinner animation="border" role="status"></Spinner>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <Fragment>
      <div className="col-span-1 overflow-auto bg-white shadow-lg p-4 text-sm">
        <div class="flex  rounded justify-between">
          <div className="flex items-center">
            {/* It's open the add product modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) =>
                dispatch({ type: "addProductModal", payload: true })
              }
              className="rounded-full cursor-pointer p-2 bg-gray-800 flex items-center text-gray-100 text-sm font-semibold uppercase"
            >
              <svg
                className="w-6 h-6 text-gray-100 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clipRule="evenodd"
                />
              </svg>
              Add Product
            </span>
          </div>
          <div className="flex justify-end">
            <input
              type="text"
              class="px-4 py-2 w-80 bg-transparent outline-black focus:outline-none "
              value={search}
              placeholder="Search..."
              onChange={(e) => {
                setSearch(e.target.value);
                searchTable();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // Call your search function here
                  searchTable();
                  // setSearch(e.target.value);
                }
              }}
              onKeyDownCapture={(e) => {
                if (e.key === "Enter") {
                  // Call your search function here
                  searchTable();
                  // setSearch(e.target.value);
                }
              }}
            />
            <button
              onClick={searchTable}
              class="flex items-center  justify-center px-4 border-l hover:bg-gray-400 hover:text-black"
            >
              <svg
                class="w-6 h-6 text-black"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
              </svg>
            </button>
          </div>
        </div>
        <UploadProductImage
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            fetchData();
          }}
          pid={pId}
        ></UploadProductImage>
        <PhotoPandent
          show={photoPandentModalShow}
          onHide={() => {
            setphotoPandentModalShow(false);
            fetchData();
          }}
          pid={pId}
        ></PhotoPandent>
        <table className="table-auto border w-full my-2">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Model No</th>
              <th className="px-4 py-2 border">Product</th>
              <th className="px-4 py-2 border">Views</th>
              <th className="px-4 py-2 border">Image</th>
              <th className="px-4 py-2 border">Status</th>
              <th className="px-4 py-2 border">Category</th>
              <th className="px-4 py-2 border">Sub Category</th>
              <th className="px-4 py-2 border">Created on</th>
              {/* <th className="px-4 py-2 border">Updated on</th> */}
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody style={{ maxHeight: "50px", overflowY: "scroll" }}>
            {products && products.length > 0 ? (
              products.map((item, key) => {
                return (
                  <ProductTable
                    product={item}
                    editProduct={(pId, product, type) =>
                      editProduct(pId, product, type)
                    }
                    imageModal={imageModal}
                    photoPandentModal={photoPandentModal}
                    deleteProduct={(pId) => deleteProductReq(pId)}
                    key={key}
                  />
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="10"
                  className="text-xl text-center font-semibold py-8"
                >
                  {/* <Spinner animation="border" role="status"></Spinner> */}
                  NO PRODUCTS FOUND
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="text-sm text-center text-gray-600 mt-2"></div>
        <Stack className="text-center" spacing={2}>
          <Pagination
            page={page}
            onChange={(event, val) => {
              setPage(val);
            }}
            count={count}
            color="primary"
          />
        </Stack>
      </div>
    </Fragment>
  );
};

/* Single Product Component */
const ProductTable = ({
  product,
  deleteProduct,
  editProduct,
  imageModal,
  photoPandentModal,
}) => {
  return (
    <Fragment>
      <tr>
        <td className="p-2 text-left">{product.modelNumber}</td>
        <td className="p-2 text-left">
          <a
            target="_blank"
            href={`/shop/${product.pCategory.cName
              .toLowerCase()
              .split(" ")
              .join("-")
              .split("/")
              .join("or")}/${product.pSubCategory.name
              .toLowerCase()
              .split(" ")
              .join("-")
              .split("/")
              .join("or")}/${product.pName
              .toLowerCase()
              .split(" ")
              .join("-")
              .split("/")
              .join("or")}-${product.modelNumber}`}
          >
            {" "}
            {product.pName.length > 15
              ? product.pName
              : product.pDescription.substring(1, 15) + "..."}
          </a>
        </td>
        <td className="p-2 text-left">{product.viewCount}</td>
        <td className="p-2 text-center">
          <img
            className="w-12 h-12 object-cover object-center"
            src={
              product.pImages.length == 0
                ? "dummyimage"
                : product.pImages[0]?.url
            }
            alt="pic"
          />
        </td>
        <td className="p-2 text-center">
          {product.pStatus ? (
            <span className="bg-green-200 rounded-full text-center text-xs px-2 font-semibold">
              Active
            </span>
          ) : (
            <span className="bg-red-200 rounded-full text-center text-xs px-2 font-semibold">
              InActive
            </span>
          )}
        </td>
        <td className="p-2 text-center">{product.pCategory.cName}</td>
        <td className="p-2 text-center">{product.pSubCategory.name}</td>
        <td className="p-2 text-center">
          {/* {moment(product.createdAt).format("lll")} */}
          {moment(product.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        </td>
        {/* <td className="p-2 text-center">
          {moment(product.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
        </td> */}
        <td className="p-2 flex items-center justify-center">
          {product.pSubCategory.name == "Photo Pendants" && (
            <span
              onClick={(e) => photoPandentModal(product._id)}
              className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
            >
              <i class="fas fa-upload"></i>
            </span>
          )}
          <span
            onClick={(e) => imageModal(product._id)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <i class="fas fa-camera"></i>
          </span>
          <span
            onClick={(e) => editProduct(product._id, product, true)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-green-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span
            onClick={(e) => deleteProduct(product._id)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </td>
      </tr>
    </Fragment>
  );
};

export default AllProduct;
