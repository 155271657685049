import React, { useState, Fragment, createContext } from "react";
import { Navber, Footer, CartModal } from "../partials";
import LoginSignup from "../auth/LoginSignup";
import MobileViewNav from "../partials/MobileViewNav";
import Filter from "../partials/Filter";
import { BasicSpeedDial } from "../partials";
import { useLocation } from "react-router-dom";
export const LayoutContext = createContext();

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const isLogin = localStorage.getItem("jwt");
  const [open, setOpen] = React.useState(false);
  return (
    <Fragment>
      <div className="flex-grow body-min-height">
        <Navber setOpen={setOpen} />
        <LoginSignup isLogin={isLogin} />
        <CartModal />
        {pathname.startsWith('/shop') && pathname !== '/shop/diamond-watches' && pathname !== '/shop/diamond-watches/diamond-watches' && pathname.split('/').length<5 ?<Filter></Filter>:null}
        <div>{children}</div>
      </div>
      <BasicSpeedDial open={open} setOpen={setOpen}></BasicSpeedDial>
      {/* <div className="fixed bottom-0 right-0 mb-20 mr-5 md:mb-10 z-50 md:mr-10"><a target="_blank" href="https://api.whatsapp.com/message/WWVUN5G3GFEKF1"><img className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" src="https://img.icons8.com/office/60/000000/whatsapp--v1.png"/></a></div> */}
      <MobileViewNav />
      <Footer isLogin={isLogin} />
    </Fragment>
  );
};

export default Layout;
