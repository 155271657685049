import React, { Fragment,useState,useEffect} from "react";
import {useParams} from "react-router-dom";
import {getContents} from "../admin/content/FetchApi"
import {Helmet} from "react-helmet";
const ContentShow = () =>{ 
const {id} =  useParams()
const [content,setContent] = useState({})
useEffect(() => {
    fetchContents()
},[id])
const fetchContents = async () => {
    let constentsData = sessionStorage.getItem("contents")
    if(constentsData){
      try {
        let resp =  JSON.parse(constentsData).filter((c)=>c.url==id)
        return setContent(resp[0]);
      } catch (error) {
        
      }
    }
    let response = await getContents();
    sessionStorage.setItem("contents",JSON.stringify(response))
    if(response){  
        let resp = response.filter((c)=>c.url==id)
        setContent(resp[0]);
    }
    
  };
return(  
    <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${content.name}`} | H&M Designs | Fine Diamond Jewellery & Diamond Watches</title>
                <link rel="canonical" href={`/contents${content.url}`}/>
            </Helmet>
    <div className="p-3 text-start container">
        <div className="ck-content" dangerouslySetInnerHTML={{ __html: content.content }}></div>
    </div>
    </>
)
}
export default ContentShow;