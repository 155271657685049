import React, { useEffect, useContext } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { DashboardContext } from './';
import { GetAllData } from './Action';
import { People, ShoppingCart, Inventory, Category } from '@mui/icons-material';

const DashboardCard = () => {
  const { data, dispatch } = useContext(DashboardContext);

  useEffect(() => {
    GetAllData(dispatch);
  }, [dispatch]);

  const cardData = [
    { title: 'Customers', value: data?.totalData.Users ?? 0, icon: People, color: 'bg-indigo-100 text-indigo-600' },
    { title: 'Orders', value: data?.totalData.Orders ?? 0, icon: ShoppingCart, color: 'bg-red-100 text-red-600' },
    { title: 'Products', value: data?.totalData.Products ?? 0, icon: Inventory, color: 'bg-green-100 text-green-600' },
    { title: 'Categories', value: data?.totalData.Categories ?? 0, icon: Category, color: 'bg-orange-100 text-orange-600' },
  ];

  return (
    <Grid container spacing={3} className="p-4">
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Paper elevation={3} className="transition-all duration-300 hover:shadow-lg">
            <Box className="p-4 flex flex-col items-center">
              <Box className={`p-2 rounded-full ${card.color}`}>
                <card.icon className="w-8 h-8" />
              </Box>
              <Typography variant="h4" className="mt-2 font-semibold">
                {card.value}
              </Typography>
              <Typography variant="subtitle1" className="text-gray-600">
                {card.title}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardCard;