import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const TestConnection = () => {
  const [test, setTest] = useState(true);
  const customId1 = "1";
  const customId2 = "2";
  window.addEventListener("online", () => {
    if (!test) {
      toast.success("Back Online", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: customId1,
      });
      setTest(true);
    }
  });
  window.addEventListener("offline", () => {
    if (test) {
      toast.error("No Internet Connection", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: customId2,
      });
      setTest(false);
    }
  });
  return null;
};
