import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllCategory = async () => {
  try {
    let res = await axios.get(`/api/category/all-category`, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getAllCategoryWithSubCategory = async () => {
  try {
    let res = await axios.get(`/api/category/all-category-withsubcat`, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const createCategory = async ({
  cName,
  cImage,
  cDescription,
  cStatus,
  sizeRequired,
  subCatagories,
  priceConstant,
  isPhotoPendant,
}) => {
  let formData = new FormData();
  formData.append("cImage", cImage);
  formData.append("cName", cName);
  formData.append("cDescription", cDescription);
  formData.append("cStatus", cStatus);
  formData.append("sizeRequired", sizeRequired);
  formData.append("priceConstant", priceConstant);
  formData.append("isPhotoPendant", isPhotoPendant);
  formData.append("subCatagories", JSON.stringify(subCatagories));

  try {
    let res = await axios.post(
      `/api/category/add-category`,
      formData,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editCategory = async (cId, des, status,cImage) => {
  let formData = new FormData();
  formData.append("cId", cId);
  formData.append("cDescription", des);
  formData.append("cStatus", status);
  formData.append("cImage", cImage);
  try {
    let res = await axios.post(
      `/api/category/edit-category`,
      formData,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategory = async (cId) => {
  try {
    let res = await axios.post(
      `/api/category/delete-category`,
      { cId },
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSubCatagories = async(cat)=> {
  try{  
    let res = await axios.get(`/api/category/fetch-subCatagories/${cat}`)
    return res.data;
  }
  
  catch(error) {  
    console.log(error);
  }
}

export const fetchSubCatagoriesAdmin = async(cat)=> {
  try{  
    let res = await axios.get(`/api/category/fetch-subCatagories-admin/${cat}`)
    return res.data;
  }
  
  catch(error) {  
    console.log(error);
  }
}

export const activeSubCatagories = async({id,active})=> {
  try{  
    let res = await axios.post(`/api/category/active-subcats`, {id,active},Headers())
    return res.data;
  }
  
  catch(error) {  
    console.log(error);
  }
}

export const addSubCatagories = async({catagory,name})=> {
  try{  
    let res = await axios.post(`/api/category/add-sub-category`, {catagory,name},Headers())
    return res.data;
  }
  
  catch(error) {  
    console.log(error);
  }
}
