import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutContext } from '../index';
import { loginReq, signupReq, forgotPasswordReq, newPassword } from './fetchApi';
import { toast } from 'react-toastify';
import { 
  Button, TextField, Checkbox, FormControlLabel, Link, 
  Typography, Box, Paper, InputAdornment, IconButton,
  Fade, Grow, Dialog, DialogContent, DialogTitle
} from '@mui/material';
import { Visibility, VisibilityOff, Email, Lock, Person, Close } from '@mui/icons-material';

const AuthComponent = ({ open, onClose, initialMode = 'login' }) => {
  const [mode, setMode] = useState(initialMode);
  const [showPassword, setShowPassword] = useState(false);
  const { dispatch: layoutDispatch } = useContext(LayoutContext);
  const navigate = useNavigate();
  const { token } = useParams();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    cPassword: '',
    rememberMe: false,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (mode === 'login') {
      const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
      const savedEmail = localStorage.getItem('email') || '';
      const savedPassword = localStorage.getItem('password') || '';

      setFormData(prevData => ({
        ...prevData,
        rememberMe: rememberMeValue,
        email: rememberMeValue ? savedEmail : '',
        password: rememberMeValue ? savedPassword : '',
      }));
    }
  }, [mode]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (mode !== 'resetPassword' && !formData.email) newErrors.email = 'Email is required';
    if (mode !== 'forgotPassword' && !formData.password) newErrors.password = 'Password is required';
    if (mode === 'signup') {
      if (!formData.name) newErrors.name = 'Name is required';
      if (formData.password !== formData.cPassword) {
        newErrors.password = 'Passwords do not match';
        newErrors.cPassword = 'Passwords do not match';
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      let responseData;
      switch (mode) {
        case 'login':
          responseData = await loginReq({ email: formData.email, password: formData.password });
          if (responseData.token) {
            localStorage.setItem('jwt', JSON.stringify(responseData));
            if (formData.rememberMe) {
              localStorage.setItem('rememberMe', 'true');
              localStorage.setItem('email', formData.email);
              localStorage.setItem('password', formData.password);
            } else {
              localStorage.removeItem('rememberMe');
              localStorage.removeItem('email');
              localStorage.removeItem('password');
            }
            layoutDispatch({ type: 'loginSignupModalToggle', payload: false });
            layoutDispatch({ type: 'logedinUser', payload: true });
            navigate('/');
            toast.success('Login Successful');
            onClose();
          }
          break;
        case 'signup':
          responseData = await signupReq(formData);
          if (responseData.success) {
            toast.success(responseData.success);
            setMode('login');
          }
          break;
        case 'forgotPassword':
          responseData = await forgotPasswordReq({ email: formData.email });
          if (responseData.message) {
            toast.success(responseData.message);
          }
          break;
        case 'resetPassword':
          responseData = await newPassword({ password: formData.password, cPassword: formData.cPassword, token });
          if (responseData.message) {
            toast.success(responseData.message);
            setTimeout(() => navigate('/'), 3000);
            onClose();
          }
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      className="rounded-lg"
      // Add this line to enable closing on backdrop click
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle className="flex justify-between items-center">
        <Typography variant="h6">
          {mode === 'login' ? 'Login' : mode === 'signup' ? 'Sign Up' : mode === 'forgotPassword' ? 'Forgot Password' : 'Reset Password'}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Fade in={true} timeout={500}>
          <Paper elevation={0} className="p-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              {mode === 'signup' && (
                <Grow in={true} timeout={500}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person />
                        </InputAdornment>
                      ),
                    }}
                    className="transition-all duration-300 ease-in-out transform hover:scale-105"
                  />
                </Grow>
              )}
              {mode !== 'resetPassword' && (
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  className="transition-all duration-300 ease-in-out transform hover:scale-105"
                />
              )}
              {mode !== 'forgotPassword' && (
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className="transition-all duration-300 ease-in-out transform hover:scale-105"
                />
              )}
              {(mode === 'signup' || mode === 'resetPassword') && (
                <Grow in={true} timeout={500}>
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    name="cPassword"
                    type={showPassword ? 'text' : 'password'}
                    value={formData.cPassword}
                    onChange={handleChange}
                    error={!!errors.cPassword}
                    helperText={errors.cPassword}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                    className="transition-all duration-300 ease-in-out transform hover:scale-105"
                  />
                </Grow>
              )}
              {mode === 'login' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="rememberMe"
                      checked={formData.rememberMe}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Remember me"
                />
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="mt-3 bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                {mode === 'login' ? 'Login' : mode === 'signup' ? 'Sign Up' : mode === 'forgotPassword' ? 'Reset Password' : 'Update Password'}
              </Button>
            </form>
            <Box mt={2}>
              {mode === 'login' && (
                <>
                  <Link href="#" variant="body2" onClick={() => setMode('forgotPassword')} className="hover:underline">
                    Forgot password?
                  </Link>
                  <Typography variant="body2" className="mt-2">
                    Don't have an account?{' '}
                    <Link href="#" onClick={() => setMode('signup')} className="hover:underline">
                      Sign Up
                    </Link>
                  </Typography>
                </>
              )}
              {mode === 'signup' && (
                <Typography variant="body2">
                  Already have an account?{' '}
                  <Link href="#" onClick={() => setMode('login')} className="hover:underline">
                    Login
                  </Link>
                </Typography>
              )}
              {mode === 'forgotPassword' && (
                <Typography variant="body2">
                  Remember your password?{' '}
                  <Link href="#" onClick={() => setMode('login')} className="hover:underline">
                    Login
                  </Link>
                </Typography>
              )}
            </Box>
          </Paper>
        </Fade>
      </DialogContent>
    </Dialog>
  );
};

export default AuthComponent;