import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"

const ProductImage = (props) => {
  // const images = [
  //     {
  //       original: 'https://picsum.photos/id/1018/1000/600/',
  //       thumbnail: 'https://picsum.photos/id/1018/250/150/',
  //     },
  //     {
  //       original: 'https://picsum.photos/id/1015/1000/600/',
  //       thumbnail: 'https://picsum.photos/id/1015/250/150/',
  //     },
  //     {
  //       original: 'https://picsum.photos/id/1019/1000/600/',
  //       thumbnail: 'https://picsum.photos/id/1019/250/150/',
  //     },
  //   ];
  const slider = useRef(null);
  const images = props.images.reduce((r, i) => {
    if (i.type === "photo") {
      r.push({
        original: i.url,
        thumbnail: i.url,
        originalHeight:300
      });
    }
    return r;
  }, []);
  function onSlide(e) {
    // props.setCount(e)
  }
  useEffect(() => {
    slider.current.slideToIndex(props.count)
  },[props.count])
  const options = {
    thumbnailPosition: "left",
  };
  return (
    <>
      <ImageGallery
        onSlide={onSlide}
        ref={slider}
        {...options}
        items={images}
      />
    </>
  );
};
export default ProductImage;
