import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';
export const getAllCustomProducts =async (skip) =>{  
    try {
        let res = await axios.get(`/api/customJwe/getAllCustomProducts?skip=${skip}`);
        console.log(res)
        return res.data;
      } catch (error) {
        console.log(error);
      }
}

export const getCustomProduct = async ()=>{
  try{ 
    let res = await axios.get(`/api/customJwe/getCustomProduct`)
   }
  catch(e){  
    console.log(e);
  }
}