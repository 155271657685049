import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';

export const getAllCustomImages = async() =>{
    try{
        let response = await axios.get(`/api/customize/get-slide-image`)
        console.log(response)
        return response.data
    }catch(e){
        console.log(e)
        }
}
export const getAllSec1Images = async() =>{
    try{
        let response = await axios.get(`/api/customize/get-sec1-image`)
        console.log(response)
        return response.data
    }catch(e){
        console.log(e)
        }
}