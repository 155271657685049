export const materialState = {
  materials: [],
  addMaterialModal: false,
  editMaterialModal: {
    modal: false,
    mId: null,
    mPrice: "",
    is_deleted:false
  },
  loading: false,
};

export const materialReducer = (state, action) => {
  switch (action.type) {
    /* Get all category */
    case "fetchMaterialAndChangeState":
      return {
        ...state,
        materials: action.payload,
      };
    /* Create a category */
    case "addMaterialModal":
      return {
        ...state,
        addMaterialModal: action.payload,
      };
    /* Edit a category */
    case "editMaterialModalOpen":
      return {
        ...state,
        editMaterialModal: {
          modal: true,
          mId: action.mId,
          mPrice: action.mPrice,
          is_deleted:action.is_deleted
        },
      };
    case "editMaterialModalClose":
      return {
        ...state,
        editMaterialModal: {
          modal: false,
          mId: null,
          mPrice: "",
          is_deleted:false
        },
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
