import React from "react";
import { Dialog } from "@mui/material";
import sizeImg from "../../../assets/size_guide.jpeg";
function SizeGuide({ open, setOpen }) {
  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <img src={sizeImg}></img>
    </Dialog>
  );
}

export default SizeGuide;
