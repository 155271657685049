import React, { Fragment, useEffect, useState, useContext } from "react";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "./Layout";
import { DashboardUserContext } from "./Layout";
import { getOrderByID } from "./FetchApi";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "@mui/material";
const OrdersComponent = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const fetchOrder = async () => {
    const response = await getOrderByID(id);
    setOrder(response);
    setActiveStep(response.status_steps.length);
  };
  useEffect(() => {
    fetchOrder();
  }, []);
  const [activeStep, setActiveStep] = useState(0);
const [productsHeader,setProductsHeader] = useState(true);
  return (
    <>
      {order.order_id && (
        <Accordion className="w-full md:w-2/5 mx-auto">
          <Accordion.Item eventKey={1}>
            <Accordion.Header onClick={()=>setProductsHeader(true)}>{order.status}, {moment(order.last_updated).format('LLL')}</Accordion.Header>
            <Accordion.Body>
            <Stepper activeStep={activeStep} orientation="vertical">
        {order.status_steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.status}, {moment(step.updated_on).format('LLL')}
            </StepLabel>
          </Step>
        ))}
        <Step key={"sssss"} >
            <StepLabel
            >
              Expected Delivery Date
            </StepLabel>
            <StepContent>
            {moment(Date.now()).format('LLL')}
            </StepContent>
          </Step>
      </Stepper>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={2}>
            <Accordion.Header onClick={()=>setProductsHeader(!productsHeader)}>
            {productsHeader?<>
              <div className="w-full pr-4">
              {order.products.map((p, i) => (
                <>
                {i==0 &&

                <div className="flex justify-between text-sm">
                  <img src={p.image.url} className="h-8" />
                  <div>{p.product.pName}</div>
                  <div>{p.amount}.00</div>
                </div>
                 
                
                }
                </>
              ))}
             
             {order.products?.length >1  &&   <div className="text-center border-b-2 leading-3 w-full"><span style={{padding:"0 10px"}} className="bg-white text-gray-400 text-sm">{order.products?.length-1} more product{order.products?.length-1 >1 && "s" }</span></div>}
             
              </div>
              </>:
              "Products"
              }
            </Accordion.Header>
            <Accordion.Body>
              <div className="px-4 space-y-2">
                {order.products.map((p, i) => (
                  <div className="bg-gray-200 p-2 rounded">
                    <div>
                      <img src={p.image.url} className="h-10" />
                      <div className="flex justify-between">
                      <div>{p.product.modelNumber}</div>
                      <div>{p.product.pName}</div>
                        <div>{p.amount}.00</div>
                        
                      </div>
                      <div className="flex justify-between">
                        <div>Color</div>
                        <div>{p.color.name}</div>
                      </div>
                      {!p.weight && 
                      <div className="flex justify-between">
                      <div>Size</div>
                      <div>{p.size.size}</div>
                    </div>
                      }
                      <div className="flex justify-between">
                        <div>Weight</div>
                        <div>{p.weight?p.weight:p.size.weight} gram</div>
                      </div>
                      <div className="flex justify-between">
                        <div>Diamonds</div>
                        <div className="uppercase">{p.diamond.mName}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={3}>
            <Accordion.Header onClick={()=>setProductsHeader(true)}></Accordion.Header>
            <Accordion.Body></Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

const UserOrders = (props) => {
  return (
    <Fragment>
      <Layout children={<OrdersComponent />} />
    </Fragment>
  );
};

export default UserOrders;
