import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';
const {sign,verify} = require("jsonwebtoken");
export const isAuthenticate = () =>{
return localStorage.getItem("jwt")? JSON.parse(localStorage.getItem("jwt")) : false;
}
export const isAdmin = () =>{
  let token = JSON.parse(localStorage.getItem("jwt"))?JSON.parse(localStorage.getItem("jwt")).token:false
  let user = token && verify(token,process.env.REACT_APP_JWT_SECRET)
 return token
    ? user.role === 1
    : false;
}
export const loginReq = async ({ email, password }) => {
  const data = { email, password };
  try {
    let res = await axios.post(`/api/signin`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const googleLoginReq = async (tokenId) => {
  console.log(tokenId)
  try {
    let res = await axios.post(`/api/googleLogin`, {tokenId:tokenId});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


export const facebookLoginReq = async (data) => {
  try {
    let res = await axios.post(`/api/facebookLogin`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const signupReq = async ({ name, email, password, cPassword }) => {
  const data = { name, email, password, cPassword };
  try {
    let res = await axios.post(`/api/signup`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const forgotPasswordReq = async (data) => {
  try {
    let res = await axios.post(`/api/forgotPasswordReq`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const newPassword = async (data) => {
  try {
    let res = await axios.post(`/api/newPassword`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

