import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import CustomizeUpload from "./Customize";
const CutomizeComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <CustomizeUpload />
    </div>
  );
};

const Customize = (props) => {
  return (
    <Fragment>
        <AdminLayout children={<CutomizeComponent />} />
    </Fragment>
  );
};

export default Customize;
