import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import ListSuggestions from "./ListSuggestion"; // Adjust the import path as necessary

const Suggestions = () => {
  return (
    <Fragment>
      <AdminLayout children={<ListSuggestions />} />
    </Fragment>
  );
};

export default Suggestions;
