import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import UpdateContent from "./UpdateContents";
const UpdateContentPage = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <UpdateContent />
    </div>
  );
};

const Contents = (props) => {
  return (
    <Fragment>
        <AdminLayout children={<UpdateContentPage />} />
    </Fragment>
  );
};

export default Contents;
