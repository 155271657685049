import React, { Fragment, useReducer, useEffect, useState } from "react";

import Routes from "./components";
import { LayoutContext, layoutState, layoutReducer } from "./components/shop";
import {Helmet} from "react-helmet";
function App() {
  const [data, dispatch] = useReducer(layoutReducer, layoutState);
  const [login, setLogin] = useState(false);
  useEffect(() => {
    data.logedin ? setLogin(true) : setLogin(false);
  }, [data.logedin]);
  return (
    <Fragment>
      {login ? (
        <LayoutContext.Provider value={{ data, dispatch }}>
          <Routes />
        </LayoutContext.Provider>
      ) : (
        <LayoutContext.Provider value={{ data, dispatch }}>
          <Routes />
        </LayoutContext.Provider>
      )}
    </Fragment>
  );
}

export default App;
