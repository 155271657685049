import React, { Fragment, useContext, useState, useEffect } from "react";
import { CategoryContext } from "./index";
import {
  editCategory,
  getAllCategory,
  fetchSubCatagoriesAdmin,
  activeSubCatagories,
  addSubCatagories,
} from "./FetchApi";
import { Button } from "react-bootstrap";
const EditCategoryModal = (props) => {
  const { data, dispatch } = useContext(CategoryContext);
  const [addSubCat, setAddSubCat] = useState({
    name: "",
  });
  const [des, setDes] = useState("");
  const [status, setStatus] = useState("");
  const [cId, setCid] = useState("");
  const [cImage, setImage] = useState("");
  const [subCats, setSubCats] = useState([]);
  useEffect(() => {
    if (data.editCategoryModal.modal) {
      setDes(data.editCategoryModal.des);
      setStatus(data.editCategoryModal.status);
      setCid(data.editCategoryModal.cId);
      fetchSubCats(data.editCategoryModal.cId);
    }
  }, [data.editCategoryModal.modal]);
  const addSubCatagory = async () => {
    let response = await addSubCatagories({
      name: addSubCat.name,
      catagory: data.editCategoryModal.cId,
    });
    if (response.success) {
      fetchSubCats(data.editCategoryModal.cId);
      setAddSubCat({ name: "" });
    }
  };
  const activeSubCat = async (id, active) => {
    const response = await activeSubCatagories({ id, active });
    if (response.success) {
      fetchSubCats(data.editCategoryModal.cId);
    }
  };

  const fetchSubCats = async (id) => {
    const response = await fetchSubCatagoriesAdmin(id);
    if (response.length > 0) {
      setSubCats(response);
    }
  };
  const fetchData = async () => {
    let responseData = await getAllCategory();
    if (responseData.Categories) {
      dispatch({
        type: "fetchCategoryAndChangeState",
        payload: responseData.Categories,
      });
    }
  };

  const submitForm = async () => {
    dispatch({ type: "loading", payload: true });
    let edit = await editCategory(cId, des, status, cImage);
    console.log("edit>>>>", edit);
    if (edit.error) {
      console.log(edit.error);
      dispatch({ type: "loading", payload: false });
    } else if (edit.success) {
      console.log(edit.success);
      dispatch({ type: "editCategoryModalClose" });
      setTimeout(() => {
        fetchData();
        dispatch({ type: "loading", payload: false });
      }, 1000);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "editCategoryModalClose" })}
        className={`${
          data.editCategoryModal.modal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.editCategoryModal.modal ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4  overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Category
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) => dispatch({ type: "editCategoryModalClose" })}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="description">Category Description</label>
            <textarea
              value={des}
              onChange={(e) => setDes(e.target.value)}
              className="px-4 py-2 border focus:outline-none"
              name="description"
              id="description"
              cols={5}
              rows={5}
            />
          </div>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="status">Category Status</label>
            <select
              value={status}
              name="status"
              onChange={(e) => setStatus(e.target.value)}
              className="px-4 py-2 border focus:outline-none"
              id="status"
            >
              <option name="status" value="Active">
                Active
              </option>
              <option name="status" value="Disabled">
                Disabled
              </option>
            </select>
          </div>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="file">Category Image</label>
            <input
              id="file"
              type="file"
              name="file"
              onChange={(e) => setImage(e.target.files[0])}
            ></input>
          </div>
          <div className="flex flex-wrap w-full  ">
            {subCats.map((s) => (
              <div key={s._id} className="border border-indigo-600 m-2">
                {s.name}
                <Button
                  onClick={(e) => {
                    activeSubCat(s._id, !s.active);
                  }}
                  variant={s.active ? "danger" : "success"}
                  size="sm"
                >
                  {s.active ? "Deactive" : "Active"}
                </Button>
              </div>
            ))}
            <div className="border border-indigo-600 m-2">
              <input
                className="outline-none w-20 px-2 focus:outline-none"
                value={addSubCat.name}
                onChange={(e) => setAddSubCat({ name: e.target.value })}
                type="text"
              ></input>
              <Button
                onClick={(e) => {
                  addSubCatagory();
                }}
                variant="success"
                size="sm"
              >
                Add
              </Button>
            </div>
          </div>
          <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6">
            <button
              style={{ background: "#303031" }}
              onClick={(e) => submitForm()}
              className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
            >
              Update category
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditCategoryModal;
