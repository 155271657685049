import React, { Fragment, useContext, useState, useEffect } from "react";

function AddSideWeight({ WeightAndSize, Size }) {
  const [sizeWeight, setsizeWeight] = useState([]);

  const [error, setError] = useState("");
  const [inputSize, setInputSize] = useState(0);
  const [inputWeight, setInputWeight] = useState(0);

  // if (Size.length > 0) {
  //   setsizeWeight(Size);
  //   console.log(Size);
  // }
  // useEffect(() => {
  //   WeightAndSize([...sizeWeight])
  // }, [sizeWeight]);
  const addMore = () => {
    if (inputSize != "" && inputWeight != "") {
      WeightAndSize([...Size, { size: inputSize, weight: inputWeight }]);
      setInputSize(0);
      setInputWeight(0);
    } else {
      setError("Please Enter Valid Input");
      setInputSize(0);
      setInputWeight(0);
    }
  };
  const remove = (index) => {
    const newSizeWeight = [...Size];
    newSizeWeight.splice(index, 1);
    WeightAndSize(newSizeWeight);
  };
  return (
    <>
      <table className="text-center" border="5" cellspacing="5">
        {error ? <span className="text-red-400">{error}</span> : ""}
        <thead>
          <tr>
            <th>Size</th>
            <th>Weight</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Size && Size.map((s, i) => (
            <tr key={i}>
              <td>{s.size}</td>
              <td>{s.weight}</td>
              <td>
                <i onClick={() => remove(i)} className="fa fa-trash"></i>
              </td>
            </tr>
          ))}
          <tr className="">
            <td>
              <input
                type="number"
                value={inputSize}
                onChange={(e) => setInputSize(e.target.value)}
                onFocus={(e) => setError("")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ></input>
            </td>
            <td>
              <input
                type="number"
                value={inputWeight}
                onFocus={(e) => setError("")}
                onChange={(e) => setInputWeight(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ></input>
            </td>
            <td>
              <i
                class="fas fa-plus ml-3"
                aria-hidden="true"
                onClick={addMore}
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
export default AddSideWeight;
