import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  uploadSlide,
  getAllCustomImages,
  postDeleteImage,
  uploadMobSlide,
} from "./fetchApi";
import {
  Table,
  Container,
  Row,
  Col,
  Pagination,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";

const LandingPageEdit = () => {
  const [data, setData] = useState({
    loading: true,
    saveLoading: false,
    success: false,
    error: 0,
    title: "",
    subtitle: "",
    link: "",
    image: "",
    mob: "",
  });
  const [images, setImages] = useState([]);
  const [slider, setSlider] = useState("");
  const mobSlide = async (image) => {
    setDisabled(true)
    let resp = await uploadMobSlide(image);
    console.log(resp);
    if (resp && resp.url) {
      setDisabled(false)
      setData({ ...data, mob: resp.url });
    }
  };
  const [disabled,setDisabled] = useState(false)
  const getAllImages = async () => {
    setData({ ...data, loading: true });
    let Slidedata = await getAllCustomImages(slider);
    setImages([...Slidedata.Images]);
    console.log(Slidedata.Images);
    setData({ ...data, loading: false });
  };
  useEffect(() => {
if(slider){  
  getAllImages();
}
    
  }, [data.success, slider]);

  const SaveHandler = async (e) => {
    e.preventDefault();
    if(!slider){  
      alert('please select a section')
      return false
    }
    setData({ ...data, success: false, loading: true, saveLoading: true });
    let response = await uploadSlide({...data,slider});

    if (response.success) {
      setData({
        ...data,
        success: true,
        loading: false,
        saveLoading: false,
        error: 2,
        title: "",
        subtitle: "",
        link: "",
        image: "",
        mob: "",
      });
    } else {
      setData({
        ...data,
        success: true,
        loading: false,
        saveLoading: false,
        error: 1,
        title: "",
        subtitle: "",
        link: "",
        image: "",
        mob: "",
      });
    }
  };
  const deleteImageReq = async (id) => {
    setData({ ...data, success: false, loading: true });
    let response = await postDeleteImage(id);
    if (response.success) {
      setData({ ...data, success: true, loading: false });
    }
  };
  return (
    <>
    <div className="flex justify-end">
      <select onChange={(e)=>setSlider(e.target.value)} className="p-1" id="selectSlider" name="selectSlider">
        <option value="">--Select Slider--</option>
        <option value="1">Slider-1</option>
         <option value="2">Slider-2</option>
      </select>
    </div>
      <div class="grid grid-cols-1 md:grid-cols-3">
        <div
          className="shadow p-5 bg-gray-200 rounded"
          style={{ height: "max-content" }}
        >
          <form className="flex flex-col" onSubmit={SaveHandler}>
            <label htmlFor="title">Title</label>
            <input
              id="title"
              name="title"
              type="text"
              value={data.title}
              onChange={(e) =>
                setData({ ...data, title: e.target.value, error: 0 })
              }
              placeholder="Enter Title"
              class=" focus:outline-none focus:ring focus:border-blue-300 w-full "
            />
            <br className="mt-1.5" />
            <label htmlFor="subtitle">Sub-Title</label>

            <input
              id="subtitle"
              type="text"
              value={data.subtitle}
              onChange={(e) =>
                setData({ ...data, subtitle: e.target.value, error: 0 })
              }
              placeholder="Enter Sub-Title"
              class=" focus:outline-none focus:ring focus:border-blue-300 w-full"
            />
            <br className="mt-1.5" />
            <label htmlFor="link">Link</label>

            <input
              type="text"
              id="link"
              name="link"
              value={data.link}
              onChange={(e) =>
                setData({ ...data, link: e.target.value, error: 0 })
              }
              placeholder="Enter Link"
              class=" focus:outline-none focus:ring focus:border-blue-300 w-full"
            />
            <br className="mt-1.5" />
            <label htmlFor="sliderFile">Upload Image</label>
            <input
              id="sliderFile"
              onChange={(e) =>
                setData({ ...data, image: e.target.files[0], error: 0 })
              }
              type="file"
            />
            <br className="mt-1.5" />
            <label htmlFor="MobSliderFile">Upload Mobile Image</label>
            <input
              id="MobSliderFile"
              onChange={(e) => mobSlide(e.target.files[0])}
              type="file"
            />
            <br className="mt-1.5" />
            {data.error == 1 && (
              <Alert variant="warning">All fields are required</Alert>
            )}
            {data.error == 2 && (
              <Alert variant="success">Saved Successfully</Alert>
            )}

            <Button
              type="submit"
              variant={data.saveLoading ? "success" : "primary"}
              disabled={disabled}
            >
              {data.saveLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {data.saveLoading ? "Saving..." : "Save"}
            </Button>
          </form>
        </div>
        <div class="col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 p-2">
            {images.length > 0 &&
              images.map((item) => (
                <div key={item._id} className="relative col-span-1 m-2 border">
                  <img
                    className="w-full md:h-32 object-center object-cover"
                    src={item.image}
                    alt="sliderImages"
                  />
                  <span
                    onClick={(e) => deleteImageReq(item._id)}
                    style={{ background: "#303031" }}
                    className="absolute top-0 right-0 m-1 text-white cursor-pointer rounded-full p-1"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default LandingPageEdit;
