import React, { Fragment, useContext, useState, useEffect } from "react";
import { ProductContext } from "./index";
import { toast } from "react-toastify";
import { createProduct, getAllProduct } from "./FetchApi";
import { getAllCategory, fetchSubCatagories } from "../categories/FetchApi";
import AddSideWeight from "./AddSideWeight";
import ExtraStone from "./ExtraStone";
import { 
  TextField, Select, MenuItem, FormControlLabel, Radio, RadioGroup, 
  Button, Typography, Checkbox, FormControl, InputLabel
} from '@material-ui/core';

const AddProductDetail = ({ categories }) => {
  const { data, dispatch } = useContext(ProductContext);

  const alert = (msg, type) => (
    <div className={`bg-${type}-100 border border-${type}-400 text-${type}-700 px-4 py-3 rounded relative mb-4`} role="alert">
      <span className="block sm:inline">{msg}</span>
    </div>
  );

  const [sizeRequired, setSizeRequired] = useState(true);
  const [priceConstant, setPriceConstant] = useState(false);
  const [isSaving, setSaving] = useState(0);
  const [fData, setFdata] = useState({
    pName: "",
    modelNumber: "",
    pDescription: "",
    pStatus: true,
    pCategory: "",
    WeightAndSize: [],
    extraStones:[],
    extraStoneAdded: false,
    pSubCatagory: "",
    custumizable: false,
    sex: "unisex",
    goldWeight: 0,
    pPrice: 0,
    sName: "",
    sPrice: 0,
    DCarrett: 0,
    DOffer: 0,
    LPrice: 0,
    LOffer: 0,
    pDiamension: "",
    success: false,
    error: false,
  });
  const [subCatagories, setSubCatagories] = useState([]);

  const getSubCatagories = async (cat) => {
    let subCat = await fetchSubCatagories(cat);
    if (subCat) {
      setSubCatagories(subCat);
    }
  };

  const sizereq = (value) => {
    categories.filter((cat) => {
      if (cat._id === value) {
        setSizeRequired(cat.sizeRequired);
        setPriceConstant(cat.priceConstant);
      }
    });
  };

  const WeightAndSize = (sizeArr) => {
    setFdata({
      ...fData,
      error: false,
      success: false,
      WeightAndSize: sizeArr,
    });
  };

  const extraStones = (stoneArr) => {
    setFdata({
      ...fData,
      error: false,
      success: false,
      extraStones: stoneArr,
    });
  };

  const fetchData = async () => {
    let responseData = await getAllProduct();
    setTimeout(() => {
      if (responseData && responseData.Products) {
        dispatch({
          type: "fetchProductsAndChangeState",
          payload: responseData.Products,
        });
      }
    }, 1000);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setSaving(1);
    try {
      let responseData = await createProduct(fData);
      if (responseData.success) {
        fetchData();
        setSaving(2);
        setFdata({
          ...fData,
          pName: "",
          modelNumber: "",
          pDescription: "",
          pStatus: true,
          pCategory: "",
          WeightAndSize: [],
          extraStones:[],
          extraStoneAdded: false,
          custumizable: false,
          sex: "unisex",
          pDiamension: "",
          pPrice: 0,
          sName: "",
          pSubCategory: "",
          goldWeight: 0,
          sPrice: 0,
          DCarrett: 0,
          DOffer: 0,
          LPrice: "",
          LOffer: "",
          success: responseData.success,
          error: false,
        });
        toast.success(responseData.success, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setSaving(0);
        }, 2000);
      } else if (responseData.error) {
        toast.error(responseData.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSaving(3);
        setFdata({ ...fData, success: false, error: responseData.error });
        setTimeout(() => {
          setSaving(0);
          return setFdata({ ...fData, error: false, success: false });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div
        onClick={(e) => dispatch({ type: "addProductModal", payload: false })}
        className={`${
          data.addProductModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />

      <div
        className={`${
          data.addProductModal ? "" : "hidden"
        } fixed inset-0 flex items-center justify-center z-40 overflow-auto`}
      >
        <div className="bg-white w-11/12 md:w-3/5 lg:w-1/2 rounded-lg shadow-xl p-6 max-h-screen overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <Typography variant="h5" className="font-semibold">Add Product</Typography>
            <Button
              onClick={(e) => dispatch({ type: "addProductModal", payload: false })}
              className="text-gray-600 hover:text-gray-800"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Button>
          </div>

          {fData.error && alert(fData.error, "red")}
          {fData.success && alert(fData.success, "green")}

          <form className="space-y-4" onSubmit={(e) => submitForm(e)}>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Product Name"
                variant="outlined"
                fullWidth
                value={fData.pName}
                onChange={(e) => setFdata({ ...fData, pName: e.target.value })}
              />
              <TextField
                label="Model Number"
                variant="outlined"
                fullWidth
                value={fData.modelNumber}
                onChange={(e) => setFdata({ ...fData, modelNumber: e.target.value })}
              />
            </div>

            <TextField
              label="Product Description"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={fData.pDescription}
              onChange={(e) => setFdata({ ...fData, pDescription: e.target.value })}
            />

            <div className="flex items-center space-x-4">
              <Typography variant="body1">Sex:</Typography>
              <RadioGroup
                row
                value={fData.sex}
                onChange={(e) => setFdata({ ...fData, sex: e.target.value })}
              >
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="unisex" control={<Radio />} label="Unisex" />
              </RadioGroup>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Product Status</InputLabel>
                <Select
                  value={fData.pStatus}
                  onChange={(e) => setFdata({ ...fData, pStatus: e.target.value })}
                  label="Product Status"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Disabled</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel>Product Category</InputLabel>
                <Select
                  value={fData.pCategory}
                  onChange={(e) => {
                    setFdata({ ...fData, pCategory: e.target.value });
                    sizereq(e.target.value);
                    getSubCatagories(e.target.value);
                  }}
                  label="Product Category"
                >
                  <MenuItem disabled value="">Select a category</MenuItem>
                  {categories.map((elem) => (
                    <MenuItem value={elem._id} key={elem._id}>{elem.cName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <FormControl variant="outlined" fullWidth>
              <InputLabel>Product Sub Category</InputLabel>
              <Select
                value={fData.pSubCategory}
                onChange={(e) => setFdata({ ...fData, pSubCategory: e.target.value })}
                label="Product Sub Category"
              >
                <MenuItem value="">Select a sub category</MenuItem>
                {subCatagories.map((elem) => (
                  <MenuItem value={elem._id} key={elem._id}>{elem.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="grid grid-cols-2 gap-4">
              {sizeRequired ? (
                <AddSideWeight
                  WeightAndSize={WeightAndSize}
                  Size={fData.WeightAndSize}
                />
              ) : (
                <>
                  <TextField
                    label="Gold Weight"
                    variant="outlined"
                    type="number"
                    fullWidth
                    value={fData.goldWeight}
                    onChange={(e) => setFdata({ ...fData, goldWeight: e.target.value })}
                  />
                  {priceConstant && (
                    <TextField
                      label="Price"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={fData.pPrice}
                      onChange={(e) => setFdata({ ...fData, pPrice: e.target.value })}
                    />
                  )}
                </>
              )}
              <TextField
                label="Dimension"
                variant="outlined"
                fullWidth
                value={fData.pDiamension}
                onChange={(e) => setFdata({ ...fData, pDiamension: e.target.value })}
              />
            </div>

            {fData.pCategory === "6150c537ea2f237c3e0fa244" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fData.custumizable}
                    onChange={(e) => setFdata({ ...fData, custumizable: e.target.checked })}
                    color="primary"
                  />
                }
                label="Customizable"
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={fData.extraStoneAdded}
                  onChange={(e) => setFdata({ ...fData, extraStoneAdded: e.target.checked })}
                  color="primary"
                />
              }
              label="Extra Stone Added"
            />

            {fData.extraStoneAdded && (
              <ExtraStone
                extraStones={extraStones}
                Stones={fData.extraStones}
                custumizable={fData.custumizable}
              />
            )}

            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Diamond Carat"
                variant="outlined"
                type="number"
                fullWidth
                value={fData.DCarrett}
                onChange={(e) => setFdata({ ...fData, DCarrett: e.target.value })}
              />
              <TextField
                label="Offer on Diamond (%)"
                variant="outlined"
                type="number"
                fullWidth
                value={fData.DOffer}
                onChange={(e) => setFdata({ ...fData, DOffer: e.target.value })}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Labour Price"
                variant="outlined"
                type="number"
                fullWidth
                value={fData.LPrice}
                onChange={(e) => setFdata({ ...fData, LPrice: e.target.value })}
              />
              <TextField
                label="Offer on Labour Price (%)"
                variant="outlined"
                type="number"
                fullWidth
                value={fData.LOffer}
                onChange={(e) => setFdata({ ...fData, LOffer: e.target.value })}
              />
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              className="py-3 bg-blue-600 hover:bg-blue-700"
              disabled={isSaving !== 0}
            >
              {isSaving === 0 && "Create Product"}
              {isSaving === 1 && "Creating Product..."}
              {isSaving === 2 && "Product Created"}
              {isSaving === 3 && "Failed to Create"}
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const AddProductModal = (props) => {
  useEffect(() => {
    fetchCategoryData();
  }, []);

  const [allCat, setAllCat] = useState([]);

  const fetchCategoryData = async () => {
    let responseData = await getAllCategory();
    if (responseData.Categories) {
      setAllCat(responseData.Categories);
    }
  };

  return (
    <Fragment>
      <AddProductDetail categories={allCat} />
    </Fragment>
  );
};

export default AddProductModal;