import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const uploadSlide = async (data)=>{
  let formData = new FormData();
  formData.append("image", data.image);
  formData.append("title", data.title);
  formData.append("subtitle", data.subtitle);
  formData.append("link", data.link);
  formData.append("mob", data.mob);
  formData.append("slider", data.slider);

  try {
    let res = await axios.post(`/api/customize/upload-slide`,formData,Headers())
    return res.data
  } catch (error) {
    console.log(error)
  }
}
export const uploadMobSlide = async (image)=>{
  let formData = new FormData();
  formData.append("mob", image);
  try {
    let res = await axios.post(`/api/customize/upload-mob-slide`,formData,Headers())
    return res.data
  } catch (error) {
    console.log(error)
  }
}
export const getAllCustomImages = async(slider) =>{
    try{
        let response = await axios.get(`/api/customize/get-slide-image-admin?slider=${slider}`)
        console.log(response)
        return response.data
    }catch(e){
        console.log(e)
        }
}
export const postDeleteImage = async (id) => {
  try {
    let res = await axios.post(`/api/customize/delete-slide-image`, {
      id,
    },Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


export const uploadSec1Images = async (data)=>{
  let formData = new FormData();
  formData.append("image", data.image);
  formData.append("title", data.title);
  formData.append("subtitle", data.subtitle);
  formData.append("link", data.link);
  formData.append("section", data.section);

  try {
    let res = await axios.post(`/api/customize/upload-sec1-slide`,formData,Headers())
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const getAllSec1Images = async(section) =>{
    try{
        let response = await axios.get(`/api/customize/get-sec1-admin-image?section=${section}`)
        console.log(response)
        return response.data
    }catch(e){
        console.log(e)
        }
}
export const postSec1DeleteImage = async (id) => {
  try {
    let res = await axios.post(`/api/customize/delete-sec1-image`, {
      id,
    },Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrder = async (data) => {
  try {
    let res = await axios.post(`/api/customize/update_order`, {
      data,
    },Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};