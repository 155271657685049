import React, { Fragment } from "react";
import Layout from "../layout";
import MobileCatagoryPage from "./MobileCatagory";

const MobileCatagory = () => {
  return (
    <Fragment>
      <Layout children={<MobileCatagoryPage />} />
    </Fragment>
  );
};

export default MobileCatagory;