import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import MaterialMenu from "./MaterialMenu";
import AllMaterials from "./AllMaterials";
import { materialState, materialReducer } from "./MaterialContext";

/* This context manage all of the caregories component's data */
export const MaterialContext = createContext();

const MaterialComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <MaterialMenu />
      <AllMaterials />
    </div>
  );
};

const Materials = (props) => {
  const [data, dispatch] = useReducer(materialReducer, materialState);
  return (
    <Fragment>
      <MaterialContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<MaterialComponent />} />
      </MaterialContext.Provider>
    </Fragment>
  );
};

export default Materials;