import React, { Fragment, useContext, useState, useEffect } from "react";
import { MaterialContext } from "./index";
import { editMaterial, getAllMaterial } from "./FetchApi";

const EditMaterialModal = (props) => {
  const { data, dispatch } = useContext(MaterialContext);
console.log(data)
  const [mId, setMid] = useState("");
  const [mPrice, setMprice] = useState("");
  const [isDeleted, setIsdeleted] = useState(false)
  useEffect(() => {
    console.log("hhhhhhhhh",data.editMaterialModal)
    setMid(data.editMaterialModal.mId);
    setMprice(data.editMaterialModal.mPrice)
    setIsdeleted(data.editMaterialModal.is_deleted)
  }, [data.editMaterialModal.modal]);

  const fetchData = async () => {
    let responseData = await getAllMaterial();
    if (responseData.Categories) {
      dispatch({
        type: "fetchMaterialAndChangeState",
        payload: responseData.Materials,
      });
    }
  };

  const submitForm = async () => {
    dispatch({ type: "loading", payload: true });
    let edit = await editMaterial(mId, mPrice, isDeleted);
    if (edit.error) {
      console.log(edit.error);
      dispatch({ type: "loading", payload: false });
    } else if (edit.success) {
      console.log(edit.success);
      dispatch({ type: "editMaterialModalClose" });
      setTimeout(() => {
        fetchData();
        dispatch({ type: "loading", payload: false });
      }, 1000);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "editMaterialModalClose" })}
        className={`${
          data.editMaterialModal.modal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.editMaterialModal.modal ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4  overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Material
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) => dispatch({ type: "editMaterialModalClose" })}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          <div className="flex flex-col space-y-1 w-full">
          <label htmlFor="price">Product Price *</label>
                <input
                  value={mPrice}
                  onChange={(e) => setMprice (e.target.value)}
                  className="px-4 py-2 border focus:outline-none"
                  name="price"
                  id="price"                  
                />
          </div>   
          <div className="flex flex-col space-y-1 w-full">
          <label htmlFor="price">Status</label>
          <select
           className="px-4 py-2 border focus:outline-none"
           value={isDeleted?"true":"false"}
           onChange={(e) => setIsdeleted (e.target.value=="true")}
          >
            <option value="false">Active</option>
            <option value="true">In active</option>
          </select>
             
          </div>          
          <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6">
            <button
              style={{ background: "#303031" }}
              onClick={(e) => submitForm()}
              className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
            >
              Update material
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditMaterialModal;
