import React, { Fragment, createContext, useReducer } from "react";
import Layout from "../layout";
import Slider from "./Slider";
import ProductCategory from "./ProductCategory";
import { homeState, homeReducer } from "./HomeContext";
import SingleProduct from "./SingleProductWatches";

export const HomeContext = createContext();

const HomeComponent = () => {
  return (
    <Fragment>
      {/* Category, Search & Filter Section */}
      {/* Product Section */}
      <SingleProduct />
    </Fragment>
  );
};

const Home = (props) => {
  const [data, dispatch] = useReducer(homeReducer, homeState);
  return (
    <Fragment>
      <HomeContext.Provider value={{ data, dispatch }}>
        <HomeComponent />
      </HomeContext.Provider>
    </Fragment>
  );
};

export default Home;
