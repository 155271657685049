import React from "react";
import { Route, Navigate } from "react-router-dom";
import { isAuthenticate, isAdmin } from "./fetchApi";

const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  // <Route
  //   {...rest}
  //   render={(props) =>
  //     isAdmin() && isAuthenticate() ? (
  //       <Component {...props} />
  //     ) : (
  //       <Navigate
  //         to={{
  //           pathname: "/user/profile",
  //           state: { from: props.location },
  //         }}
  //       />
  //     )
  //   }
  // />
  if (isAdmin() && isAuthenticate()) {
    return <Component {...rest} />
  }
  return <Navigate
    to={{
      pathname: "/user/profile"
    }}
  />
  };

export default AdminProtectedRoute;
