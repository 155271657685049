import React, { Fragment } from "react";
import Layout from "../shop/layout";
import Home from "./Home";

const LandingPage = () => {
  return (
    <Fragment>
      <Layout children={<Home />} />
    </Fragment>
  );
};

export default LandingPage;