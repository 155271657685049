import React, { Fragment, useState, useContext, useEffect } from "react";
import moment from "moment";
import { Spinner, Button } from "react-bootstrap";
// import { OrderContext } from "./index";
// import { fetchData, editOrderReq, deleteOrderReq } from "./Actions";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { getAllOrder } from "./FetchApi";
import AddressModal from "./AddressModal";
import VOP from "./VOP";
import StatusChange from "./StatusChangeModal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
// const OrderTable = ()=>{
// const [columnDefs, setColumnDefs] = useState( [
//   {headerName: "Make", field: "make"},
//   {headerName: "Model", field: "model"},
//   {headerName: "Price", field: "price",editable:true}

// ])

// const [rowData, setRowData] = useState([
//         {make: "Toyota", model: "Celica", price: 35000},
// 				{make: "Ford", model: "Mondeo", price: 32000},
// 				{make: "Porsche", model: "Boxter", price: 72000}
// ])
//   return (
//     <>

// 				<AgGridReact
//         style={{height:"80vh"}}
//         className="ag-theme-alpine"
// 					columnDefs={columnDefs}
// 					rowData={rowData}>
// 				</AgGridReact>
//     </>
//   )
// }

const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState("OR");
  const [count, setCount] = useState(0);
  const [openStatusModal, setStatusModal] = useState(false);
  const fetchOrders = async (skip, search) => {
    setLoading(true);
    const data = await getAllOrder(skip, search, status);

    console.log(data);
    setOrders(data.Orders);
    // setTotalPages(data.totalPages);
    setCount(Math.ceil(data.count / 10));
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders(page * 10 - 10, search);
  }, [page, search, status]);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openVOP, setOpenVOP] = useState(false);
  const [selectedVOP, setSelectedVOP] = useState([]);

  const [orderID, setOrderID] = useState("");
  const [address, setAddress] = useState({});

  const handleAddressModal = (value, address) => {
    setAddress({ address });
    setOpenAddressModal(value);
  };
  const handleStatusModal = (value, orderID) => {
    setOrderID(orderID);
    setStatusModal(value);
  };

  return (
    <>
      <div className="col-span-1 overflow-auto bg-white shadow-lg p-4 text-sm">
        <div class="flex  rounded justify-between">
          <div className="flex items-center">
            {/* It's open the add product modal */}
            {/* <span
              style={{ background: "#303031" }}
              // onClick={(e) =>
              //   dispatch({ type: "addProductModal", payload: true })
              // }
              className="rounded-full cursor-pointer p-2 bg-gray-800 flex items-center text-gray-100 text-sm font-semibold uppercase"
            >
              <svg
                className="w-6 h-6 text-gray-100 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clipRule="evenodd"
                />
              </svg>
              Add Product
            </span> */}
          </div>
          <div className="flex justify-between w-full">
            <div style={{ minWidth: "120px" }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="OR">Ordered</MenuItem>
                  <MenuItem value="SH">Shipped</MenuItem>
                  <MenuItem value="DL">Delivered</MenuItem>
                  <MenuItem value="CN">Cancelled</MenuItem>
                  <MenuItem value="RN">Returned</MenuItem>
                  {/* New Statuses */}
                  <MenuItem value="Cast Tree">Cast Tree</MenuItem>
                  <MenuItem value="Mould">Mould</MenuItem>
                  <MenuItem value="Stone Setting">Stone Setting</MenuItem>
                  <MenuItem value="Polishing">Polishing</MenuItem>
                  <MenuItem value="Inspection">Inspection</MenuItem>
                  <MenuItem value="Final Product">Final Product</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex" style={{ minWidth: "320px" }}>
              <input
                type="text"
                class="px-4 py-2 w-80 bg-transparent outline-black focus:outline-none "
                // value={search}
                placeholder="Search..."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // Call your search function here
                    // searchTable();
                    setSearch(e.target.value);
                  }
                }}
                onKeyDownCapture={(e) => {
                  if (e.key === "Enter") {
                    // Call your search function here
                    // searchTable();
                    setSearch(e.target.value);
                  }
                }}
              />
              <button
                // onClick={searchTable}
                class="flex items-center  justify-center px-4 border-l hover:bg-gray-400 hover:text-black"
              >
                <svg
                  class="w-6 h-6 text-black"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <table className="table-auto border w-full my-2">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Order ID</th>
              <th className="px-4 py-2 border">User</th>
              <th className="px-4 py-2 border">Ordered on</th>
              <th className="px-4 py-2 border">Products</th>
              <th className="px-4 py-2 border">Status</th>
              <th className="px-4 py-2 border">Amount</th>
              <th className="px-4 py-2 border">Payment Mode</th>
              <th className="px-4 py-2 border">Transaction ID</th>
              <th className="px-4 py-2 border">Razor pay Order ID</th>
              <th className="px-4 py-2 border">Transaction Status</th>
              <th className="px-4 py-2 border">Address</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody style={{ overflowY: "scroll" }}>
            {orders.map((data, i) => (
              <OrderTable
                order={data}
                key={i}
                setOpenAddressModal={handleAddressModal}
                handleStatusModal={handleStatusModal}
                setOpenVOP={setOpenVOP}
                setSelectedVOP={setSelectedVOP}
              />
            ))}
            {/* {products && products.length > 0 ? (
              products.map((item, key) => {
                return (
                  <ProductTable
                    product={item}
                    editProduct={(pId, product, type) =>
                      editProduct(pId, product, type)
                    }
                    imageModal={imageModal}
                    deleteProduct={(pId) => deleteProductReq(pId)}
                    key={key}
                  />
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="10"
                  className="text-xl text-center font-semibold py-8"
                >
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
        <div className="text-sm text-center text-gray-600 mt-2"></div>
        <Stack className="text-center" spacing={2}>
          <Pagination
            page={page}
            onChange={(event, val) => {
              setPage(val);
            }}
            count={count}
            color="primary"
          />
        </Stack>
      </div>
      <StatusChange
        orderID={orderID}
        open={openStatusModal}
        setOpen={setStatusModal}
        fetchOrders={fetchOrders}
        page={page}
        search={search}
      ></StatusChange>
      <AddressModal
        address={address}
        open={openAddressModal}
        setOpen={setOpenAddressModal}
      ></AddressModal>
      <VOP products={selectedVOP} open={openVOP} setOpen={setOpenVOP}></VOP>
    </>
  );
};

// function OrderTable({
//   order,
//   setOpenAddressModal,
//   handleStatusModal,
//   setOpenVOP,
//   setSelectedVOP,
// }) {
//   return (
//     <>
//       <tr>
//         <td className="p-2 text-left">{order.order_id}</td>
//         <td className="p-2 text-left">
//           <a href="#">{order.user.name}</a>
//         </td>
//         <td className="p-2 text-left">
//           {moment(order.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
//         </td>
//         <td className="p-2 text-center">
//           <a
//             href="#"
//             onClick={() => {
//               setSelectedVOP(order.products);
//               setOpenVOP(true);
//             }}
//           >
//             {order.products.length}
//           </a>
//         </td>
//         <td className="p-2 text-center">{order.status}</td>
//         <td className="p-2 text-center">{order.total_amount}</td>
//         <td className="p-2 text-center">
//           {order.payment_mode == 0 && order.payment == "unpaid" ? (
//             <span className="bg-green-200 rounded-full text-center text-xs px-2 font-semibold">
//               COD
//             </span>
//           ) : (
//             <span className="bg-red-200 rounded-full text-center text-xs px-2 font-semibold">
//               Online
//             </span>
//           )}
//         </td>
//         <td className="p-2 text-left">{order.tranaction}</td>
//         <td className="p-2 text-left">{order.razorpay_order_id}</td>
//         <td className="p-2 text-center">{order.payment}</td>
//         <td className="p-2 text-center">
//           <a
//             href="#"
//             className="hover:scale-125"
//             onClick={() => setOpenAddressModal(true, order.address)}
//           >
//             <i className="fa fa-eye "></i>
//           </a>
//         </td>
//         <td className="p-2 text-left">
//           {!order.is_shipped && (
//             <a
//               href="#"
//               className="hover:scale-125"
//               onClick={(e) => handleStatusModal(true, order._id)}
//             >
//               <i class="fas fa-shipping-fast "></i>
//             </a>
//           )}
//         </td>
//       </tr>
//     </>
//   );
// }

const getStatusName = (status) => {
  switch (status) {
    case "OR":
      return "Ordered";
    case "SH":
      return "Shipped";
    case "DL":
      return "Delivered";
    case "CN":
      return "Cancelled";
    case "RN":
      return "Returned";
    case "Casting Tree":
      return "Casting Tree";
    case "Mould":
      return "Mould";
    case "Stone Setting":
      return "Stone Setting";
    case "Polishing":
      return "Polishing";
    case "Inspection":
      return "Inspection";
    case "Final Product":
      return "Final Product";
    default:
      return status;
  }
};

function OrderTable({
  order,
  setOpenAddressModal,
  handleStatusModal,
  setOpenVOP,
  setSelectedVOP,
}) {
  return (
    <>
      <tr>
        <td className="p-2 text-left">{order.order_id}</td>
        <td className="p-2 text-left">
          <a href="#">{order.user.name}</a>
        </td>
        <td className="p-2 text-left">
          {moment(order.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        </td>
        <td className="p-2 text-center">
          <a
            href="#"
            onClick={() => {
              setSelectedVOP(order.products);
              setOpenVOP(true);
            }}
          >
            {order.products.length}
          </a>
        </td>
        <td className="p-2 text-center">
          <a href="#" onClick={(e) => handleStatusModal(true, order._id)}>
            {order?.statusType}
            {/* {getStatusName(order.status)} */}
          </a>
        </td>
        <td className="p-2 text-center">{order.total_amount}</td>
        <td className="p-2 text-center">
          {order.payment_mode === 0 && order.payment === "unpaid" ? (
            <span className="bg-green-200 rounded-full text-center text-xs px-2 font-semibold">
              COD
            </span>
          ) : (
            <span className="bg-red-200 rounded-full text-center text-xs px-2 font-semibold">
              Online
            </span>
          )}
        </td>
        <td className="p-2 text-left">{order.transaction}</td>
        <td className="p-2 text-left">{order.razorpay_order_id}</td>
        <td className="p-2 text-center">{order.payment}</td>
        <td className="p-2 text-center">
          <a
            href="#"
            className="hover:scale-125"
            onClick={() => setOpenAddressModal(true, order.address)}
          >
            <i className="fa fa-eye "></i>
          </a>
        </td>
        <td className="p-2 text-left">
          {!order.is_shipped && (
            <a
              href="#"
              className="hover:scale-125"
              onClick={(e) => handleStatusModal(true, order._id)}
            >
              <i className="fas fa-shipping-fast "></i>
            </a>
          )}
        </td>
      </tr>
    </>
  );
}

export default AllOrders;
