import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};
export const getUserById = async (uId) => {
  try {
    let res = await axios.post(`/api/user/signle-user`, { uId },Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePersonalInformationFetch = async (userData) => {
  try {
    let res = await axios.post(`/api/user/edit-user`, userData,Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderByUser = async () => {
  try {
    let res = await axios.post(`/api/order/order-by-user`,{},Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getOrderByID = async (id) => {
  try {
    let res = await axios.get(`/api/order/order-by-id?id=${id}`,Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePassword = async (formData) => {
  try {
    let res = await axios.post(`/api/user/change-password`, formData,Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
