import React, { Fragment, useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddressModal = (props) => {
  // const [open, setOpen] = useState(false);
  // const [address, setAddress] = useState(props.address?.address);
  // useEffect(()=>{
  //    console.log(address)
  // },[props.address])
  return (
    <>
      <Modal
        open={props.open}
        onClose={(e) => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Address Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Name:{"  "} {props.address?.address?.name}
          </Typography>
          <Typography id="modal-modal-description">
            Phone:{"  "} {props.address?.address?.phone}
          </Typography>
          <Typography id="modal-modal-description">
            Address-1:{"  "} {props.address?.address?.address1}
          </Typography>
          <Typography id="modal-modal-description">
            Address-2:{"  "} {props.address?.address?.address2}
          </Typography>
          <Typography id="modal-modal-description">
            Landmark:{"  "} {props.address?.address?.landmark}
          </Typography>
          <Typography id="modal-modal-description">
            City: {"  "} {props.address?.address?.city}
          </Typography>
          <Typography id="modal-modal-description">
            State:{"  "} {props.address?.address?.state}
          </Typography>
          <Typography id="modal-modal-description">
            PIN: {"  "} {props.address?.address?.pin}
          </Typography>
          <Typography id="modal-modal-description">
            Country: {"  "} INDIA
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default AddressModal;
