export const productState = {
  products: null,
  addProductModal: false,
  editProductModal: {
    modal: false,
    pId: "",
    pName: "",
    modelNumber: "",
    pDescription: "",
    pStatus: "Active",
    pImage: null, // Initial value will be null or empty array
    pCategory: "",
    WeightAndSize: [],
    extraStoneAdded: false,
    pSubCatagory:"",
    pPrice:0,
    custumizable:false,
    sex:"",
    goldWeight:0,
    sName: "",
    sPrice: 0,
    DCarrett: 0,
    DOffer: 0,
    LPrice: "",
    LOffer: "",
    pDiamension:"",
    success: false,
    error: false,
    extraStones:[]
  },
};

export const productReducer = (state, action) => {
  switch (action.type) {
    /* Get all product */
    case "fetchProductsAndChangeState":
      return {
        ...state,
        products: action.payload,
      };
    /* Create a product */
    case "addProductModal":
      return {
        ...state,
        addProductModal: action.payload,
      };
    /* Edit a product */
    case "editProductModalOpen":
      return {
        ...state,
        editProductModal: {
          modal: true,
          pId: action.product._id,
          pName: action.product.pName,
          modelNumber: action.product.modelNumber,
          pDescription: action.product.pDescription,
          pStatus: action.product.pStatus,
          pImage: action.product.pImages,
          pCategory: action.product.pCategory,
          WeightAndSize: action.product.Size,
          extraStoneAdded: action.product.extraStoneAdded,
          pSubCatagory:action.product.pSubCategory,
          custumizable:action.product.custumizable,
          sex:action.product.sex,
          goldWeight:action.product.goldWeight,
          sName: action.product.sName,
          sPrice: action.product.sPrice,
          DCarrett: action.product.DCarrett,
          DOffer: action.product.DOffer,
          LPrice: action.product.LPrice,
          pDiamension:action.product.pDiamension,
          LOffer: action.product.LOffer,
          pPrice: action.product.pPrice,
          extraStones:action.product.extraStones,
          success: false,
          error: false,
        },
      };
    case "editProductModalClose":
      return {
        ...state,
        editProductModal: {
          modal: false,
          pName: "",
          modelNumber: "",
          pDescription: "",
          pStatus: "Active",
          pImage: null, // Initial value will be null or empty array
          pCategory: "",
          WeightAndSize: null,
          extraStoneAdded: false,
          pSubCatagory:"",
          custumizable:false,
          sex:"",
          goldWeight:0,
          sName: "",
          sPrice: 0,
          DCarrett: 0,
          pPrice:0,
          pDiamension: "",
          DOffer: 0,
          LPrice: "",
          LOffer: "",
          extraStones:[],
          success: false,
          error: false,
        },
      };
    default:
      return state;
  }
};
