import React, { useState } from 'react';
import styles from './filter.module.css';
import Button from "@mui/material/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css';
const options = [
    { value: 'new-arrivals', label: 'New Arrivals' },
    { value: 'discount', label: 'Discount' },
    { value: 'price-low-to-high', label: 'Price: Low to High' },
    { value: 'price-high-to-low', label: 'Price: High to Low' },
    { value: 'customer-rating-high-to-low', label: 'Customer Rating: High to Low' }
];
const Filter = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isOpen, setIsOpen] = useState(false);
    const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
    const [filterParams, setFilterParams] = useState(JSON.parse(decodeURIComponent(searchParams.get('filter') ||
       '%7B%22colors%22%3A%5B%5D%2C%22price%22%3A%5B%5D%2C%22goldWeight%22%3A%5B%5D%2C%22discount%22%3A%5B%5D%2C%22sex%22%3A%5B%5D%7D'
    )))
    const [selectedOption, setSelectedOption] = useState(searchParams.get('sortby') || 'price-low-to-high');
    const toggleDropdown = () => {
        console.log(searchParams)
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (value) => {
        setSelectedOption(value);
        searchParams.delete("sortby")
        searchParams.set("sortby", value)
        setSearchParams(searchParams);
        setIsOpen(false);
    };
    const handleInput = (e, type) => {
        e.persist()
        if (e.target.checked)
            setFilterParams((prev) => ({ ...prev, [type]: [...prev[type], e.target.name] }))
        else
            setFilterParams((prev) => ({ ...prev, [type]: [...prev[type].filter((x) => (e.target.name) != x)] }))
    }
    const checkFilter = (value, type) => {
        return filterParams[type].some((a) => a == value)
    }
    const applyFilter = () => {
        let filter = encodeURIComponent(JSON.stringify(filterParams))
        searchParams.delete("filter")
        searchParams.set("filter", filter)
        setSearchParams(searchParams);
        setBottomSheetOpen(false)
    }
    return (
        <><div className={styles.filter}>
            <Button onClick={() => setBottomSheetOpen(true)} variant='outlined'>Filter <i className="fas fa-filter"></i></Button>
            <div className={`${styles.customDropdown} ${isOpen ? styles.open : ''}`}>
                <div className={styles.dropdownHeader} onClick={toggleDropdown}>
                    <div><span className={styles.sortByText}>Sort By:</span>
                        {options.find((option) => option.value === selectedOption)?.label}
                    </div>
                    <div className={`${styles.arrow} ${isOpen ? styles.rotateArrow : ''}`}>
                        <i class="fas fa-angle-down"></i>
                    </div>
                </div>
                <ul className={styles.dropdownList}>
                    {options.map((option) => (
                        <li
                            key={option.value}
                            onClick={() => handleOptionClick(option.value)}
                            className={styles.dropdownListItem}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            </div>

        </div>
            <BottomSheet className={styles.bottomSheet} onDismiss={() => setBottomSheetOpen(false)} open={bottomSheetOpen}>
                <div className='p-5 pt-2'>
                    <div className={styles.bottomSheetFilters + " grid grid-cols-1 md:grid-cols-5"}>
                        <div>
                            <h3>Select colors</h3>
                            <ul>
                                <li><label><input onClick={(e) => handleInput(e, "colors")} checked={checkFilter("61a46c40c1c0ce38966f03dc", "colors")} type='checkbox' name="61a46c40c1c0ce38966f03dc"></input> Yellow Gold</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "colors")} type='checkbox' checked={checkFilter("61a48957c1c0ce38966f03fe", "colors")} name="61a48957c1c0ce38966f03fe"></input> Rose Gold</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "colors")} type='checkbox' checked={checkFilter("61a48ed0c1c0ce38966f0409", "colors")} name="61a48ed0c1c0ce38966f0409"></input> White Gold</label></li>
                            </ul>
                        </div>
                        <div>
                            <h3>Select price</h3>
                            <ul>
                                <li><label><input onClick={(e) => handleInput(e, "price")} checked={checkFilter("0-10000", "price")} type='checkbox' name="0-10000"></input> 0 to 10000</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "price")} checked={checkFilter("10000-20000", "price")} type='checkbox' name="10000-20000"></input> 10000 to 20000</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "price")} checked={checkFilter("20000-30000", "price")} type='checkbox' name="20000-30000"></input> 20000 to 30000</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "price")} checked={checkFilter("30000-40000", "price")} type='checkbox' name="30000-40000"></input> 30000 to 40000</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "price")} checked={checkFilter("40000-50000", "price")} type='checkbox' name="40000-50000"></input> 40000 to 50000</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "price")} checked={checkFilter("50000<", "price")} type='checkbox' name="50000<"></input> More than 50000</label></li>
                            </ul>
                        </div>
                        <div>
                            <h3>Select type</h3>
                            <ul>
                                <li><label><input onClick={(e) => handleInput(e, "sex")} checked={checkFilter("male", "sex")} type='checkbox' name="male"></input> Men</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "sex")} checked={checkFilter("female", "sex")} type='checkbox' name="female"></input> Women</label></li>
                                <li><label><input onClick={(e) => handleInput(e, "sex")} checked={checkFilter("both", "sex")} type='checkbox' name="both"></input> Both</label></li>
                            </ul>
                        </div>
                        <div>
                            <h3>Select gold weight</h3>
                            <ul>
                                <li><label><input type='checkbox' name="0-5"></input> 0 to 5 gram</label></li>
                                <li><label><input type='checkbox' name="5-10"></input> 5 to 10 gram</label></li>
                                <li><label><input type='checkbox' name="10-15"></input> 10 to 15 gram</label></li>
                                <li><label><input type='checkbox' name="15-20"></input> 15 to 20 gram</label></li>
                                <li><label><input type='checkbox' name="20<"></input> More than 20 gram</label></li>
                            </ul>
                        </div>
                        <div>
                            <h3>Select discount</h3>
                            <ul>
                                <li><label><input type='checkbox' name="0-5"></input> 0% to 10% off</label></li>
                                <li><label><input type='checkbox' name="5-10"></input> 10% to 20% off</label></li>
                                <li><label><input type='checkbox' name="10-15"></input> 20% to 35% off</label></li>
                                <li><label><input type='checkbox' name="15-20"></input> 35% to 50% off</label></li>
                                <li><label><input type='checkbox' name="20<"></input> More than 50% off</label></li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.applyFilterBtns}>
                        <Button onClick={() => setBottomSheetOpen(false)} color="warning" variant='contained'>Cancel</Button>
                        <Button onClick={() => applyFilter()} variant='contained'>Apply Filters</Button>
                    </div>
                </div>
            </BottomSheet>
        </>
    )
}
export default Filter;