import React from "react";
import {
  Home,
  WishList,
  ProtectedRoute,
  AdminProtectedRoute,
  CartProtectedRoute,
  PageNotFound,
  ProductDetails,
  ProductByCategory,
  CheckoutPage,
  CustomizePage,
  LandingPage,
  MobileCatagory,
  SuggestYourOwn,
} from "./shop";
import ContentPage from "./ContentPage";
import {
  DashboardAdmin,
  Categories,
  Materials,
  Products,
  Orders,
  Customize,
  LandingPageEdit,
  Contents,
} from "./admin";
import {
  UserProfile,
  UserOrders,
  SettingUser,
  OrderDetails,
} from "./shop/dashboardUser";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ResetPassword from "./shop/auth/ResetPassword";
import ScrollToTop, { TestConnection } from "../ScrollToTop";
import DiamondWatches from "./shop/diamond-watches";
import Suggestions from "./admin/suggestion";
/* Routing All page will be here */
const RoutesDefault = (props) => {
  return (
    <Router>
      <ScrollToTop />
      <TestConnection />

      <Routes>
        {/* Shop & Public Routes */}
        <Route exact path="/" element={<LandingPage></LandingPage>} />
        <Route
          exact
          path="/shop/diamond-watches"
          element={<DiamondWatches></DiamondWatches>}
        />
        <Route
          exact
          path="/shop/diamond-watches/diamond-watches"
          element={<DiamondWatches></DiamondWatches>}
        />
        <Route exact path="/shop/:cat/:subCat" element={<Home></Home>} />
        <Route exact path="/shop/:cat" element={<Home></Home>} />
        <Route exact path="/shop" element={<Home></Home>} />
        <Route
          exact
          path="/contents/:id"
          element={<ContentPage></ContentPage>}
        />
        <Route
          exact
          path="/customize-ring"
          element={<CustomizePage></CustomizePage>}
        />
        <Route
          exact
          path="/resetpassword/:token"
          element={<ResetPassword></ResetPassword>}
        />
        <Route exact path="/wish-list" element={<WishList></WishList>} />
        <Route
          exact
          path="/shop/:cat/:subCat/:id"
          element={<ProductDetails></ProductDetails>}
        />
        <Route
          exact
          path="/catagories"
          element={<MobileCatagory></MobileCatagory>}
        />
        <Route
          exact
          path="/suggest-your-jewellery"
          element={<SuggestYourOwn></SuggestYourOwn>}
        />
        {/* <Route
          exact
          path="/products/category/:catId"
          component={ProductByCategory}
        /> */}
        <Route
          path={"/checkout"}
          exact={true}
          element={
            <CartProtectedRoute
              exact={true}
              path="/checkout"
              component={CheckoutPage}
            />
          }
        ></Route>

        {/* Shop & Public Routes End */}

        {/* Admin Routes */}
        <Route
          path={"/admin/dashboard"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard"
              component={DashboardAdmin}
            />
          }
        ></Route>
        <Route
          path={"/admin/dashboard/categories"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard/categories"
              component={Categories}
            />
          }
        />

        <Route
          path={"/admin/dashboard/materials"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard/materials"
              component={Materials}
            />
          }
        />

        <Route
          path={"/admin/dashboard/products"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard/products"
              component={Products}
            />
          }
        />

        <Route
          path={"/admin/dashboard/orders"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard/orders"
              component={Orders}
            />
          }
        />

        <Route
          path={"/admin/dashboard/customize"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard/customize"
              component={Customize}
            />
          }
        />

        <Route
          path={"/admin/dashboard/landingpage"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard/landingpage"
              component={LandingPageEdit}
            />
          }
        />

        <Route
          path={"/admin/dashboard/update-contents"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard/update-contents"
              component={Contents}
            />
          }
        />
        <Route
          path={"/admin/dashboard/suggestions"}
          exact={true}
          element={
            <AdminProtectedRoute
              exact={true}
              path="/admin/dashboard/suggestions"
              component={Suggestions}
            />
          }
        />

        {/* Admin Routes End */}

        {/* User Dashboard */}
        <Route
          path={"/user/profile"}
          exact={true}
          element={
            <ProtectedRoute
              exact={true}
              path="/user/profile"
              component={UserProfile}
            />
          }
        />

        <Route
          path={"/user/orders"}
          exact={true}
          element={
            <ProtectedRoute
              exact={true}
              path="/user/orders"
              component={UserOrders}
            />
          }
        />

        <Route
          path={"/user/orders/:id"}
          exact={true}
          element={
            <ProtectedRoute
              exact={true}
              path="/user/orders/:id"
              component={OrderDetails}
            />
          }
        />

        <Route
          path={"/user/setting"}
          exact={true}
          element={
            <ProtectedRoute
              exact={true}
              path="/user/setting"
              component={SettingUser}
            />
          }
        />

        {/* <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/categories"
          component={Categories}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/materials"
          component={Materials}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/products"
          component={Products}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/orders"
          component={Orders}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/customize"
          component={Customize}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/landingpage"
          component={LandingPageEdit}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/update-contents"
          component={Contents}
        />
        {/* Admin Routes End */}

        {/* User Dashboard */}
        {/* <ProtectedRoute
          exact={true}
          path="/user/profile"
          component={UserProfile}
        />
        <ProtectedRoute
          exact={true}
          path="/user/orders"
          component={UserOrders}
        />
        <ProtectedRoute
          exact={true}
          path="/user/orders/:id"
          component={OrderDetails}
        />
        <ProtectedRoute
          exact={true}
          path="/user/setting"
          component={SettingUser}
        />  */}
        {/* User Dashboard End */}

        {/* 404 Page */}
        <Route component={PageNotFound} />
      </Routes>
    </Router>
  );
};

export default RoutesDefault;
