import React, { Fragment, createContext, useReducer } from "react";
import Layout from "../layout";
import { customizeReducer, customizeState } from "./HomeContext";
import SingleProduct from "./SingleProduct";
import {Helmet} from "react-helmet";
export const customizeContext = createContext();

const CustomizeRingComponent = () => {
  return (
    <Fragment>  
      <Helmet>
                <meta charSet="utf-8" />
                <title>Customize Ring | Create Your Own Ring| H&M Designs | Fine Diamond Jewellery & Diamond Watches</title>
                <link rel="canonical" href={window.location.pathname}/>
            </Helmet>    

        <SingleProduct />
    </Fragment>
  );
};

const CustomizePage = (props) => {
  const [data, dispatch] = useReducer(customizeReducer, customizeState);
  return (
    <>
      <customizeContext.Provider value={{ data, dispatch }}>
        <Layout children={<CustomizeRingComponent />} />
      </customizeContext.Provider>
    </>
  );
};

export default CustomizePage;
